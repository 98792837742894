<template>
  <div class="container-center-horizontal">
    <div class="index-375 screen">
      <div class="overlap-group3-1" :style="{ 'background-image': 'url(' + overlapGroup6 + ')' }">
        <div class="rectangle-11-1"></div>
        <div class="rectangle-51"></div>
        <img class="union-9" src="img/union-12.svg" alt="Union" @click="drawer = true" />
        <img class="logo-01-3" src="img/logo-01-2.svg" alt="logo-01" />
        <el-input v-model="rNumber" :placeholder="reportNumber" @keyup.enter.native="showCertificateOld"></el-input>
      </div>
      <div class="overlap-group15-1">
        <div class="about-algt-3 plusjakartasans-bold-mine-shaft-24px">{{ aboutAlgt }}</div>
        <p class="the-gemstone-market-1 heebo-normal-mine-shaft-12px">{{ theGemstoneMarket }}</p>
        <p class=" heebo-normal-mine-shaft-12px" style="width: 335px"><b>NEW!</b> ALGT launches first 3D tour in Antwerp laboratory: <a href="https://my.matterport.com/show/?m=17d3L1aeZZL&lang=en&hl=1&help=1" target="_blank">More Details</a></p>
        <br />
        <img class="rectangle-50-1" :src="rectangle" alt="Rectangle 50" />
      </div>
      <div class="overlap-group5-1" :style="{ 'background-image': 'url(' + overlapGroup2 + ')' }">
        <div class="rectangle-55"></div>
        <div class="founder-2 plusjakartasans-normal-white-12px">{{ founder1 }}</div>
        <p class="igi-gg-hrd-gg-acam-gg-feeg-eg-1 plusjakartasans-normal-white-12px">{{ theCaratColorCl1 }}</p>
        <img class="mask-group-2" :src="maskGroup1" alt="Mask group" />
        <div class="segers-marc-1 plusjakartasans-bold-white-24px">{{ name1 }}</div>
        <p class="m-sc-igi-gg-acam-gg-feeg-eg-1 plusjakartasans-normal-white-12px">{{ theCaratColorCl2 }}</p>
        <div class="founder-3 plusjakartasans-normal-white-12px">{{ founder2 }}</div>
        <img class="mask-group-3" :src="maskGroup2" alt="Mask group" />
        <div class="mingyue-yang-1 plusjakartasans-bold-white-24px">{{ text6 }}</div>
      </div>
      <h1 class="title-1 plusjakartasans-bold-mine-shaft-24px">{{ whyChooseAlgt }}</h1>
      <div class="overlap-group-container-2">
        <div class="overlap-group8">
          <img class="union-10" src="img/union-19.svg" alt="Union" />
          <div class="highly-qualified-professionals heebo-normal-black-14px" v-html="highlyQualifiedProfessionals"></div>
        </div>
        <div class="overlap-group9-1">
          <div class="group-28-1">
            <div class="overlap-group1-1">
              <img class="subtract-4" src="img/subtract-4.svg" alt="Subtract" />
              <img class="subtract-5" src="img/subtract-5.svg" alt="Subtract" />
              <img class="subtract-6" src="img/subtract-6.svg" alt="Subtract" />
              <img class="subtract-7" src="img/subtract-7.svg" alt="Subtract" />
              <img class="vector-1-1" src="img/vector-1-2.svg" alt="Vector 1" />
              <img class="vector-2-1" src="img/vector-2-2.svg" alt="Vector 2" />
            </div>
            <img class="rectangle-121-1" src="img/rectangle-121-1.svg" alt="Rectangle 121" />
          </div>
          <div class="latest-technology-1 heebo-normal-black-14px">{{ latestTechnology }}</div>
        </div>
      </div>
      <div class="overlap-group-container-3">
        <div class="overlap-group16-1">
          <img class="union-11" src="img/union-10.svg" alt="Union" />
          <div class="total-transparency-1 heebo-normal-black-14px">{{ totalTransparency }}</div>
        </div>
        <div class="overlap-group2-1">
          <img class="union-12" src="img/union-9.svg" alt="Union" />
          <div class="x5-star-services-1 heebo-normal-black-14px">{{ x5StarServices }}</div>
        </div>
      </div>
      <div class="overlap-group-container-4">
        <div class="overlap-group6-1">
          <img class="union-13" src="img/union-11.svg" alt="Union" />
          <div class="fast-turnaround-time-1 heebo-normal-black-14px">{{ fastTurnaroundTime }}</div>
        </div>
        <div class="overlap-group11-1">
          <div class="rectangle-113"></div>
          <img class="group-29-1" src="img/group-29-2@2x.png" alt="Group 29" />
          <div class="competitive-prices-1 heebo-normal-black-14px">{{ competitivePrices }}</div>
        </div>
      </div>
      <div class="overlap-group14">
        <img class="union-14" src="img/union-15-2.svg" alt="Union" />
        <p class="guaranteed-peace-of-1 heebo-normal-black-16px">{{ guaranteedPeaceOf }}</p>
      </div>
      <div class="our-reports-3 plusjakartasans-bold-mine-shaft-24px">{{ title }}</div>
      <div class="overlap-group4">
        <div class="name-2 plusjakartasans-bold-mine-shaft-16px">
          <router-link to="/s/DiamondReport" style="visited: {text-align:center; color:#333;}">
            {{ name2 }}
          </router-link>  
        </div>
        <p class="the-carat-color-cl-2 heebo-normal-mine-shaft-12px">{{ theCaratColorCl3 }}</p>
        <div class="overlap-group19-1">
          <div class="rectangle-5-1"></div>
          <img class="rectangle-3-1" :src="overlapGroup2" alt="Rectangle 35" />
        </div>
      </div>
      <div class="x-report plusjakartasans-bold-mine-shaft-16px">
        <router-link to="/s/GemstoneReport" style="visited: {text-align:center; color:#333;}">
          {{ gemstoneReport }}
        </router-link>
      </div>
      <p class="the-certificate-iden-1 heebo-normal-mine-shaft-12px">{{ theCertificateIden1 }}</p>
      <div class="overlap-group13-1">
        <div class="rectangle-5-1"></div>
        <img class="rectangle-3-1" :src="rectangle36" alt="Rectangle 36" />
      </div>
      <div class="overlap-group10">
        <div class="name-3 plusjakartasans-bold-mine-shaft-16px">
          <router-link to="/s/PearlReport" style="visited: {text-align:center; color:#333;}">
              {{ name3 }}
            </router-link>
          </div>
        <p class="the-carat-color-cl-3 heebo-normal-mine-shaft-12px">{{ theCaratColorCl4 }}</p>
        <div class="overlap-group18-1">
          <div class="rectangle-5-1"></div>
          <img class="rectangle-3-1" :src="rectangle38" alt="Rectangle 38" />
        </div>
      </div>
      <p class="x-report plusjakartasans-bold-mine-shaft-16px">
        <router-link to="/s/OtherGemstoneReport" style="visited: {text-align:center; color:#333;}">
          {{ labGrownDiamondReport }}
        </router-link>
      </p>
      <p class="the-certificate-iden-1 heebo-normal-mine-shaft-12px">{{ theCertificateIden2 }}</p>
      <div class="overlap-group17">
        <div class="rectangle-5-1"></div>
        <img class="rectangle-3-1" :src="rectangle37" alt="Rectangle 37" />
      </div>
      <div class="overlap-group12-1">
        <div class="premium-report-1 plusjakartasans-bold-mine-shaft-16px">
          <router-link to="/s/PremiumReport" style="visited: {text-align:center; color:#333;}">
            {{ premiumReport }}
          </router-link>
        </div>
        <p class="encased-in-a-lush-tr-1 heebo-normal-mine-shaft-12px">{{ encasedInALushTr }}</p>
        <img class="rectangle-44-1" :src="rectangle44" alt="Rectangle 44" />
      </div>
      <div class="service-4 plusjakartasans-bold-mine-shaft-24px">{{ service }}</div>
      <div class="frame-container">
        <div class="frame-1">
          <img class="lase-inscription-1-1" :src="laseInscription1" alt="lase inscription 1" />
          <div class="lase-inscription-2 plusjakartasans-bold-mine-shaft-16px">{{ laseInscription }}</div>
        </div>
        <div class="frame-1">
          <img class="lase-inscription-1-1" :src="quickCheck1" alt="lase inscription 1" />
          <div class="quick-check-1 plusjakartasans-bold-mine-shaft-16px">{{ quickCheck }}</div>
        </div>
        <div class="frame-1">
          <img class="lase-inscription-1-1" :src="seal1" alt="lase inscription 1" />
          <div class="place-3 plusjakartasans-bold-mine-shaft-16px">{{ seal }}</div>
        </div>
      </div>
      <foot v-bind="globalData" />
      <el-drawer direction="ltr" :visible.sync="drawer" :with-header="false" size="50%">
        <left-nav />
      </el-drawer>
    </div>
  </div>
</template>

<script>
import Foot from "./components/Foot375";
import LeftNav from "./components/Navbar";
export default {
  name: "Home375",
  components: {
    Foot,
    LeftNav,
  },
  data() {
    return {
      drawer: false,
      rNumber: '',
      rules: {
        rNumber: [{
          type: "number",
          required: true,
          message: "Must be a number",
          trigger: "blur"
        }],
      }
    }
  },
  methods: {
    showCertificateOld() {
      console.log(this.rNumber)
      // https://lab.algtlabs.com/stones/cert?rn=020390110&t=b&l=e&f=api
      window.open('https://lab.algtlabs.com/stones/cert?rn=' + this.rNumber + '&t=b&l=e&f=api', '_blank')
    },
    showCertificate() {
      console.log(this.rNumber)
      // let region = parseInt(this.rNumber.substring(0, 1))
      // if (region % 2 == 0) { // 偶数代表中国
      if (this.rNumber.trim().length == 9) { // 9位代表中国
        this.$axios.get('http://admin.algtlabs.com.cn/diamond/system/report/v',
          {
            params: { 'number': this.rNumber }
          }
        ).then(res => {
          // console.log(res.data);
          if (res.data.code == 200) {
            let url = res.data.data;
            window.open('http://admin.algtlabs.com.cn/diamond' + url, '_blank')
          } else {
            this.$message({
              message: 'report number error',
              type: 'warning'
            });
          }
        }).catch(err => {
          // console.log(err)
          this.$message({
            message: 'report number error',
            type: 'warning'
          });
        })
      } else { // 奇数代表欧洲
        // https://lab.algtlabs.com/stones/cert?rn=020390110&t=b&l=e&f=api
        window.open('https://lab.algtlabs.com/stones/cert?rn=' + this.rNumber + '&t=b&l=e&f=api', '_blank')
      }
    }
  },
  props: [
    "rectangle44",
    "whyChooseAlgt",
    "theCaratColorCl4",
    "name3",
    "theCaratColorCl3",
    "overlapGroup2",
    "globalData",
    "overlapGroup7",
    "rectangle21",
    "reportNumber",
    "rectangle",
    "phone",
    "infoAlgtlabsCom",
    "aboutAlgt",
    "theGemstoneMarket",
    "moreDetails",
    "rectangle10",
    "overlapGroup6",
    "igiGgHrdGgAcamGgFeegEg",
    "founder1",
    "mscIgiGgAcamGgFeegEg",
    "founder2",
    "maskGroup1",
    "segersMarc",
    "maskGroup2",
    "mingyueYang",
    "title",
    "highlyQualifiedProfessionals",
    "latestTechnology",
    "totalTransparency",
    "x5StarServices",
    "fastTurnaroundTime",
    "competitivePrices",
    "guaranteedPeaceOf",
    "ourReports",
    "name1",
    "theCaratColorCl1",
    "rectangle35",
    "rectangle36",
    "gemstoneReport",
    "theCertificateIden1",
    "name2",
    "text6",
    "overlapGroup1",
    "theCaratColorCl2",
    "rectangle38",
    "rectangle37",
    "labGrownDiamondReport",
    "theCertificateIden2",
    "premiumReport",
    "encasedInALushTr",
    "laseInscription1",
    "laseInscription",
    "service",
    "quickCheck1",
    "quickCheck",
    "seal",
    "seal1",
    "place",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../variables'

.index-375
  align-items: center
  background-color: $selago
  display: flex
  flex-direction: column
  height: 5175px
  position: relative
  width: 375px

.overlap-group3-1
  background-position: 50% 50%
  background-size: cover
  height: 147px
  position: relative
  width: 375px

.rectangle-11-1
  background-color: $black-3
  height: 147px
  left: 0
  position: absolute
  top: 0
  width: 375px

.rectangle-51
  background-color: $white
  box-shadow: 0px 1px 16px #33333326
  height: 47px
  left: 0
  position: absolute
  top: 0
  width: 375px

.logo-01-3
  height: 26px
  left: 143px
  position: absolute
  top: 10px
  width: 90px

.rectangle-52
  background-color: $white
  border-radius: 40px
  height: 40px
  left: 20px
  position: absolute
  top: 77px
  width: 335px

.report-number-1
  color: $black
  font-family: $font-family-roboto
  font-size: $font-size-m
  font-weight: 400
  left: 40px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 89px
  white-space: nowrap

.union-9
  height: 17px
  left: 15px
  position: absolute
  top: 15px
  width: 19px

.overlap-group15-1
  align-items: center
  background-color: $white
  display: flex
  flex-direction: column
  margin-top: 10px
  min-height: 509px
  padding: 19px 0
  width: 375px

.about-algt-3
  align-self: flex-start
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  min-height: 30px

.the-gemstone-market-1
  letter-spacing: 0
  line-height: 19.2px
  margin-top: 20px
  min-height: 208px
  width: 335px

.rectangle-50-1
  height: 181px
  margin-top: 21px
  object-fit: cover
  width: 335px

.overlap-group5-1
  background-position: 50% 50%
  background-size: cover
  height: 352px
  position: relative
  width: 375px

.rectangle-55
  background-color: $black-3
  height: 352px
  left: 0
  position: absolute
  top: 0
  width: 375px

.founder-2
  left: 130px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 103px

.igi-gg-hrd-gg-acam-gg-feeg-eg-1
  left: 130px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 128px

.mask-group-2
  height: 80px
  left: 30px
  position: absolute
  top: 64px
  width: 80px

.segers-marc-1
  left: 130px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 63px

.m-sc-igi-gg-acam-gg-feeg-eg-1
  left: 130px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 272px

.founder-3
  left: 130px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 247px

.mask-group-3
  height: 80px
  left: 30px
  position: absolute
  top: 208px
  width: 80px

.mingyue-yang-1
  left: 130px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 207px

.title-1
  letter-spacing: 0
  line-height: normal
  margin-top: 39px
  min-height: 30px
  min-width: 237px

.overlap-group-container-2
  align-items: flex-start
  display: flex
  gap: 15px
  height: 142px
  margin-top: 31px
  min-width: 335px

.overlap-group8
  align-items: center
  background: linear-gradient(180deg, rgba(167.9, 176.61, 255, 0.09) 0%, rgb(255, 255, 255) 100%)
  border-radius: 8px
  box-shadow: 0px 4px 20px #0000000d
  display: flex
  flex-direction: column
  gap: 30px
  min-height: 142px
  padding: 11px 0
  width: 160px

.union-10
  height: 41px
  margin-top: 21px
  width: 52px

.highly-qualified-professionals
  letter-spacing: 0
  line-height: 14px
  min-height: 28px
  width: 138px

.overlap-group9-1
  align-items: center
  background: linear-gradient(180deg, rgba(167.9, 176.61, 255, 0.09) 0%, rgb(255, 255, 255) 100%)
  border-radius: 8px
  box-shadow: 0px 4px 20px #0000000d
  display: flex
  flex-direction: column
  gap: 24px
  min-height: 142px
  padding: 25px 0
  width: 160px

.group-28-1
  align-items: center
  display: flex
  flex-direction: column
  margin-left: 13.21px
  margin-top: 2px
  min-height: 52px
  width: 49px

.overlap-group1-1
  height: 46px
  margin-right: 0
  position: relative
  width: 49px

.subtract-4
  height: 21px
  left: 0
  position: absolute
  top: 18px
  width: 21px

.subtract-5
  height: 15px
  left: 14px
  position: absolute
  top: 31px
  width: 16px

.subtract-6
  height: 20px
  left: 5px
  position: absolute
  top: 3px
  width: 21px

.subtract-7
  height: 12px
  left: 25px
  position: absolute
  top: 2px
  width: 15px

.vector-1-1
  height: 5px
  left: 37px
  position: absolute
  top: 0
  width: 13px

.vector-2-1
  height: 5px
  left: 37px
  position: absolute
  top: 11px
  width: 13px

.rectangle-121-1
  height: 6px
  margin-right: 6.19px
  width: 28px

.latest-technology-1,
.x5-star-services-1
  letter-spacing: 0
  line-height: 14px
  white-space: nowrap
  width: 138px

.overlap-group-container-3
  align-items: flex-start
  display: flex
  gap: 15px
  height: 142px
  margin-top: 15px
  min-width: 335px

.overlap-group16-1
  align-items: center
  background: linear-gradient(180deg, rgba(167.9, 176.61, 255, 0.09) 0%, rgb(255, 255, 255) 100%)
  border-radius: 8px
  box-shadow: 0px 4px 20px #0000000d
  display: flex
  flex-direction: column
  gap: 30px
  min-height: 142px
  padding: 15px 0
  width: 160px

.union-11
  height: 52px
  margin-top: 10px
  width: 52px

.total-transparency-1,
.fast-turnaround-time-1
  letter-spacing: 0
  line-height: 14px
  min-height: 20px
  width: 138px

.overlap-group2-1
  align-items: center
  background: linear-gradient(180deg, rgba(167.9, 176.61, 255, 0.09) 0%, rgb(255, 255, 255) 100%)
  border-radius: 8px
  box-shadow: 0px 4px 20px #0000000d
  display: flex
  flex-direction: column
  gap: 34px
  min-height: 142px
  padding: 21px 0
  width: 160px

.union-12
  height: 48px
  margin-top: 4px
  width: 52px

.overlap-group-container-4
  align-items: flex-start
  display: flex
  gap: 15px
  margin-top: 15px
  min-width: 335px

.overlap-group6-1
  align-items: center
  background: linear-gradient(180deg, rgba(167.9, 176.61, 255, 0.09) 0%, rgb(255, 255, 255) 100%)
  border-radius: 8px
  box-shadow: 0px 4px 20px #0000000d
  display: flex
  flex-direction: column
  gap: 29px
  min-height: 142px
  padding: 16px 0
  width: 160px

.union-13
  height: 52px
  margin-right: 0.06px
  margin-top: 9px
  width: 40px

.overlap-group11-1
  height: 146px
  position: relative
  width: 160px

.rectangle-113
  background: linear-gradient(180deg, rgba(167.9, 176.61, 255, 0.09) 0%, rgb(255, 255, 255) 100%)
  border-radius: 8px
  box-shadow: 0px 4px 20px #0000000d
  height: 142px
  left: 0
  position: absolute
  top: 0
  width: 160px

.group-29-1
  height: 52px
  left: 57px
  position: absolute
  top: 25px
  width: 52px

.competitive-prices-1
  left: 11px
  letter-spacing: 0
  line-height: 14px
  position: absolute
  top: 106px
  width: 138px

.overlap-group14
  align-items: flex-end
  background: linear-gradient(180deg, rgba(167.9, 176.61, 255, 0.09) 0%, rgb(255, 255, 255) 100%)
  border-radius: 8px
  box-shadow: 0px 4px 20px #0000000d
  display: flex
  gap: 23px
  height: 105px
  margin-top: 11px
  min-width: 335px
  padding: 25.0px 40px

.union-14
  height: 55px
  width: 80px

.guaranteed-peace-of-1
  letter-spacing: 0
  line-height: 16px
  margin-bottom: 1.01px
  min-height: 49px
  width: 151px

.our-reports-3
  letter-spacing: 0
  line-height: normal
  margin-right: 1.0px
  margin-top: 49px
  min-height: 30px
  min-width: 170px

.overlap-group4
  align-items: flex-start
  background-color: $white
  display: flex
  flex-direction: column
  margin-top: 21px
  min-height: 363px
  padding: 25px
  width: 375px

.name-2
  letter-spacing: 0
  line-height: normal
  margin-left: 5px
  margin-top: 4px
  min-height: 20px

.the-carat-color-cl-2
  align-self: center
  letter-spacing: 0
  line-height: 18px
  margin-top: 10px
  min-height: 53px
  width: 315px

.overlap-group19-1
  align-self: flex-end
  height: 215px
  margin-top: 11px
  position: relative
  width: 320px

.rectangle-5-1
  background-color: $brand
  height: 210px
  left: 5px
  opacity: 0.55
  position: absolute
  top: 5px
  width: 315px

.rectangle-3-1
  height: 210px
  left: 0
  object-fit: cover
  position: absolute
  top: 0
  width: 315px

.x-report
  align-self: flex-start
  letter-spacing: 0
  line-height: normal
  margin-left: 30px
  margin-top: 29px
  min-height: 20px

.the-certificate-iden-1
  letter-spacing: 0
  line-height: 18px
  margin-top: 10px
  min-height: 70px
  width: 315px

.overlap-group13-1
  height: 215px
  margin-left: 5.0px
  margin-top: 31px
  position: relative
  width: 320px

.overlap-group10
  align-items: flex-start
  background-color: $white
  display: flex
  flex-direction: column
  margin-top: 25px
  min-height: 372px
  padding: 25px
  width: 375px

.name-3
  letter-spacing: 0
  line-height: normal
  margin-left: 5px
  margin-top: 4px
  min-height: 20px
  width: 176px

.the-carat-color-cl-3
  align-self: center
  letter-spacing: 0
  line-height: 18px
  margin-top: 10px
  min-height: 52px
  width: 315px

.overlap-group18-1
  align-self: flex-end
  height: 215px
  margin-top: 21px
  position: relative
  width: 320px

.overlap-group17
  height: 215px
  margin-left: 5.0px
  margin-top: 21px
  position: relative
  width: 320px

.overlap-group12-1
  align-items: center
  background-color: $white
  display: flex
  flex-direction: column
  margin-top: 30px
  min-height: 469px
  padding: 29px 0
  width: 375px

.premium-report-1
  align-self: flex-start
  letter-spacing: 0
  line-height: normal
  margin-left: 30px
  min-height: 20px
  width: 212px

.encased-in-a-lush-tr-1
  letter-spacing: 0
  line-height: 18px
  margin-top: 10px
  min-height: 89px
  width: 315px

.rectangle-44-1
  height: 270px
  margin-top: 21px
  width: 315px

.service-4
  letter-spacing: 0
  line-height: normal
  margin-right: 1.0px
  margin-top: 29px
  min-height: 30px
  min-width: 102px

.frame-container
  align-items: center
  background-color: $white
  display: flex
  flex-direction: column
  gap: 10px
  margin-top: 21px
  min-height: 779px
  padding: 30px 0
  width: 375px

.frame-1
  align-items: center
  background-color: $selago
  border-radius: 10px
  display: flex
  flex-direction: column
  gap: 9px
  min-height: 233px
  width: 315px

.lase-inscription-1-1
  height: 183px
  object-fit: cover
  width: 315px

.lase-inscription-2
  letter-spacing: 0
  line-height: normal
  min-height: 20px
  min-width: 147px
  text-align: center

.quick-check-1
  letter-spacing: 0
  line-height: normal
  min-height: 20px
  min-width: 113px
  text-align: center

.place-3
  letter-spacing: 0
  line-height: normal
  margin-right: 1.0px
  min-height: 20px
  min-width: 40px
  text-align: center

.el-input
  // background-color: $white
  border-radius: 48px
  height: 32px
  left: 70px
  position: absolute
  top: 70px
  width: 235px
</style>

<style lang="sass">
.el-input__inner
  border-radius: 48px

.input__inner
  font-size: 14px
</style>