<template>
  <div class="container-center-horizontal">
    <div class="report-check-1024 heebo-normal-mine-shaft-14px screen">
      <div class="flex-col-4">
        <div class="overlap-group1-1" :style="{ 'background-image': 'url(' + overlapGroup + ')' }">
          <div class="rectangle-11-2"></div>
          <left-nav />
          <info v-bind="globalData" />
        </div>
        <h1 class="title">{{ title }}</h1>
        <p class="encased-in-a-lush-tr-2 heebo-normal-mine-shaft-14px">{{ encasedInALushTr }}</p>
        <div class="group-22-2">
          <img class="group-22-item-2" :src="rectangle" alt="1" />
          <!-- <img class="group-22-item-2" :src="x1" alt="1" /> -->
          <!-- <img class="group-22-item-2" :src="x3" alt="3" /> -->
        </div>
        <!-- <div class="premium-service-2 heebo-bold-black-20px">{{ premiumService }}</div> -->
        <!-- <p class="for-a-small-addition-2 heebo-normal-mine-shaft-14px">{{ forASmallAddition }}</p> -->
      </div>
      <!-- <div class="flex-row-2">
        <img class="rectangle-9-2" :src="rectangle93" alt="Rectangle 93" />
        <img class="rectangle-9-2" :src="rectangle95" alt="Rectangle 95" />
        <img class="rectangle-9-2" :src="rectangle94" alt="Rectangle 94" />
      </div>
      <p class="the-algt-sealing-sys-2">{{ theAlgtSealingSys }}</p>
      <p class="algt-offers-a-3-hour-2">{{ algtOffersA3Hour }}</p> -->
      <footBottom v-bind="globalData"/>
    </div>
  </div>
</template>

<script>
import LeftNav from "../components/Navbar";
import FootBottom from "../components/FootBottom";
import Info from "../components/Info";
export default {
  name: "PremiumReport1024",
  components: {
    LeftNav,
    FootBottom,
        Info,
  },
  props: [
    "globalData",
    "overlapGroup",
    "title",
    "encasedInALushTr",
    "rectangle",
    "x1",
    "x3",
    "premiumService",
    "forASmallAddition",
    "rectangle93",
    "rectangle95",
    "rectangle94",
    "theAlgtSealingSys",
    "algtOffersA3Hour",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'

.report-check-1024
  align-items: flex-start
  background-color: $selago
  display: flex
  flex-direction: column
  // height: 1582px
  overflow: hidden
  width: 1024px

.flex-col-4
  align-items: center
  display: flex
  flex-direction: column
  min-height: 1198px
  width: 1024px

.overlap-group1-1
  background-position: 50% 50%
  background-size: cover
  height: 300px
  position: relative
  width: 1024px

.rectangle-11-2
  background-color: $black-2
  height: 300px
  left: 0
  position: absolute
  top: 0
  width: 1024px

.logo-01-2
  height: 43px
  left: 437px
  position: absolute
  top: 157px
  width: 150px

.group-29
  align-items: flex-end
  display: flex
  height: 20px
  left: 651px
  min-width: 337px
  position: absolute
  top: 29px

.vector-stroke
  height: 20px
  width: 20px

.phone-1
  letter-spacing: 0
  line-height: normal
  margin-left: 10px
  min-height: 19px
  min-width: 108px
  white-space: nowrap

.vector-2
  align-self: center
  height: 16px
  margin-left: 30px
  width: 20px

.infoalgtlabscom-1
  cursor: pointer
  letter-spacing: 0
  line-height: normal
  margin-left: 10px
  min-height: 19px
  min-width: 135px
  white-space: nowrap

.title
  color: $mine-shaft
  font-family: $font-family-plus_jakarta_sans
  font-size: $font-size-xxxl
  font-weight: 700
  letter-spacing: 0
  line-height: normal
  margin-top: 39px
  min-height: 40px
  min-width: 252px

.encased-in-a-lush-tr-2
  letter-spacing: 0
  line-height: 16.8px
  margin-top: 40px
  min-height: 34px
  width: 944px

.group-22-2
  align-items: flex-start
  display: flex
  margin-top: 41px
  min-width: 832px

.group-22-item-2
  height: 586px
  object-fit: cover
  width: 832px

.premium-service-2
  align-self: flex-start
  letter-spacing: 0
  line-height: 24.0px
  margin-left: 40px
  margin-top: 39px
  white-space: nowrap

.for-a-small-addition-2
  letter-spacing: 0
  line-height: 16.8px
  margin-top: 20px
  min-height: 35px
  width: 944px

.flex-row-2
  align-items: flex-start
  display: flex
  gap: 20px
  margin-left: 40px
  margin-top: 21px
  min-width: 628px

.rectangle-9-2
  height: 134px
  object-fit: cover
  width: 196px

.the-algt-sealing-sys-2
  align-self: center
  letter-spacing: 0
  line-height: 16.8px
  margin-top: 19px
  min-height: 32px
  width: 944px

.algt-offers-a-3-hour-2
  letter-spacing: 0
  line-height: 16.8px
  margin-left: 40px
  margin-top: 20px
  min-height: 17px
  white-space: nowrap

.foot
  align-items: flex-start
  display: flex
  margin-top: 41px
  min-width: 1036px

.overlap-group-2
  align-items: center
  background-color: $selago
  display: flex
  height: 100px
  justify-content: flex-end
  min-width: 1024px
  padding: 28px 30px

.logo-02-2
  align-self: flex-end
  height: 42px
  width: 34px

.about-algt-2
  letter-spacing: 0
  line-height: normal
  margin-left: 21px
  margin-top: 3.0px
  min-height: 15px
  min-width: 76px

.our-reports-1
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  margin-top: 3.0px
  min-height: 15px
  min-width: 85px

.service-1
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  margin-top: 3.0px
  min-height: 15px
  min-width: 51px

.video-2
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  margin-top: 3.0px
  min-height: 15px
  min-width: 39px

.place-3
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  margin-top: 3.0px
  min-height: 15px
  min-width: 59px

.x2023-algt-all-rights-reserved-2
  letter-spacing: 0
  line-height: normal
  margin-left: 295px
  margin-top: 1.0px
  min-height: 15px
  min-width: 214px
</style>
