<template>
  <div class="container-center-horizontal">
    <div class="about-us-1920 screen">
      <div class="overlap-group3">
        <img class="banner-1920" :src="overlapGroup" alt="banner-1920" />
        <div class="rectangle-11"></div>
        <left-nav/>
        <info v-bind="globalData" />
      </div> 
      <h1 class="title">{{ title }}</h1>
      <div class="overlap-group2">
        <div class="rectangle-118"></div>
        <div class="rectangle-107"></div>
        <p class="the-gemstone-market">{{ theGemstoneMarket }}</p>
        <p class="algt-is-equipped-wit heebo-normal-white-20px">{{ algtIsEquippedWit }}</p>
        <p class="our-goal-is-to-accom heebo-normal-mine-shaft-20px">{{ ourGoalIsToAccom }}</p>
        <p class="antwerp-laboratory-f heebo-normal-white-20px">{{ antwerpLaboratoryF }}</p>
        <p class="our-team-of-gemmolog heebo-normal-mine-shaft-20px">{{ ourTeamOfGemmolog }}</p>
        <img class="dsc4285-1" :src="dsc428512" alt="DSC4285 1" />
        <img class="rectangle-73" :src="rectangle73" alt="Rectangle 73" />
        <img class="rectangle-74" :src="rectangle74" alt="Rectangle 74" />
        <img class="raman-pl-1" :src="ramanPl2" alt="RAMAN PL 1" />
        <div class="group-container">
          <img class="group-11" src="img/image-9--stroke--1.svg" alt="Group 11" />
          <div class="group-10" :style="{ 'background-image': 'url(' + image9 + ')' }"></div>
        </div>
      </div>
      <foot v-bind="globalData" />
    </div>
  </div>
</template>

<script>
import LeftNav from "./components/Navbar";
import Foot from "./components/Foot1920";
import Info from "./components/Info";

export default {
  name: "About1024",
  components: {
    LeftNav,
    Foot,
    Info,
  },
  props: [
    "globalData",
    "overlapGroup",
    "title",
    "theGemstoneMarket",
    "algtIsEquippedWit",
    "ourGoalIsToAccom",
    "antwerpLaboratoryF",
    "ourTeamOfGemmolog",
    "dsc428512",
    "rectangle73",
    "rectangle74",
    "ramanPl2",
    "image9",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../variables'

.about-us-1920
  align-items: center
  background-color: $selago
  display: flex
  flex-direction: column
  height: 3629px
  overflow: hidden
  position: relative
  width: 1920px

.overlap-group3
  align-self: flex-start
  height: 400px
  position: relative
  width: 2000px

.banner-1920
  height: 400px
  left: 0
  object-fit: cover
  position: absolute
  top: 0
  width: 1920px

.rectangle-11
  background-color: $black-2
  height: 400px
  left: 0
  position: absolute
  top: 0
  width: 1920px

.phone
  left: 1537px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.infoalgtlabscom
  cursor: pointer
  left: 1705px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.rectangle-12
  border: 1px solid
  border-color: $white
  border-radius: 56px
  height: 24px
  left: 1323px
  position: absolute
  top: 47px
  width: 160px

.report-number
  color: $white
  font-family: $font-family-roboto
  font-size: $font-size-l
  font-weight: 400
  left: 1336px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 51px
  white-space: nowrap

.logo-01
  height: 87px
  left: 810px
  position: absolute
  top: 157px
  width: 300px

.vector
  height: 20px
  left: 1507px
  position: absolute
  top: 49px
  width: 20px

.vector-1
  height: 16px
  left: 1675px
  position: absolute
  top: 51px
  width: 20px

.title
  color: $mine-shaft
  font-family: $font-family-plus_jakarta_sans
  font-size: 48px
  font-weight: 700
  letter-spacing: 0
  line-height: normal
  margin-left: 1.0px
  margin-top: 59px
  min-height: 60px
  min-width: 245px

.overlap-group2
  height: 2498px
  margin-right: 69.0px
  margin-top: 61px
  position: relative
  width: 1271px

.rectangle-118
  background-color: $white
  height: 2498px
  left: 1px
  position: absolute
  top: 0
  width: 1270px

.rectangle-107
  background-color: $brand
  height: 539px
  left: 0
  position: absolute
  top: 604px
  width: 1270px

.the-gemstone-market
  color: $black
  font-family: $font-family-heebo
  font-size: $font-size-20
  font-weight: 400
  left: 90px
  letter-spacing: 0
  line-height: 32.0px
  position: absolute
  top: 113px
  width: 593px

.algt-is-equipped-wit
  left: 586px
  letter-spacing: 0
  line-height: 32.0px
  position: absolute
  top: 901px
  width: 614px

.our-goal-is-to-accom
  left: 545px
  letter-spacing: 0
  line-height: 32.0px
  position: absolute
  top: 2263px
  width: 516px

.antwerp-laboratory-f
  left: 586px
  letter-spacing: 0
  line-height: 32.0px
  position: absolute
  top: 685px
  width: 631px

.our-team-of-gemmolog
  left: 111px
  letter-spacing: 0
  line-height: 32.0px
  position: absolute
  top: 1251px
  width: 480px

.dsc4285-1
  height: 539px
  left: 0
  position: absolute
  top: 604px
  width: 515px

.rectangle-73
  height: 450px
  left: 0
  object-fit: cover
  position: absolute
  top: 1658px
  width: 1270px

.rectangle-74
  height: 340px
  left: 70px
  object-fit: cover
  position: absolute
  top: 2158px
  width: 348px

.raman-pl-1
  height: 445px
  left: 650px
  position: absolute
  top: 1173px
  width: 620px
  object-fit: cover

.group-container
  height: 468px
  left: 808px
  position: absolute
  top: 48px
  width: 392px

.group-11
  height: 448px
  left: 20px
  position: absolute
  top: 20px
  width: 372px

.group-10
  background-position: 50% 50%
  background-size: cover
  height: 448px
  left: 0
  position: absolute
  top: 0
  width: 372px
</style>
