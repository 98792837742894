<template>
  <div :class="[`tag`, className || ``]">
    <div class="x029 plusjakartasans-bold-white-18px">{{ x029 }}</div>
    <div class="fee"><div class="x28 valign-text-middle plusjakartasans-bold-white-20px">Fee</div></div>
  </div>
</template>

<script>
export default {
  name: "Tag",
  props: ["x029", "className"],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'

.tag
  align-items: center
  background-color: $brand
  display: flex
  gap: 20px
  margin-top: 21px
  width: 648px

.x029
  letter-spacing: 0
  line-height: normal
  margin-bottom: 4.0px
  margin-left: 16px
  min-height: 23px
  width: 392px

.fee
  align-items: center
  background-color: $brand
  display: flex
  flex-wrap: wrap
  gap: 20px 20px
  height: 41px
  padding: 8px 0px 8px 16px
  position: relative
  width: 220px

.x28
  align-self: stretch
  letter-spacing: 0
  line-height: normal
  margin-top: -2.00px
  position: relative
  width: 188px

.tag.tag-1,
.tag.tag-3,
.tag.tag-5,
.tag.tag-7,
.tag.tag-10,
.tag.tag-12,
.tag.tag-14
  margin-top: unset

.tag.tag-6,
.tag.tag-8
  margin-left: 1px
</style>
