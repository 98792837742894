<template>
  <div class="container-center-horizontal">
    <div class="price-1024 screen">
      <div class="overlap-group6-1">
        <img class="rectangle-21-1" :src="rectangle21" alt="Rectangle 21" />
        <div class="rectangle-11-1"></div>
        <left-nav/>
        <info v-bind="globalData" />
      </div>
      <div class="flex-row-38">
        <h1 class="title plusjakartasans-bold-mine-shaft-32px">{{ title }}</h1>
        <div class="overlap-group1-3">
          <img
            class="vector-27"
            src="img/vector-9.svg"
            alt="Vector"
          />
          <div class="fee-pdf-4 heebo-bold-white-24px">
            <a :href="globalData.algtReportPriceListPdf" target="_blank" style="visited: {text-align:center; color:#fff;}">
            {{ feePdf }}
          </a>
          </div>
        </div>
      </div>
      <div class="overlap-group8">
        <div class="name-4 plusjakartasans-bold-mine-shaft-24px">{{ name1 }}</div>
        <div class="tag-container-2">
          <tag1024 :x029="tag10241Props.x029" />
          <tag1024 :x029="tag10242Props.x029" />
        </div>
        <div class="tag-container">
          <tag10242 :text15="tag102421Props.text15" :fee2Props="tag102421Props.fee2Props" />
          <tag10242 :text15="tag102422Props.text15" :fee2Props="tag102422Props.fee2Props" />
        </div>
        <div class="tag-container">
          <tag10242 :text15="tag102423Props.text15" :fee2Props="tag102423Props.fee2Props" />
          <tag10242 :text15="tag102424Props.text15" :fee2Props="tag102424Props.fee2Props" />
        </div>
      </div>
      <div class="overlap-group5">
        <p class="colored-stone-identi-2 plusjakartasans-bold-mine-shaft-24px">{{ coloredStoneIdenti }}</p>
        <div class="ruby-sapphire-emeral-2 plusjakartasans-normal-mine-shaft-20px">{{ rubySapphireEmeral }}</div>
        <div class="tag-container-3">
          <tag1024 :x029="tag10243Props.x029" />
          <tag1024 :x029="tag10244Props.x029" />
        </div>
        <div class="tag-container-1">
          <tag10242 :text15="tag102425Props.text15" :fee2Props="tag102425Props.fee2Props" />
          <tag10242 :text15="tag102426Props.text15" :fee2Props="tag102426Props.fee2Props" />
        </div>
        <div class="tag-container-1">
          <tag10242 :text15="tag102427Props.text15" :fee2Props="tag102427Props.fee2Props" />
          <tag10242 :text15="tag102428Props.text15" :fee2Props="tag102428Props.fee2Props" />
        </div>
        <div class="tag-container-1">
          <tag10242 :text15="tag102429Props.text15" :fee2Props="tag102429Props.fee2Props" />
          <tag10242 :text15="tag1024210Props.text15" :fee2Props="tag1024210Props.fee2Props" />
        </div>
        <div class="tag-container-1">
          <tag10242 :text15="tag1024211Props.text15" :fee2Props="tag1024211Props.fee2Props" />
          <tag10242 :text15="tag1024212Props.text15" :fee2Props="tag1024212Props.fee2Props" />
        </div>
        <div class="tag-container-1">
          <tag10242 :text15="tag1024213Props.text15" :fee2Props="tag1024213Props.fee2Props" />
          <tag10242 :text15="tag1024214Props.text15" :fee2Props="tag1024214Props.fee2Props" />
        </div>
        <div class="tag-container-1">
          <tag10242 :text15="tag1024215Props.text15" :fee2Props="tag1024215Props.fee2Props" />
          <tag10242 :text15="tag1024216Props.text15" :fee2Props="tag1024216Props.fee2Props" />
        </div>
        <tag10242
          :text15="tag1024217Props.text15"
          :className="tag1024217Props.className"
          :fee2Props="tag1024217Props.fee2Props"
        />
      </div>
      <div class="flex-row-39">
        <div class="flex-col-40">
          <div class="other-colored-stones-loose-2 plusjakartasans-bold-mine-shaft-24px">
            {{ otherColoredStonesLoose }}
          </div>
          <tag1024 :x029="tag10245Props.x029" :className="tag10245Props.className" />
          <tag10242 :text15="tag1024218Props.text15" :className="tag1024218Props.className" :fee2Props="tag1024218Props.fee2Props" />
          <tag10242 :text15="tag1024219Props.text15" :className="tag1024219Props.className" :fee2Props="tag1024219Props.fee2Props" />
          <tag10242 :text15="tag1024220Props.text15" :className="tag1024220Props.className" :fee2Props="tag1024220Props.fee2Props" />
          <tag10242 :text15="tag1024221Props.text15" :className="tag1024221Props.className" :fee2Props="tag1024221Props.fee2Props" />
          <tag10242 :text15="tag1024222Props.text15" :className="tag1024222Props.className" :fee2Props="tag1024222Props.fee2Props" />
        </div>
        <div class="tag-container-4">
          <tag1024 :x029="tag10246Props.x029" />
          <tag10242 :text15="tag1024223Props.text15" :fee2Props="tag1024223Props.fee2Props" />
          <tag10242 :text15="tag1024224Props.text15" :fee2Props="tag1024224Props.fee2Props" />
          <tag10242 :text15="tag1024225Props.text15" :fee2Props="tag1024225Props.fee2Props" />
          <tag10242 :text15="tag1024226Props.text15" :fee2Props="tag1024226Props.fee2Props" />
        </div>
      </div>
      <div class="flex-row-40">
        <div class="flex-col-41">
          <div class="name-5 plusjakartasans-bold-mine-shaft-24px">{{ name2 }}</div>
          <tag1024 :x029="tag10247Props.x029" :className="tag10247Props.className" />
          <tag10242 :text15="tag1024227Props.text15" :className="tag1024227Props.className" :fee2Props="tag1024227Props.fee2Props" />
        </div>
        <div class="tag-container-5">
          <tag1024 :x029="tag10248Props.x029" />
          <tag10242 :text15="tag1024228Props.text15" :fee2Props="tag1024228Props.fee2Props" />
        </div>
      </div>
      <div class="overlap-group11">
        <div class="extra-service-for-delivery-2 plusjakartasans-bold-mine-shaft-24px">
          {{ extraServiceForDelivery }}
        </div>
        <tag1024 :x029="tag10249Props.x029" :className="tag10249Props.className" />
        <tag10242 :text15="tag1024229Props.text15" :className="tag1024229Props.className" :fee2Props="tag1024229Props.fee2Props" />
      </div>
      <div class="overlap-group9">
        <p class="rough-diamond-identi-2 plusjakartasans-bold-mine-shaft-24px">{{ roughDiamondIdenti }}</p>
        <div class="tag-container-6">
          <tag1024 :x029="tag102410Props.x029" />
          <tag1024 :x029="tag102411Props.x029" />
        </div>
        <div class="tag-container">
          <tag10242 :text15="tag1024230Props.text15" :fee2Props="tag1024230Props.fee2Props" />
          <tag10242 :text15="tag1024231Props.text15" :fee2Props="tag1024231Props.fee2Props" />
        </div>
        <div class="tag-container">
          <tag10242 :text15="tag1024232Props.text15" :fee2Props="tag1024232Props.fee2Props" />
          <tag10242 :text15="tag1024233Props.text15" :fee2Props="tag1024233Props.fee2Props" />
        </div>
      </div>
      <div class="flex-row-41">
        <div class="flex-col-42">
          <div class="jewelry-identification-report-2 plusjakartasans-bold-mine-shaft-24px">
            {{ jewelryIdentificationReport }}
          </div>
          <tag1024 :x029="tag102412Props.x029" :className="tag102412Props.className" />
          <tag10242 :text15="tag1024234Props.text15" :className="tag1024234Props.className" :fee2Props="tag1024234Props.fee2Props" />
          <tag10242 :text15="tag1024235Props.text15" :className="tag1024235Props.className" :fee2Props="tag1024235Props.fee2Props" />
          <tag10242 :text15="tag1024236Props.text15" :className="tag1024236Props.className" :fee2Props="tag1024236Props.fee2Props" />
        </div>
        <div class="tag-container-7">
          <tag1024 :x029="tag102413Props.x029" />
          <tag10242 :text15="tag1024237Props.text15" :className="tag1024237Props.className" :fee2Props="tag1024237Props.fee2Props" />
          <tag10242 :text15="tag1024238Props.text15" :className="tag1024238Props.className" :fee2Props="tag1024238Props.fee2Props" />
          <tag10242 :text15="tag1024239Props.text15" :className="tag1024239Props.className" :fee2Props="tag1024239Props.fee2Props" />
        </div>
      </div>
      <div class="flex-row-42">
        <div class="flex-col-43">
          <div class="additional-service-1 plusjakartasans-bold-mine-shaft-24px">{{ additionalService }}</div>
          <tag1024 :x029="tag102414Props.x029" :className="tag102414Props.className" />
          <tag10242 :text15="tag1024240Props.text15" :className="tag1024240Props.className" :fee2Props="tag1024240Props.fee2Props" />
          <tag10242 :text15="tag1024241Props.text15" :className="tag1024241Props.className" :fee2Props="tag1024241Props.fee2Props" />
          <tag10242 :text15="tag1024242Props.text15" :className="tag1024242Props.className" :fee2Props="tag1024242Props.fee2Props" />
          <tag10242 :text15="tag1024243Props.text15" :className="tag1024243Props.className" :fee2Props="tag1024243Props.fee2Props" />
          <tag10242 :text15="tag1024244Props.text15" :className="tag1024244Props.className" :fee2Props="tag1024244Props.fee2Props" />
          <tag10242 :text15="tag1024245Props.text15" :className="tag1024245Props.className" :fee2Props="tag1024245Props.fee2Props" />
          <tag10242 :text15="tag1024246Props.text15" :className="tag1024246Props.className" :fee2Props="tag1024246Props.fee2Props" />
          <tag10242 :text15="tag1024247Props.text15" :className="tag1024247Props.className" :fee2Props="tag1024247Props.fee2Props" />
          <tag10242 :text15="tag1024248Props.text15" :className="tag1024248Props.className" :fee2Props="tag1024248Props.fee2Props" />
          <tag10242 :text15="tag1024249Props.text15" :className="tag1024249Props.className" :fee2Props="tag1024249Props.fee2Props" />
        </div>
        <div class="tag-container-8">
          <tag1024 :x029="tag102415Props.x029" />
          <tag10242 :text15="tag1024250Props.text15" :fee2Props="tag1024250Props.fee2Props" />
          <tag10242 :text15="tag1024251Props.text15" :fee2Props="tag1024251Props.fee2Props" />
          <tag10242 :text15="tag1024252Props.text15" :fee2Props="tag1024252Props.fee2Props" />
          <tag10242 :text15="tag1024253Props.text15" :fee2Props="tag1024253Props.fee2Props" />
          <tag10242 :text15="tag1024254Props.text15" :fee2Props="tag1024254Props.fee2Props" />
          <tag10243 :x029="tag10243Props2.x029" :fee2Props="tag10243Props2.fee2Props" />
          <tag10242 :text15="tag1024255Props.text15" :fee2Props="tag1024255Props.fee2Props" />
          <tag10242 :text15="tag1024256Props.text15" :fee2Props="tag1024256Props.fee2Props" />
          <tag10242 :text15="tag1024257Props.text15" :className="tag1024257Props.className" :fee2Props="tag1024257Props.fee2Props" />
          <tag10242 :text15="tag1024258Props.text15" :className="tag1024258Props.className" :fee2Props="tag1024258Props.fee2Props" />
        </div>
      </div>

      <div class="flex-row-44">
        <div class="flex-col-44">
          <div class="additional-service-1 plusjakartasans-bold-mine-shaft-24px">Platinum Report(No Large Report) </div>
          <tag1024 :x029="tag102414Props.x029" :className="tag102414Props.className" />
          <tag10242 text15="Platinum Report(No Large Report) " :fee2Props="{ number: '533', className: '' }" />
        </div>
      </div>

      <group46 :algtReportPriceListPdf="globalData.algtReportPriceListPdf"/>
      <foot v-bind="globalData" />
    </div>
  </div>
</template>

<script>
import LeftNav from "./components/Navbar";
import Foot from "./components/Foot1024";
import Info from "./components/Info";
import Tag1024 from "./price/Tag1024";
import Tag10242 from "./price/Tag10242";
import Tag10243 from "./price/Tag10243";
import Group46 from "./price/Group46";
export default {
  name: "Price1024",
  components: {
    LeftNav,
    Foot,
    Info,
    Tag1024,
    Tag10242,
    Tag10243,
    Group46,
  },
  props: [
    "globalData",
    "rectangle21",
    "title",
    "feePdf",
    "name1",
    "coloredStoneIdenti",
    "rubySapphireEmeral",
    "otherColoredStonesLoose",
    "name2",
    "extraServiceForDelivery",
    "roughDiamondIdenti",
    "jewelryIdentificationReport",
    "additionalService",
    "tag10241Props",
    "tag10242Props",
    "tag102421Props",
    "tag102422Props",
    "tag102423Props",
    "tag102424Props",
    "tag10243Props",
    "tag10244Props",
    "tag102425Props",
    "tag102426Props",
    "tag102427Props",
    "tag102428Props",
    "tag102429Props",
    "tag1024210Props",
    "tag1024211Props",
    "tag1024212Props",
    "tag1024213Props",
    "tag1024214Props",
    "tag1024215Props",
    "tag1024216Props",
    "tag1024217Props",
    "tag10245Props",
    "tag1024218Props",
    "tag1024219Props",
    "tag1024220Props",
    "tag1024221Props",
    "tag1024222Props",
    "tag10246Props",
    "tag1024223Props",
    "tag1024224Props",
    "tag1024225Props",
    "tag1024226Props",
    "tag10247Props",
    "tag1024227Props",
    "tag10248Props",
    "tag1024228Props",
    "tag10249Props",
    "tag1024229Props",
    "tag102410Props",
    "tag102411Props",
    "tag1024230Props",
    "tag1024231Props",
    "tag1024232Props",
    "tag1024233Props",
    "tag102412Props",
    "tag1024234Props",
    "tag1024235Props",
    "tag1024236Props",
    "tag102413Props",
    "tag1024237Props",
    "tag1024238Props",
    "tag1024239Props",
    "tag102414Props",
    "tag1024240Props",
    "tag1024241Props",
    "tag1024242Props",
    "tag1024243Props",
    "tag1024244Props",
    "tag1024245Props",
    "tag1024246Props",
    "tag1024247Props",
    "tag1024248Props",
    "tag1024249Props",
    "tag102415Props",
    "tag1024250Props",
    "tag1024251Props",
    "tag1024252Props",
    "tag1024253Props",
    "tag1024254Props",
    "tag10243Props2",
    "tag1024255Props",
    "tag1024256Props",
    "tag1024257Props",
    "tag1024258Props",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../variables'

.price-1024
  align-items: center
  background-color: $selago-2
  display: flex
  flex-direction: column
  overflow: hidden
  position: relative
  width: 1024px

.overlap-group6-1
  align-self: flex-start
  height: 300px
  position: relative
  width: 1960px

.rectangle-21-1
  height: 300px
  left: 0
  object-fit: cover
  position: absolute
  top: 0
  width: 1024px

.rectangle-11-1
  background-color: $black-3
  height: 300px
  left: 0
  position: absolute
  top: 0
  width: 1024px

.logo-01-8
  height: 43px
  left: 437px
  position: absolute
  top: 157px
  width: 150px

.group-26
  align-items: center
  display: flex
  height: 20px
  left: 692px
  min-width: 296px
  position: absolute
  top: 30px

.vector-stroke-1
  height: 20px
  width: 20px

.phone-1
  letter-spacing: 0
  line-height: normal
  margin-bottom: 2.0px
  margin-left: 10px
  min-height: 16px
  min-width: 94px
  white-space: nowrap

.vector-26
  height: 16px
  margin-left: 20px
  width: 20px

.infoalgtlabscom-7
  cursor: pointer
  letter-spacing: 0
  line-height: normal
  margin-bottom: 2.0px
  margin-left: 10px
  min-height: 16px
  min-width: 118px
  white-space: nowrap

.flex-row-38
  align-items: center
  display: flex
  gap: 138px
  margin-left: 2.0px
  margin-top: 60px
  min-width: 946px

.title
  letter-spacing: 0
  line-height: normal
  margin-bottom: 2.0px
  min-height: 40px
  min-width: 343px

.overlap-group1-3
  align-items: center
  background-color: $brand
  border-radius: 4px
  display: flex
  gap: 12px
  height: 64px
  min-width: 363px
  padding: 12px 30px

.vector-27
  height: 24px
  margin-bottom: 4.0px
  width: 18px

.fee-pdf-4
  align-self: flex-end
  letter-spacing: 0
  line-height: 38.4px
  min-width: 273px
  white-space: nowrap

.overlap-group8
  align-items: flex-start
  background-color: $white
  display: flex
  flex-direction: column
  margin-top: 48px
  min-height: 244px
  padding: 39px 40px
  width: 944px

.name-4,
.colored-stone-identi-2,
.other-colored-stones-loose-2,
.name-5,
.extra-service-for-delivery-2,
.rough-diamond-identi-2,
.jewelry-identification-report-2,
.additional-service-1
  letter-spacing: 0
  line-height: normal
  min-height: 30px

.tag-container-2
  align-items: flex-start
  display: flex
  gap: 1px
  height: 34px
  margin-top: 31px
  min-width: 861px
  position: relative

.tag-container
  align-items: flex-start
  display: flex
  gap: 1px
  height: 34px
  margin-top: 1px
  min-width: 861px
  position: relative

.overlap-group5
  align-items: flex-start
  background-color: $white
  display: flex
  flex-direction: column
  margin-top: 30px
  min-height: 444px
  padding: 39px
  position: relative
  width: 944px

.ruby-sapphire-emeral-2
  letter-spacing: 0
  line-height: normal
  margin-top: 10px
  min-height: 25px

.tag-container-3
  align-items: flex-start
  display: flex
  gap: 1px
  height: 34px
  margin-left: 1px
  margin-top: 21px
  min-width: 861px
  position: relative

.tag-container-1
  align-items: flex-start
  display: flex
  gap: 1px
  height: 34px
  margin-left: 1px
  margin-top: 1px
  min-width: 861px
  position: relative

.flex-row-39
  align-items: flex-start
  background-color: $white
  display: flex
  gap: 1px
  height: 339px
  margin-top: 30px
  min-width: 944px
  padding: 39px

.flex-col-40
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 260px
  position: relative
  width: 431px

.tag-container-4
  align-items: flex-start
  align-self: center
  display: flex
  flex-direction: column
  gap: 1px
  margin-top: 15.0px
  min-height: 174px
  position: relative
  width: 430px

.flex-row-40
  align-items: flex-start
  background-color: $white
  display: flex
  gap: 1px
  height: 214px
  margin-top: 30px
  min-width: 944px
  padding: 39px

.flex-col-41
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 120px
  position: relative
  width: 431px

.tag-container-5
  align-items: flex-start
  align-self: flex-end
  display: flex
  flex-direction: column
  gap: 1px
  margin-bottom: 16px
  min-height: 69px
  position: relative
  width: 430px

.overlap-group11
  align-items: flex-start
  background-color: $white
  display: flex
  flex-direction: column
  margin-top: 30px
  min-height: 214px
  padding: 39px 40px
  position: relative
  width: 944px

.overlap-group9
  align-items: flex-start
  background-color: $white
  display: flex
  flex-direction: column
  margin-top: 30px
  min-height: 257px
  padding: 39px 40px
  width: 944px

.tag-container-6
  align-items: flex-start
  display: flex
  gap: 1px
  height: 34px
  margin-top: 21px
  min-width: 861px
  position: relative

.flex-row-41
  align-items: flex-start
  background-color: $white
  display: flex
  gap: 1px
  height: 341px
  margin-right: 2.0px
  margin-top: 30px
  min-width: 942px
  padding: 39px 40px

.flex-col-42
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 262px
  position: relative
  width: 430px

.tag-container-7
  align-items: flex-start
  align-self: flex-end
  display: flex
  flex-direction: column
  gap: 1px
  margin-bottom: -16px
  min-height: 211px
  position: relative
  width: 430px

.flex-row-42
  align-items: flex-start
  background-color: $white
  display: flex
  gap: 1px
  height: 572px
  margin-right: 2.0px
  margin-top: 30px
  min-width: 942px
  padding: 25px 40px

.flex-col-43
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 507px
  position: relative
  width: 430px

.flex-row-44
  align-items: flex-start
  background-color: $white
  display: flex
  gap: 1px
  margin-right: 2.0px
  margin-top: 30px
  min-width: 942px
  padding: 25px 40px
  height: 180px
  
.flex-col-44
  align-items: flex-start
  display: flex
  flex-direction: column
  position: relative
  width: 430px

.tag-container-8
  align-items: flex-start
  align-self: flex-end
  display: flex
  flex-direction: column
  gap: 1px
  margin-bottom: 3px
  min-height: 456px
  position: relative
  width: 430px
</style>
