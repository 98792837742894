<template>
  <div class="container-center-horizontal">
    <div class="report-check-1024 screen">
      <div class="overlap-group1-1" :style="{ 'background-image': 'url(' + overlapGroup + ')' }">
        <div class="rectangle-11-2"></div>
        <left-nav />
        <info v-bind="globalData"/>
      </div>
      <h1 class="title">{{ title }}</h1>
      <p class="we-are-happy-to-cons-2" v-html="weAreHappyToCons"></p>
      <div class="flex-row-1">
        <el-carousel class="flex-row-item-1">
            <el-carousel-item v-for="item in images" :key="item.url" class="flex-row-item-1">
              <img class="flex-row-item-1" :src="item.url" :alt="item.url" />
            </el-carousel-item>
          </el-carousel>
        <!-- <img class="flex-row-item-1" :src="x1" alt="1" /> -->
        <!-- <img class="flex-row-item-1" :src="x3" alt="3" /> -->
      </div>
      <footBottom v-bind="globalData"/>
    </div>
  </div>
</template>

<script>
import LeftNav from "../components/Navbar";
import FootBottom from "../components/FootBottom";
import Info from "../components/Info";
export default {
  name: "OtherGemstoneReport1024",
  components: {
    LeftNav,
    FootBottom,
    Info,
  },
  props: [
    "globalData",
    "overlapGroup",
    "title",
    "weAreHappyToCons",
    "images",
    "x1",
    "x3",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'
.el-carousel::-webkit-scrollbar
  display: none
.report-check-1024
  align-items: center
  background-color: $selago
  display: flex
  flex-direction: column
  // height: 1324px
  overflow: hidden
  width: 1024px

.overlap-group1-1
  background-position: 50% 50%
  background-size: cover
  height: 300px
  position: relative
  width: 1024px

.rectangle-11-2
  background-color: $black-2
  height: 300px
  left: 0
  position: absolute
  top: 0
  width: 1024px

.logo-01-2
  height: 43px
  left: 437px
  position: absolute
  top: 140px
  width: 150px

.group-29
  align-items: flex-end
  display: flex
  height: 20px
  left: 651px
  min-width: 337px
  position: absolute
  top: 29px

.vector-stroke
  height: 20px
  width: 20px

.phone-1
  letter-spacing: 0
  line-height: normal
  margin-left: 10px
  min-height: 19px
  min-width: 108px
  white-space: nowrap

.vector-2
  align-self: center
  height: 16px
  margin-left: 30px
  width: 20px

.infoalgtlabscom-1
  cursor: pointer
  letter-spacing: 0
  line-height: normal
  margin-left: 10px
  min-height: 19px
  min-width: 135px
  white-space: nowrap

.title
  color: $mine-shaft
  font-family: $font-family-plus_jakarta_sans
  font-size: $font-size-xxxl
  font-weight: 700
  letter-spacing: 0
  line-height: normal
  margin-left: 1.0px
  margin-top: 39px
  min-height: 40px
  min-width: 375px

.we-are-happy-to-cons-2
  color: $mine-shaft
  font-family: $font-family-heebo
  font-size: $font-size-l
  font-weight: 400
  letter-spacing: 0
  line-height: 16.8px
  margin-top: 40px
  min-height: 138px
  width: 944px

.flex-row-1
  align-items: flex-start
  display: flex
  margin-top: 41px
  min-width: 832px

.flex-row-item-1
  height: 586px
  object-fit: cover
  width: 832px

.foot
  align-items: flex-start
  align-self: flex-start
  display: flex
  margin-top: 40px
  min-width: 1036px

.overlap-group-2
  align-items: center
  background-color: $selago
  display: flex
  height: 100px
  justify-content: flex-end
  min-width: 1024px
  padding: 28px 30px

.logo-02-2
  align-self: flex-end
  height: 42px
  width: 34px

.about-algt-2
  letter-spacing: 0
  line-height: normal
  margin-left: 21px
  margin-top: 3.0px
  min-height: 15px
  min-width: 76px

.our-reports-1
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  margin-top: 3.0px
  min-height: 15px
  min-width: 85px

.service-1
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  margin-top: 3.0px
  min-height: 15px
  min-width: 51px

.video-2
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  margin-top: 3.0px
  min-height: 15px
  min-width: 39px

.place-3
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  margin-top: 3.0px
  min-height: 15px
  min-width: 59px

.x2023-algt-all-rights-reserved-2
  letter-spacing: 0
  line-height: normal
  margin-left: 295px
  margin-top: 1.0px
  min-height: 15px
  min-width: 214px
</style>
