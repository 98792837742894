<template>
  <div class="container-center-horizontal">
    <div class="service-1920 screen">
      <div class="flex-col">
        <div class="overlap-group4" :style="{ 'background-image': 'url(' + overlapGroup + ')' }">
          <div class="rectangle-11"></div>
          <left-nav />
          <info v-bind="globalData" />
        </div>
        <h1 class="service plusjakartasans-bold-mine-shaft-48px">
          {{ service }}
        </h1>
        <div class="flex-row">
          <div class="name plusjakartasans-bold-black-24px" v-html="diamondReportProps.reportName"></div>
          <div class="view-more-2 plusjakartasans-bold-cosmic-cobalt-14px">
            <router-link :to="`/l` + diamondReportProps.reportUrl" style="visited: {text-align:center; color:#2a2f88;}">
             {{ viewMore }} {{ viewMore2 }}
            </router-link>
          </div>
        </div>
      </div>
      <div class="flex-row-1">
        <div class="flex-col-1">
          <div class="flex-row-2">
            <div class="flex-row-3">
              <div class="flex-col-2">
                <img class="rectangle-8" :src="diamondReportProps.tag1.rectangle" alt="Rectangle 84" />
                <img class="rectangle-8-1" :src="diamondReportProps.tag3.rectangle" alt="Rectangle 86" />
                <div class="flex-row-4">
                  <div class="gemstone-report plusjakartasans-bold-black-24px">
                    {{ gemstoneReportProps.reportName }}
                  </div>
                  <div class="view-more plusjakartasans-bold-cosmic-cobalt-14px">
                    <router-link :to="`/l`+gemstoneReportProps.reportUrl" style="visited: {text-align:center; color:#2a2f88;}">
                      View more &gt;&gt;
                    </router-link>
                  </div>
                </div>
                <div class="overlap-group5">
                  <img class="rectangle-88" :src="gemstoneReportProps.rectangle" alt="Rectangle 88" />
                </div>
              </div>
              <div class="flex-col-3 heebo-normal-granite-gray-16px">
                <p class="x-dia" v-html="diamondReportProps.tag1.text"></p>
                <p class="full-quality-assessm" v-html="diamondReportProps.tag3.text"></p>
                <p class="algt-offers-full-rep" v-html="gemstoneReportProps.text"></p>
              </div>
            </div>
            <div class="flex-col-4">
              <img class="rectangle-8" :src="diamondReportProps.tag2.rectangle" alt="Rectangle 85" />
              <img class="rectangle-8-1" :src="diamondReportProps.tag4.rectangle" alt="Rectangle 87" />
              <div class="flex-row-5">
                <div class="name-1 plusjakartasans-bold-black-24px">
                  {{ pearlReportProps.reportName }}
                </div>
                <div class="view-more plusjakartasans-bold-cosmic-cobalt-14px">
                  <router-link :to="`/l`+pearlReportProps.reportUrl" style="visited: {text-align:center; color:#2a2f88;}">
                    View more &gt;&gt;
                  </router-link>
                </div>
              </div>
              <img class="rectangle-89" :src="pearlReportProps.rectangle" alt="Rectangle 89" />
            </div>
          </div>
          <div class="flex-row-6">
            <div class="other-gemstone-report plusjakartasans-bold-black-24px">
              {{ otherGemstoneReportProps.reportName }}
            </div>
            <div class="view-more-1 plusjakartasans-bold-cosmic-cobalt-14px">
              <router-link to="/l/OtherGemstoneReport" style="visited: {text-align:center; color:#2a2f88;}">
                View more &gt;&gt;
              </router-link>
            </div>
            <div class="premium-report plusjakartasans-bold-black-24px">
              {{ premiumReportProps.reportName }}
            </div>
            <div class="view-more-1 plusjakartasans-bold-cosmic-cobalt-14px">
              <router-link :to="`/l`+premiumReportProps.reportUrl" style="visited: {text-align:center; color:#2a2f88;}">
                View more &gt;&gt;
              </router-link>
            </div>
          </div>
          <div class="flex-row-7">
            <div class="overlap-group3">
              <img class="rectangle-91" :src="otherGemstoneReportProps.rectangle" alt="Rectangle 91" />
            </div>
            <p class="in-addition-to-the-i heebo-normal-granite-gray-16px" v-html="otherGemstoneReportProps.text"></p>
            <!-- <el-carousel class="rectangle-92" style="margin-left: 107px;">
              <el-carousel-item v-for="item in premiumReportProps.images" :key="item.url" class="rectangle-92">
                <img class="rectangle-92" :src="item.url" :alt="item.url" />
              </el-carousel-item>
            </el-carousel> -->
            <img class="rectangle-92" :src="premiumReportProps.rectangle" alt="Rectangle 92" />
          </div>
        </div>
        <div class="flex-col-5 heebo-normal-granite-gray-16px">
          <p class="x-dia" v-html="diamondReportProps.tag2.text"></p>
          <p class="we-also-specialise-i">{{ diamondReportProps.tag4.text }}</p>
          <p class="name-2" v-html="pearlReportProps.text"></p>
          <p class="encased-in-a-lush-tr">{{ premiumReportProps.text }}</p>
        </div>
      </div>
      <div class="flex-col-6">

        <div class="terms">
          <h1 class="terms-title plusjakartasans-bold-mine-shaft-48px">{{termsTitle}}</h1>
          <p class="terms-content heebo-normal-mine-shaft-16px" v-html="termsContent"></p>
          <p class="heebo-normal-mine-shaft-16px" style="margin-top: 20px;text-align: center;">
            <router-link :to="`/l/terms-and-conditions`" style="visited: {text-align:center; color:#2a2f88;}">
              View More >>
            </router-link>
          </p>
        </div>


        <div class="flex-row-8">
          <div class="flex-col-7">
            <img class="rectangle-9-1" :src="additional1Props.rectangle" alt="Rectangle 93" />
            <p class="for-a-small-addition heebo-normal-granite-gray-16px">
              {{ additional1Props.addition }}
            </p>
          </div>
          <div class="flex-col-8">
            <div class="additional-service plusjakartasans-bold-mine-shaft-48px">
              {{ additionalService }}
            </div>
            <img class="rectangle-94" :src="additional2Props.rectangle" alt="Rectangle 94" />
            <p class="the-algt-sealing-sys heebo-normal-granite-gray-16px">
              {{ additional2Props.addition }}
            </p>
          </div>
          <div class="flex-col-9">
            <img class="rectangle-9-1" :src="additional3Props.rectangle" alt="Rectangle 95" />
            <p class="algt-offers-a-3-hour heebo-normal-granite-gray-16px">
              {{ additional3Props.addition }}
            </p>
          </div>
        </div>
        <foot v-bind="globalData" />
      </div>
    </div>
  </div>
</template>

<script>
import LeftNav from "./components/Navbar";
import Foot from "./components/Foot1920";
import Info from "./components/Info";
import 'element-ui/lib/theme-chalk/index.css';
export default {
  name: "Service1920",
  components: {
    LeftNav,
    Foot,
    Info,
  },
  props: [
    "globalData",
    "overlapGroup",
    "viewMore",
    "viewMore2",
    "service",
    "diamondReportProps",
    "gemstoneReportProps",
    "pearlReportProps",
    "otherGemstoneReportProps",
    "premiumReportProps",
    "additionalService",
    "additional1Props",
    "additional2Props",
    "additional3Props",
    "termsTitle",
    "termsContent",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../variables'

  
.terms
  background-color: #fff
  width: 1920px

.terms-title
  align-self: flex-start
  letter-spacing: 0
  line-height: normal
  // margin-left: 60px
  margin-top: 20px
  margin-bottom: 20px
  min-height: 40px
  text-align: center

.terms-content
  margin-left: 150px
  margin-right: 150px
  
  
  
  
.el-carousel::-webkit-scrollbar
  display: none
.service-1920
  align-items: center
  background-color: $selago
  display: flex
  flex-direction: column
  height: 3382px
  width: 1920px

.flex-col
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 609px
  width: 1920px

.overlap-group4
  background-position: 50% 50%
  background-size: cover
  height: 400px
  position: relative
  width: 1920px

.rectangle-11
  background-color: $black-2
  height: 400px
  left: 0
  position: absolute
  top: 0
  width: 1920px

.phone
  left: 1537px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.infoalgtlabscom
  cursor: pointer
  left: 1705px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.rectangle-12
  border: 1px solid
  border-color: $white
  border-radius: 56px
  height: 24px
  left: 1323px
  position: absolute
  top: 47px
  width: 160px

.report-number
  color: $white
  font-family: $font-family-roboto
  font-size: $font-size-m
  font-weight: 400
  left: 1336px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 51px
  white-space: nowrap

.logo-01
  height: 87px
  left: 810px
  position: absolute
  top: 157px
  width: 300px

.vector
  height: 20px
  left: 1507px
  position: absolute
  top: 49px
  width: 20px

.vector-1
  height: 16px
  left: 1675px
  position: absolute
  top: 51px
  width: 20px

.service
  align-self: center
  letter-spacing: 0
  line-height: normal
  margin-top: 59px
  min-height: 60px
  min-width: 204px

.flex-row
  align-items: flex-end
  display: flex
  gap: 10px
  height: 30px
  margin-left: 160px
  margin-top: 60px
  min-width: 335px

.name
  letter-spacing: 0
  line-height: normal
  min-height: 30px
  min-width: 224px

.view-more-2
  letter-spacing: 0
  line-height: normal
  margin-bottom: 2px
  min-height: 18px
  min-width: 101px
  cursor: pointer

.flex-row-1
  align-items: flex-start
  display: flex
  gap: 30px
  height: 1421px
  margin-right: 2.0px
  margin-top: 30px
  min-width: 1598px

.flex-col-1
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 1421px
  width: 1183px

.flex-row-2
  align-items: flex-end
  display: flex
  gap: 110px
  min-width: 1183px

.flex-row-3
  align-items: flex-start
  display: flex
  gap: 13px
  height: 1011px
  min-width: 743px

.flex-col-2
  align-items: flex-start
  align-self: flex-end
  display: flex
  flex-direction: column
  min-height: 1010px
  width: 347px

.rectangle-8
  height: 270px
  object-fit: cover
  width: 330px

.rectangle-8-1
  height: 270px
  margin-top: 60px
  object-fit: cover
  width: 330px

.flex-row-4
  align-items: center
  display: flex
  gap: 10px
  height: 30px
  margin-top: 89px
  min-width: 347px

.gemstone-report
  letter-spacing: 0
  line-height: normal
  min-height: 30px
  min-width: 236px

.view-more
  letter-spacing: 0
  line-height: normal
  margin-top: 2.0px
  min-height: 18px
  min-width: 101px
  cursor: pointer

.overlap-group5
  background-position: 50% 50%
  background-size: cover
  height: 270px
  margin-top: 21px
  position: relative
  width: 330px

.rectangle-9
  background-color: $white
  border-radius: 5px
  height: 21px
  left: 283px
  position: absolute
  top: 239px
  width: 37px

.text
  left: 292px
  letter-spacing: 0
  line-height: 14.4px
  position: absolute
  top: 242px
  white-space: nowrap

.flex-col-3
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 1010px
  width: 383px

.x-dia
  letter-spacing: 0
  line-height: 19.2px
  min-height: 270px
  width: 383px

.full-quality-assessm
  letter-spacing: 0
  line-height: 19.2px
  margin-top: 60px
  min-height: 270px
  width: 383px

.algt-offers-full-rep
  letter-spacing: 0
  line-height: 19.2px
  margin-top: 140px
  min-height: 270px
  width: 383px

.flex-col-4
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 1010px
  width: 330px

.flex-row-5
  align-items: center
  display: flex
  gap: 10px
  height: 30px
  margin-top: 89px
  min-width: 287px

.name-1
  letter-spacing: 0
  line-height: normal
  min-height: 30px
  min-width: 176px

.rectangle-89
  height: 270px
  margin-top: 21px
  object-fit: cover
  width: 330px

.flex-row-6
  align-items: flex-end
  display: flex
  height: 30px
  margin-top: 89px
  min-width: 1176px

.other-gemstone-report
  letter-spacing: 0
  line-height: normal
  min-height: 30px
  min-width: 326px

.view-more-1
  letter-spacing: 0
  line-height: normal
  margin-bottom: 2px
  margin-left: 10px
  min-height: 18px
  min-width: 101px
  cursor: pointer

.premium-report
  letter-spacing: 0
  line-height: normal
  margin-left: 416px
  min-height: 30px
  min-width: 212px

.flex-row-7
  align-items: flex-end
  display: flex
  height: 271px
  margin-top: 20px
  min-width: 1183px

.overlap-group3
  background-position: 50% 50%
  background-size: cover
  height: 270px
  position: relative
  width: 330px

.rectangle-88
  height: 270px
  left: 0
  object-fit: cover
  position: absolute
  top: 0
  width: 330px

.rectangle-91
  height: 270px
  left: 0
  object-fit: cover
  position: absolute
  top: 0
  width: 330px

.in-addition-to-the-i
  align-self: flex-start
  letter-spacing: 0
  line-height: 19.2px
  margin-left: 30px
  min-height: 270px
  width: 386px

.rectangle-92
  height: 270px
  margin-left: 107px
  object-fit: cover
  width: 330px

.flex-col-5
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 1279px
  width: 385px

.we-also-specialise-i
  letter-spacing: 0
  line-height: 19.2px
  margin-top: 60px
  min-height: 198px
  width: 383px

.name-2
  letter-spacing: 0
  line-height: 19.2px
  margin-top: 212px
  min-height: 270px
  width: 385px

.encased-in-a-lush-tr
  letter-spacing: 0
  line-height: 19.2px
  margin-top: 144px
  min-height: 125px
  width: 383px

.flex-col-6
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 90px
  min-height: 1232px
  position: relative
  width: 1920px

.flex-row-8
  align-items: flex-end
  background-color: $white
  display: flex
  height: 721px
  min-width: 1920px
  padding: 79px 160px

.flex-col-7
  align-items: flex-start
  display: flex
  flex-direction: column
  gap: 19px
  margin-bottom: 2px
  min-height: 440px
  width: 448px

.rectangle-9-1
  height: 296px
  object-fit: cover
  width: 448px

.for-a-small-addition,
.algt-offers-a-3-hour
  letter-spacing: 0
  line-height: 19.2px
  min-height: 125px
  width: 448px

.flex-col-8
  align-items: center
  align-self: flex-start
  display: flex
  flex-direction: column
  margin-left: 94px
  min-height: 561px
  width: 517px

.additional-service
  letter-spacing: 0
  line-height: normal
  min-height: 60px
  min-width: 517px

.rectangle-94
  height: 296px
  margin-right: 1.0px
  margin-top: 61px
  object-fit: cover
  width: 448px

.the-algt-sealing-sys
  letter-spacing: 0
  line-height: 19.2px
  margin-right: 1.0px
  margin-top: 19px
  min-height: 125px
  width: 448px

.flex-col-9
  align-items: flex-start
  display: flex
  flex-direction: column
  gap: 19px
  margin-bottom: 2px
  margin-left: 93px
  min-height: 440px
  width: 448px
</style>
