<template>
  <div class="container-center-horizontal">
    <div class="news-1920 screen">
      <div class="overlap-group3" :style="{ 'background-image': 'url(' + overlapGroup + ')' }">
        <div class="rectangle-11"></div>
        <left-nav />
        <info v-bind="globalData" />
      </div>
      <div class="overlap-group2">
        <h1 class="news-diamond-report">{{ item.title }}</h1>
        <!-- <tag3 :text5="item.tag"/> -->
        <p class="the-gemstone-market heebo-normal-mine-shaft-16px" v-html="item.content"></p>
        <!-- <img class="rectangle-74" :src="item.rectangle69" alt="Rectangle 74" /> -->
        <img class="rectangle-74" v-for="(img) in item.images" :src="img" v-bind:key="item.id"/>

        <a v-for="(lk) in item.links" :href="lk.url" v-bind:key="item.id">
          <img style="margin-top: 20px;" :src="lk.img"/>
        </a>
        
      </div>
      <foot v-bind="globalData" />
    </div>
  </div>
</template>

<script>
import LeftNav from "./components/Navbar";
import Tag3 from "./components/Tag3";
import Foot from "./components/Foot1920";
import Info from "./components/Info";
export default {
  name: "NewsDetail1920",
  components: {
    LeftNav,
    Tag3,
    Foot,
    Info,
  },
  data() {
    return {
      item: '',
    }
  },
  created() {
    let i = this.$route.query.index
    this.item = this.newsListData[i] 
  },
  props: [
    "globalData",
    "overlapGroup",
    "newsListData",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../variables'

.news-1920
  align-items: center
  background-color: $selago
  display: flex
  flex-direction: column
  gap: 40px
  // height: 2929px
  position: relative
  width: 1920px

.overlap-group3
  background-position: 50% 50%
  background-size: cover
  height: 400px
  position: relative
  width: 1920px

.rectangle-11
  -webkit-backdrop-filter: blur(30px) brightness(100%)
  backdrop-filter: blur(30px) brightness(100%)
  background-color: $black-2
  height: 400px
  left: 0
  position: absolute
  top: 0
  width: 1920px

.phone
  left: 1537px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.infoalgtlabscom
  cursor: pointer
  left: 1705px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.rectangle-12
  border: 1px solid
  border-color: $white
  border-radius: 56px
  height: 24px
  left: 1323px
  position: absolute
  top: 47px
  width: 160px

.report-number
  left: 1336px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 51px
  white-space: nowrap

.logo-01
  height: 87px
  left: 810px
  position: absolute
  top: 157px
  width: 300px

.vector
  height: 20px
  left: 1507px
  position: absolute
  top: 49px
  width: 20px

.vector-1
  height: 16px
  left: 1675px
  position: absolute
  top: 51px
  width: 20px

.overlap-group2
  align-items: center
  background-color: $white
  display: flex
  flex-direction: column
  // min-height: 1738px
  padding: 59px 0
  position: relative
  width: 1440px

.news-diamond-report
  align-self: flex-start
  color: $mine-shaft
  font-family: $font-family-plus_jakarta_sans
  font-size: 36px
  font-weight: 700
  letter-spacing: 0
  line-height: normal
  margin-left: 120px
  min-height: 45px

.the-gemstone-market
  letter-spacing: 0
  line-height: 25.6px
  margin-top: 59px
  // min-height: 107px
  width: 1200px

.rectangle-74
  // height: 313px
  margin-top: 41px
  object-fit: cover
  width: 1080px

.the-gemstone-market-1
  letter-spacing: 0
  line-height: 25.6px
  margin-top: 39px
  min-height: 107px
  width: 1200px

.antwerp-laboratory-f
  letter-spacing: 0
  line-height: 25.6px
  margin-top: 40px
  min-height: 50px
  width: 1200px

.the-gemstone-market-2
  letter-spacing: 0
  line-height: 25.6px
  margin-top: 40px
  min-height: 99px
  width: 1200px

.antwerp-laboratory-f-1
  letter-spacing: 0
  line-height: 25.6px
  margin-top: 40px
  min-height: 55px
  width: 1200px

.rectangle-75
  height: 450px
  margin-top: 41px
  width: 1080px

.the-gemstone-market-3
  letter-spacing: 0
  line-height: 25.6px
  margin-top: 39px
  min-height: 99px
  width: 1200px
</style>
