<template>
  <div class="container-center-horizontal">
    <div class="index-1024 screen">
      <div class="overlap-group3-2">
        <img class="rectangle-21" :src="overlapGroup7" alt="Rectangle 21" />
        <div class="rectangle-11-2"></div>
        <left-nav />
        <el-input v-model="rNumber" :placeholder="reportNumber" @keyup.enter.native="showCertificateOld"></el-input>
        <info v-bind="globalData" />
      </div>
      <div class="overlap-group7-1">
        <div class="flex-col-26">
          <div class="about-algt-6 plusjakartasans-bold-mine-shaft-32px">{{ aboutAlgt }}</div>
          <p class="the-gemstone-market-2 heebo-normal-mine-shaft-14px">{{ theGemstoneMarket }}</p>
          <div class="more-details-1">
            <router-link to="/m/about" style="visited: {text-align:center; color:#2a2f88;}">{{ moreDetails
            }}</router-link>
          </div>
          <p class="heebo-normal-mine-shaft-14px"><b>NEW!</b> ALGT launches first 3D tour in Antwerp laboratory: <a href="https://my.matterport.com/show/?m=17d3L1aeZZL&lang=en&hl=1&help=1" target="_blank">More Details</a></p>
        </div>
        <img class="rectangle-10-1" :src="rectangle" alt="Rectangle 10" />
      </div>
      <div class="overlap-group6-2" :style="{ 'background-image': 'url(' + overlapGroup1 + ')' }">
        <div class="rectangle-50-2"></div>
        <p class="igi-gg-hrd-gg-acam-gg-feeg-eg-2 plusjakartasans-normal-white-16px">{{ theCaratColorCl1 }}</p>
        <div class="founder-4 plusjakartasans-normal-white-16px">{{ founder1 }}</div>
        <p class="m-sc-igi-gg-acam-gg-feeg-eg-2 plusjakartasans-normal-white-16px">{{ theCaratColorCl2 }}</p>
        <div class="founder-5 plusjakartasans-normal-white-16px">{{ founder2 }}</div>
        <img class="mask-group-4" :src="maskGroup1" alt="Mask group" />
        <div class="segers-marc-2 plusjakartasans-bold-white-28px">{{ name1 }}</div>
        <img class="mask-group-5" :src="maskGroup2" alt="Mask group" />
        <div class="mingyue-yang-2 plusjakartasans-bold-white-28px">{{ text6 }}</div>
      </div>
      <h1 class="title-2 plusjakartasans-bold-mine-shaft-32px">{{ whyChooseAlgt }}</h1>
      <div class="overlap-group-container-5">
        <div class="overlap-group4-1">
          <img class="union-19" src="img/union-19.svg" alt="Union" />
          <div class="highly-qualified-professionals-1 heebo-normal-black-20px" v-html="highlyQualifiedProfessionals">
          </div>
        </div>
        <div class="overlap-group1-3">
          <div class="group-28-2">
            <div class="overlap-group1-5">
              <img class="subtract-8" src="img/subtract-8.svg" alt="Subtract" />
              <img class="subtract-9" src="img/subtract-9.svg" alt="Subtract" />
              <img class="subtract-10" src="img/subtract-10.svg" alt="Subtract" />
              <img class="subtract-11" src="img/subtract-11.svg" alt="Subtract" />
              <img class="vector-1-2" src="img/vector-1-3.svg" alt="Vector 1" />
              <img class="vector-2-2" src="img/vector-2-3.svg" alt="Vector 2" />
            </div>
            <img class="rectangle-121-2" src="img/rectangle-121-2.svg" alt="Rectangle 121" />
          </div>
          <div class="latest-technology-2 heebo-normal-black-20px">{{ latestTechnology }}</div>
        </div>
        <div class="overlap-group1-3">
          <img class="union-20" src="img/union-17-2.svg" alt="Union" />
          <div class="total-transparency-2 heebo-normal-black-20px">{{ totalTransparency }}</div>
        </div>
        <div class="overlap-group1-3">
          <img class="union-21" src="img/union-16.svg" alt="Union" />
          <div class="x5-star-services-2 heebo-normal-black-20px">{{ x5StarServices }}</div>
        </div>
      </div>
      <div class="overlap-group-container-6">
        <div class="overlap-group-3">
          <img class="union-22" src="img/union-2.svg" alt="Union" />
          <div class="fast-turnaround-time-2 heebo-normal-black-20px">{{ fastTurnaroundTime }}</div>
        </div>
        <div class="overlap-group-3">
          <img class="group-29-2" src="img/group-29-2@2x.png" alt="Group 29" />
          <div class="competitive-prices-2 heebo-normal-black-20px">{{ competitivePrices }}</div>
        </div>
        <div class="overlap-group11-2">
          <img class="union-23" src="img/union-15-2.svg" alt="Union" />
          <p class="guaranteed-peace-of-2 heebo-normal-black-20px">{{ guaranteedPeaceOf }}</p>
        </div>
      </div>
      <div class="overlap-group2-2">
        <div class="our-reports-4 plusjakartasans-bold-mine-shaft-32px">{{ title }}</div>
        <div class="flex-row-21">
          <div class="flex-col-27">
            <div class="name-4 plusjakartasans-bold-mine-shaft-24px">
              <router-link to="/m/DiamondReport" style="visited: {text-align:center; color:#333;}">
                {{ name2 }}
              </router-link>  
            </div>
            <p class="the-carat-color-cl-4 heebo-normal-mine-shaft-14px">{{ theCaratColorCl3 }}</p>
          </div>
          <div class="overlap-group18-2">
            <div class="rectangle-5-2"></div>
            <img class="rectangle-3-2" :src="overlapGroup2" alt="Rectangle 35" />
          </div>
        </div>
      </div>
      <div class="overlap-group1-4">
        <div class="overlap-group-4">
          <div class="rectangle-5-2"></div>
          <img class="rectangle-3-2" :src="rectangle36" alt="Rectangle 36" />
        </div>
        <div class="flex-col-28">
          <div class="gemstone-report-1 plusjakartasans-bold-mine-shaft-24px">
            <router-link to="/m/GemstoneReport" style="visited: {text-align:center; color:#333;}">
              {{ gemstoneReport }}
            </router-link>
          </div>
          <p class="the-certificate-iden-2 heebo-normal-mine-shaft-14px">{{ theCertificateIden1 }}</p>
        </div>
      </div>
      <div class="overlap-group12-2">
        <div class="flex-col-29">
          <div class="name-5 plusjakartasans-bold-mine-shaft-24px">
            <router-link to="/m/PearlReport" style="visited: {text-align:center; color:#333;}">
                {{ name3 }}
            </router-link>
          </div>
          <p class="the-carat-color-cl-5 heebo-normal-mine-shaft-14px">{{ theCaratColorCl4 }}</p>
        </div>
        <div class="overlap-group-4">
          <div class="rectangle-5-2"></div>
          <img class="rectangle-3-2" :src="rectangle38" alt="Rectangle 38" />
        </div>
      </div>
      <div class="overlap-group1-4">
        <div class="overlap-group-4">
          <div class="rectangle-5-2"></div>
          <img class="rectangle-3-2" :src="rectangle37" alt="Rectangle 37" />
        </div>
        <div class="flex-col-30">
          <p class="lab-grown-diamond-report plusjakartasans-bold-mine-shaft-24px">
            <router-link to="/m/OtherGemstoneReport" style="visited: {text-align:center; color:#333;}">
              {{ labGrownDiamondReport }}
            </router-link>
          </p>
          <p class="the-certificate-iden-3 heebo-normal-mine-shaft-14px">{{ theCertificateIden2 }}</p>
        </div>
      </div>
      <div class="overlap-group14-1">
        <div class="rectangle-17-1"></div>
        <div class="premium-report-2 plusjakartasans-bold-mine-shaft-24px">
          <router-link to="/m/PremiumReport" style="visited: {text-align:center; color:#333;}">
            {{ premiumReport }}
          </router-link>
        </div>
        <p class="encased-in-a-lush-tr-2 heebo-normal-mine-shaft-14px">{{ encasedInALushTr }}</p>
        <img class="rectangle-44-2" :src="rectangle44" alt="Rectangle 44" />
      </div>
      <div class="overlap-group8-1">
        <div class="overlap-group8-item">
          <img class="lase-inscription-1-2" :src="laseInscription1" alt="lase inscription 1" />
          <div class="lase-inscription-3 plusjakartasans-bold-mine-shaft-24px">{{ laseInscription }}</div>
        </div>
        <div class="flex-col-31">
          <div class="service-7 plusjakartasans-bold-mine-shaft-32px">{{ service }}</div>
          <div class="quick-check-container">
            <img class="quick-check1-1-1" :src="quickCheck1" alt="Quick Check(1) 1" />
            <div class="quick-check-2 plusjakartasans-bold-mine-shaft-24px">{{ quickCheck }}</div>
          </div>
        </div>
        <div class="overlap-group8-item">
          <img class="seal-1-1" :src="seal1" alt="Seal 1" />
          <div class="place-6 plusjakartasans-bold-mine-shaft-24px">{{ seal }}</div>
        </div>
      </div>
      <foot1024 v-bind="globalData" />
    </div>
  </div>
</template>

<script>

import LeftNav from "./components/Navbar";
import Foot1024 from "./components/Foot1024";
import Info from "./components/Info";
import 'element-ui/lib/theme-chalk/index.css';

export default {
  name: "Home1024",
  components: {
    LeftNav,
    Foot1024,
    Info,
  },
  data() {
    return {
      rNumber: '',
      rules: {
        rNumber: [{
          type: "number",
          required: true,
          message: "Must be a number",
          trigger: "blur"
        }],
      }
    }
  },
  methods: {
    showCertificateOld() {
      console.log(this.rNumber)
      // https://lab.algtlabs.com/stones/cert?rn=020390110&t=b&l=e&f=api
      window.open('https://lab.algtlabs.com/stones/cert?rn=' + this.rNumber + '&t=b&l=e&f=api', '_blank')
    },
    showCertificate() {
      console.log(this.rNumber)
      // let region = parseInt(this.rNumber.substring(0, 1))
      // if (region % 2 == 0) { // 偶数代表中国
      if (this.rNumber.trim().length == 9) { // 9位代表中国
        this.$axios.get('http://admin.algtlabs.com.cn/diamond/system/report/v',
          {
            params: { 'number': this.rNumber }
          }
        ).then(res => {
          // console.log(res.data);
          if (res.data.code == 200) {
            let url = res.data.data;
            window.open('http://admin.algtlabs.com.cn/diamond' + url, '_blank')
          } else {
            this.$message({
              message: 'report number error',
              type: 'warning'
            });
          }
        }).catch(err => {
          // console.log(err)
          this.$message({
            message: 'report number error',
            type: 'warning'
          });
        })
      } else { // 奇数代表欧洲
        // https://lab.algtlabs.com/stones/cert?rn=020390110&t=b&l=e&f=api
        window.open('https://lab.algtlabs.com/stones/cert?rn=' + this.rNumber + '&t=b&l=e&f=api', '_blank')
      }
    }
  },
  props: [
    "rectangle44",
    "whyChooseAlgt",
    "theCaratColorCl4",
    "name3",
    "theCaratColorCl3",
    "overlapGroup2",
    "globalData",
    "overlapGroup7",
    "rectangle21",
    "reportNumber",
    "rectangle",
    "phone",
    "infoAlgtlabsCom",
    "aboutAlgt",
    "theGemstoneMarket",
    "moreDetails",
    "rectangle10",
    "overlapGroup6",
    "igiGgHrdGgAcamGgFeegEg",
    "founder1",
    "mscIgiGgAcamGgFeegEg",
    "founder2",
    "maskGroup1",
    "segersMarc",
    "maskGroup2",
    "mingyueYang",
    "title",
    "highlyQualifiedProfessionals",
    "latestTechnology",
    "totalTransparency",
    "x5StarServices",
    "fastTurnaroundTime",
    "competitivePrices",
    "guaranteedPeaceOf",
    "ourReports",
    "name1",
    "theCaratColorCl1",
    "rectangle35",
    "rectangle36",
    "gemstoneReport",
    "theCertificateIden1",
    "name2",
    "text6",
    "overlapGroup1",
    "theCaratColorCl2",
    "rectangle38",
    "rectangle37",
    "labGrownDiamondReport",
    "theCertificateIden2",
    "premiumReport",
    "encasedInALushTr",
    "laseInscription1",
    "laseInscription",
    "service",
    "quickCheck1",
    "quickCheck",
    "seal",
    "seal1",
    "place",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../variables'

.index-1024
  align-items: center
  background-color: $selago-2
  display: flex
  flex-direction: column
  height: 4677px
  overflow: hidden
  position: relative
  width: 1024px

.overlap-group3-2
  align-self: flex-start
  height: 352px
  position: relative
  width: 1960px

.rectangle-21
  height: 352px
  left: 0
  object-fit: cover
  position: absolute
  top: 0
  width: 1024px

.rectangle-11-2
  background-color: $black-2
  height: 352px
  left: 0
  position: absolute
  top: 0
  width: 1024px

.rectangle-12-1
  background-color: $white
  border-radius: 28px
  height: 32px
  left: 352px
  position: absolute
  top: 214px
  width: 320px

.report-number-2
  left: 372px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 221px
  white-space: nowrap

.logo-01-6
  height: 43px
  left: 437px
  position: absolute
  top: 140px
  width: 150px

.group-26
  align-items: flex-start
  display: flex
  height: 20px
  left: 709px
  min-width: 279px
  position: absolute
  top: 28px

.vector-stroke-1
  height: 20px
  width: 20px

.phone-1
  letter-spacing: 0
  line-height: normal
  margin-left: 10px
  margin-top: 1px
  min-height: 14px
  min-width: 81px
  white-space: nowrap

.vector-17
  align-self: center
  height: 16px
  margin-left: 33px
  width: 20px

.infoalgtlabscom-5
  cursor: pointer
  letter-spacing: 0
  line-height: normal
  margin-left: 10px
  margin-top: 1px
  min-height: 14px
  white-space: nowrap

.overlap-group7-1
  align-items: flex-start
  background-color: $white
  display: flex
  gap: 47px
  height: 430px
  justify-content: flex-end
  min-width: 1024px
  padding: 39px 0

.flex-col-26
  align-items: flex-start
  display: flex
  flex-direction: column
  gap: 20px
  min-height: 322px
  width: 398px

.about-algt-6
  letter-spacing: 0
  line-height: normal
  min-height: 40px

.the-gemstone-market-2
  letter-spacing: 0
  line-height: 22.4px
  min-height: 218px
  width: 398px

.more-details-1
  color: $brand
  font-family: $font-family-heebo
  font-size: $font-size-xl
  font-weight: 400
  letter-spacing: 0
  line-height: 24px
  white-space: nowrap

.rectangle-10-1
  align-self: center
  height: 298px
  object-fit: cover
  width: 529px

.overlap-group6-2
  background-position: 50% 50%
  background-size: cover
  height: 400px
  position: relative
  width: 1024px

.rectangle-50-2
  background-color: $black-2
  height: 400px
  left: 0
  position: absolute
  top: 0
  width: 1024px

.igi-gg-hrd-gg-acam-gg-feeg-eg-2
  left: 163px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 295px

.founder-4
  left: 263px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 268px

.m-sc-igi-gg-acam-gg-feeg-eg-2
  left: 596px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 295px

.founder-5
  left: 682px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 268px

.mask-group-4
  height: 128px
  left: 238px
  position: absolute
  top: 80px
  width: 128px

.segers-marc-2
  left: 216px
  letter-spacing: 0
  line-height: normal
  position: absolute
  text-align: center
  top: 223px

.mask-group-5
  height: 128px
  left: 657px
  position: absolute
  top: 80px
  width: 128px

.mingyue-yang-2
  left: 626px
  letter-spacing: 0
  line-height: normal
  position: absolute
  text-align: center
  top: 223px

.title-2
  letter-spacing: 0
  line-height: normal
  margin-top: 59px
  min-height: 40px
  min-width: 316px

.overlap-group-container-5
  align-items: flex-start
  display: flex
  gap: 10px
  height: 224px
  margin-top: 41px
  min-width: 910px

.overlap-group4-1
  align-items: center
  background: linear-gradient(180deg, rgba(167.9, 176.61, 255, 0.09) 0%, rgb(255, 255, 255) 100%)
  border-radius: 16px
  box-shadow: 0px 4px 20px #0000000d
  display: flex
  flex-direction: column
  gap: 43px
  min-height: 224px
  padding: 21px 0
  width: 220px

.union-19
  height: 87px
  margin-top: 12px
  width: 110px

.highly-qualified-professionals-1,
.latest-technology-2,
.total-transparency-2,
.x5-star-services-2,
.fast-turnaround-time-2,
.competitive-prices-2
  letter-spacing: 0
  line-height: 20px
  min-height: 40px
  width: 188px

.overlap-group1-3
  align-items: center
  background: linear-gradient(180deg, rgba(167.9, 176.61, 255, 0.09) 0%, rgb(255, 255, 255) 100%)
  border-radius: 16px
  box-shadow: 0px 4px 20px #0000000d
  display: flex
  flex-direction: column
  gap: 42px
  min-height: 224px
  padding: 21px 0
  width: 220px

.group-28-2
  align-items: center
  display: flex
  flex-direction: column
  margin-right: 0.83px
  margin-top: 10px
  min-height: 90px
  width: 85px

.overlap-group1-5
  height: 79px
  margin-right: 0
  position: relative
  width: 85px

.subtract-8
  height: 37px
  left: 0
  position: absolute
  top: 31px
  width: 37px

.subtract-9
  height: 25px
  left: 24px
  position: absolute
  top: 54px
  width: 28px

.subtract-10
  height: 34px
  left: 9px
  position: absolute
  top: 5px
  width: 37px

.subtract-11
  height: 21px
  left: 43px
  position: absolute
  top: 4px
  width: 26px

.vector-1-2
  height: 9px
  left: 63px
  position: absolute
  top: 0
  width: 22px

.vector-2-2
  height: 9px
  left: 63px
  position: absolute
  top: 19px
  width: 22px

.rectangle-121-2
  height: 11px
  margin-right: 10.71px
  width: 49px

.union-20
  height: 90px
  margin-right: 4.0px
  margin-top: 10px
  width: 90px

.union-21
  height: 92px
  margin-right: 0
  margin-top: 8px
  width: 100px

.overlap-group-container-6
  align-items: flex-start
  display: flex
  gap: 10px
  height: 224px
  margin-top: 20px
  min-width: 680px

.overlap-group-3
  align-items: center
  background: linear-gradient(180deg, rgba(167.9, 176.61, 255, 0.09) 0%, rgb(255, 255, 255) 100%)
  border-radius: 16px
  box-shadow: 0px 4px 20px #0000000d
  display: flex
  flex-direction: column
  gap: 36px
  min-height: 224px
  padding: 21px 0
  width: 220px

.union-22
  height: 90px
  margin-right: 0.87px
  margin-top: 16px
  width: 69px

.group-29-2
  height: 90px
  margin-right: 0.83px
  margin-top: 16px
  width: 89px

.overlap-group11-2
  align-items: center
  background: linear-gradient(180deg, rgba(167.9, 176.61, 255, 0.09) 0%, rgb(255, 255, 255) 100%)
  border-radius: 16px
  box-shadow: 0px 4px 20px #0000000d
  display: flex
  flex-direction: column
  gap: 24px
  min-height: 224px
  padding: 15px 0
  width: 220px

.union-23
  height: 90px
  margin-left: 0.89px
  margin-top: 18px
  width: 131px

.guaranteed-peace-of-2
  letter-spacing: 0
  line-height: 20px
  min-height: 62px
  width: 188px

.overlap-group2-2
  align-items: flex-end
  background-color: $white
  display: flex
  flex-direction: column
  gap: 61px
  margin-top: 60px
  min-height: 499px
  padding: 30px
  width: 1024px

.our-reports-4
  align-self: center
  letter-spacing: 0
  line-height: normal
  margin-top: 29px
  min-height: 40px
  min-width: 226px

.flex-row-21
  align-items: center
  display: flex
  gap: 106px
  min-width: 944px

.flex-col-27
  align-items: flex-start
  display: flex
  flex-direction: column
  gap: 20px
  margin-bottom: 28.0px
  min-height: 103px
  width: 398px

.name-4,
.gemstone-report-1,
.name-5
  letter-spacing: 0
  line-height: normal
  min-height: 30px

.the-carat-color-cl-4
  letter-spacing: 0
  line-height: 21px
  min-height: 53px
  width: 398px

.overlap-group18-2
  height: 309px
  position: relative
  width: 440px

.rectangle-5-2
  background-color: $brand
  height: 299px
  left: 10px
  opacity: 0.55
  position: absolute
  top: 10px
  width: 430px

.rectangle-3-2
  height: 299px
  left: 0
  object-fit: cover
  position: absolute
  top: 0
  width: 430px

.overlap-group1-4
  align-items: center
  background-color: $selago
  display: flex
  gap: 40px
  height: 379px
  min-width: 1024px
  padding: 30px

.overlap-group-4
  align-self: flex-end
  height: 309px
  position: relative
  width: 440px

.flex-col-28
  align-items: flex-start
  display: flex
  flex-direction: column
  gap: 20px
  margin-bottom: 12.0px
  min-height: 121px
  width: 470px

.the-certificate-iden-2
  letter-spacing: 0
  line-height: 21px
  min-height: 71px
  width: 470px

.overlap-group12-2
  align-items: center
  background-color: $white
  display: flex
  gap: 113px
  height: 379px
  justify-content: flex-end
  min-width: 1024px
  padding: 30px

.flex-col-29
  align-items: flex-start
  display: flex
  flex-direction: column
  gap: 20px
  margin-bottom: 28.0px
  min-height: 103px
  width: 391px

.the-carat-color-cl-5
  letter-spacing: 0
  line-height: 21px
  min-height: 53px
  width: 391px

.flex-col-30
  align-items: flex-start
  display: flex
  flex-direction: column
  gap: 20px
  margin-bottom: 15.0px
  min-height: 118px
  width: 470px

.lab-grown-diamond-report
  letter-spacing: 0
  line-height: normal
  min-height: 30px
  width: 424px

.the-certificate-iden-3
  letter-spacing: 0
  line-height: 21px
  min-height: 68px
  width: 470px

.overlap-group14-1
  height: 339px
  position: relative
  width: 1024px

.rectangle-17-1
  background-color: $white
  height: 299px
  left: 0
  position: absolute
  top: 0
  width: 1024px

.premium-report-2
  left: 50px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 103px

.encased-in-a-lush-tr-2
  left: 50px
  letter-spacing: 0
  line-height: 21px
  position: absolute
  top: 153px
  width: 391px

.rectangle-44-2
  height: 299px
  left: 554px
  position: absolute
  top: 40px
  width: 430px

.overlap-group8-1
  align-items: flex-end
  background-color: $white
  display: flex
  gap: 12px
  height: 424px
  margin-top: 40px
  min-width: 1024px
  padding: 39px 35px

.overlap-group8-item
  align-items: center
  background-color: $selago
  border-radius: 20px
  display: flex
  flex-direction: column
  gap: 16px
  margin-bottom: 1px
  min-height: 264px
  width: 310px

.lase-inscription-1-2,
.quick-check1-1-1,
.seal-1-1
  height: 191px
  object-fit: cover
  width: 310px

.lase-inscription-3
  letter-spacing: 0
  line-height: normal
  min-height: 30px
  min-width: 220px

.flex-col-31
  align-items: center
  align-self: flex-start
  display: flex
  flex-direction: column
  gap: 41px
  min-height: 345px
  width: 310px

.service-7
  letter-spacing: 0
  line-height: normal
  margin-right: 10.0px
  min-height: 40px
  min-width: 118px

.quick-check-container
  align-items: center
  background-color: $selago
  border-radius: 20px
  display: flex
  flex-direction: column
  gap: 16px
  min-height: 264px
  width: 310px

.quick-check-2
  letter-spacing: 0
  line-height: normal
  margin-right: 10.0px
  min-height: 30px
  min-width: 170px

.place-6
  letter-spacing: 0
  line-height: normal
  min-height: 30px
  min-width: 60px

.el-input
  border-radius: 48px
  height: 48px
  left: 352px
  position: absolute
  top: 214px
  width: 320px
  font-size: 20px
</style>

<style lang="sass">
.el-input__inner
  border-radius: 48px
.input__inner
  font-size: 14px
</style>