<template>
  <div class="container-center-horizontal">
    <div class="about-375 screen">
      <div class="overlap-group2-1">
        <div class="overlap-group4">
          <img class="union-2" src="img/union-12.svg" alt="Union" @click="drawer = true" />
          <img class="logo-01-2" src="img/logo-01-2.svg" alt="logo-01" />
        </div>

<!--         
        <div class="about-us">{{ title }}</div>
        <p class="the-gemstone-market-1 heebo-normal-mine-shaft-11-8px">{{ theGemstoneMarket }}</p>
        <div class="image-container">
          <img class="image-9-stroke" src="img/image-9--stroke-.svg" alt="image 9 (Stroke)" />
          <img class="image-9" :src="image9" alt="image 9" />
        </div>
        <p class="antwerp-laboratory-f-1 heebo-normal-mine-shaft-11-8px">{{ antwerpLaboratoryF }}</p>
        <div class="rectangle-119"></div>
        <p class="algt-is-equipped-wit-1 heebo-normal-white-11-8px">{{ algtIsEquippedWit }}</p>
        <p class="our-team-of-gemmolog-1 heebo-normal-white-11-8px">{{ ourTeamOfGemmolog }}</p>
        <p class="our-goal-is-to-accom-1 heebo-normal-mine-shaft-11-8px">{{ ourGoalIsToAccom }}</p>
        <img class="dsc4285-1-1" :src="dsc42851" alt="DSC4285 1" />
        <img class="raman-pl-2" :src="ramanPl2" alt="RAMAN PL 2" />
        <img class="rectangle-73-1" :src="rectangle73" alt="Rectangle 73" />
        <img class="rectangle-74-1" :src="rectangle74" alt="Rectangle 74" />
        <p class="our-goal-is-to-accom-2 heebo-normal-mine-shaft-11-8px">{{ ourGoalIsToAccom }}</p>
 -->




        <div class="about-us">{{ title }}</div>
        <p class="the-gemstone-market-1 heebo-normal-mine-shaft-11-8px">{{ theGemstoneMarket }}</p>
        <div class="image-container">
          <img class="image-9-stroke" src="img/image-9--stroke-.svg" alt="image 9 (Stroke)" />
          <img class="image-9" :src="image9" alt="image 9" />
        </div>
        <div class="overlap-group3-1 heebo-normal-white-14px">
          <img class="dsc4285-1-1" :src="dsc42851" alt="DSC4285 1" />
          <p class="overlap-group3-item">{{ antwerpLaboratoryF }}</p>
          <p class="overlap-group3-item">{{ algtIsEquippedWit }}</p>
        </div>
        <img class="raman-pl-2" :src="ramanPl2" alt="RAMAN PL 2" />
        <p class="our-team-of-gemmolog-1 heebo-normal-mine-shaft-11-8px">{{ ourTeamOfGemmolog }}</p>
        <img class="rectangle-73-1" :src="rectangle73" alt="Rectangle 73" />
        <img class="rectangle-74-1" :src="rectangle74" alt="Rectangle 74" />
        <p class="our-goal-is-to-accom-1 heebo-normal-mine-shaft-11-8px">{{ ourGoalIsToAccom }}</p>
      </div>




        <div style="margin-top:-20px">
          <foot v-bind="globalData" />
        </div>
        <el-drawer direction="ltr" :visible.sync="drawer" :with-header="false" size="50%">
          <left-nav/>
        </el-drawer>
      </div>
    </div>
  <!-- </div> -->
</template>

<script>
import Group14 from "./components/Group14";
import Foot from "./components/Foot375";
import LeftNav from "./components/Navbar";
export default {
  name: "About375",
  components: {
    Group14,
    Foot,
    LeftNav,
  },
  data() {
    return {
      drawer: false,
    }
  },
  props: [
    "globalData",
    "title",
    "theGemstoneMarket",
    "image9",
    "antwerpLaboratoryF",
    "algtIsEquippedWit",
    "ourTeamOfGemmolog",
    "ourGoalIsToAccom",
    "dsc42851",
    "ramanPl2",
    "rectangle73",
    "rectangle74",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../variables'

.about-375
  align-items: flex-start
  background-color: $selago
  display: flex
  flex-direction: column
  height: 2846px
  position: relative
  width: 375px

.overlap-group2-1
  align-items: center
  background-color: $white
  display: flex
  flex-direction: column
  min-height: 2361px
  width: 375px

.overlap-group4
  align-items: flex-start
  background-color: $white
  box-shadow: 0px 0.98px 15.73px #33333326
  display: flex
  gap: 108px
  height: 46px
  margin-right: 0.3px
  min-width: 375px
  padding: 9.8px 14.7px

.union-2
  align-self: center
  height: 17px
  // margin-bottom: 0
  width: 19px

.logo-01-2
  height: 26px
  width: 88px

.about-us
  align-self: flex-start
  color: $mine-shaft
  font-family: $font-family-plus_jakarta_sans
  font-size: 23.6px
  font-weight: 700
  letter-spacing: 0
  line-height: normal
  margin-left: 20.66px
  margin-top: 19px
  min-height: 30px

.the-gemstone-market-1
  letter-spacing: 0
  line-height: 17.7px
  margin-right: 0.3px
  margin-top: 19px
  min-height: 200px
  width: 329px

.image-container
  height: 315px
  margin-right: 1.28px
  margin-top: 21px
  position: relative
  width: 263px

.image-9-stroke
  height: 301px
  left: 13px
  position: absolute
  top: 13px
  width: 250px

.image-9
  height: 301px
  left: 0
  object-fit: cover
  position: absolute
  top: 0
  width: 250px

.overlap-group3-1
  align-items: flex-end
  background-color: $brand
  display: flex
  flex-direction: column
  gap: 30px
  margin-top: 30px
  min-height: 637px
  padding: 19.7px 19px
  width: 375px

.dsc4285-1-1
  align-self: center
  height: 243px
  margin-right: 0.3px
  object-fit: cover
  width: 329px

.overlap-group3-item
  letter-spacing: 0
  line-height: 21px
  min-height: 147px
  width: 329px

.raman-pl-2
  height: 263px
  margin-right: 0.3px
  margin-top: 20px
  width: 329px
  object-fit: cover

.our-team-of-gemmolog-1
  letter-spacing: 0
  line-height: 17.7px
  margin-left: 2.0px
  margin-top: 19px
  min-height: 143px
  width: 329px

.rectangle-73-1
  height: 117px
  margin-left: 1.7px
  margin-top: 21px
  object-fit: cover
  width: 329px

.rectangle-74-1
  height: 322px
  margin-left: 1.7px
  margin-top: 20px
  object-fit: cover
  width: 329px

.our-goal-is-to-accom-1
  letter-spacing: 0
  line-height: 17.7px
  margin-left: 2.0px
  margin-top: 19px
  min-height: 71px
  width: 329px
</style>
