<template>
  <div :class="[`group-14`, className || ``]">
    <img class="rectangle-7-2" :src="rectangle73" alt="Rectangle 73" /><img
      class="rectangle-7-2 variant-1"
      :src="rectangle76"
      alt="Rectangle 76"
    />
  </div>
</template>

<script>
export default {
  name: "Group14",
  props: ["rectangle73", "rectangle76", "className"],
};
</script>

<style lang="sass" scoped>
.group-14
  align-items: flex-start
  display: flex
  margin-top: 21px
  min-width: 335px

.rectangle-7-2
  height: 126px
  object-fit: cover
  width: 168px

.group-14.group-15
  align-items: flex-end

.group-14.group-15 .rectangle-7-2
  height: 95px
  margin-bottom: 0

.group-14.group-15 .rectangle-7-2.variant-1
  object-fit: cover
  width: 168px
</style>
