<template>
  <div class="container-center-horizontal">
    <div class="pearl-report375 screen">
      <div class="overlap-group-1" :style="{ 'background-image': 'url(' + overlapGroup + ')' }">
        <div class="rectangle-11-1"></div>
        <h1 class="other-gemstone-report-1">{{ title }}</h1>
        <img class="logo-01-1" src="img/logo-01-12.svg" alt="logo-01" />
        <img class="union" src="img/union-26.svg" alt="Union" @click="drawer = true" />
      </div>
      <p class="we-are-happy-to-cons-1" v-html="weAreHappyToCons"></p>
      <div class="group-21">
        <el-carousel class="group-21-item">
            <el-carousel-item v-for="item in images" :key="item.url" class="group-21-item">
              <img class="group-21-item" :src="item.url" :alt="item.url" />
            </el-carousel-item>
          </el-carousel>
        <!-- <img class="group-21-item" :src="x1" alt="1" /> -->
        <!-- <img class="group-21-item" :src="x3" alt="3" /> -->
      </div>
      <foot-bottom v-bind="globalData" />
      <el-drawer direction="ltr" :visible.sync="drawer" :with-header="false" size="50%">
        <left-nav />
      </el-drawer>
    </div>
  </div>
</template>

<script>
import LeftNav from "../components/Navbar";
import FootBottom from "../components/FootBottom";
export default {
  name: "OtherGemstoneReport375",
  components: {
    LeftNav,
    FootBottom,
  },
  data() {
    return {
      drawer: false,
    }
  },
  props: [
    "globalData",
    "overlapGroup",
    "title",
    "weAreHappyToCons",
    "images",
    "x1",
    "x3",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'
.el-carousel::-webkit-scrollbar
  display: none
.pearl-report375
  align-items: center
  background-color: $selago
  display: flex
  flex-direction: column
  height: 1015px
  width: 375px

.overlap-group-1
  background-position: 50% 50%
  background-size: cover
  height: 200px
  position: relative
  width: 375px

.rectangle-11-1
  background-color: $black-2
  height: 200px
  left: 0
  position: absolute
  top: 0
  width: 375px

.other-gemstone-report-1
  color: $white
  font-family: $font-family-plus_jakarta_sans
  font-size: $font-size-xxl
  font-weight: 700
  left: 27px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 89px

.logo-01-1
  height: 26px
  left: 143px
  position: absolute
  top: 10px
  width: 90px

.union
  height: 17px
  left: 15px
  position: absolute
  top: 15px
  width: 19px

.we-are-happy-to-cons-1
  color: $mine-shaft
  font-family: $font-family-heebo
  font-size: $font-size-m
  font-weight: 400
  letter-spacing: 0
  line-height: 18px
  margin-top: 19px
  min-height: 254px
  width: 335px

.group-21
  align-items: flex-start
  display: flex
  margin-top: 21px
  min-width: 335px

.group-21-item
  height: 236px
  object-fit: cover
  width: 336px

.group-16
  align-items: flex-start
  display: flex
  gap: 16px
  height: 185px
  margin-right: 41.0px
  margin-top: 40px
  min-width: 274px

.logo-02-1
  height: 42px
  width: 34px

.flex-col
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 0
  min-height: 185px
  width: 214px

.about-algt-1
  letter-spacing: 0
  line-height: normal
  margin-left: 15px
  min-height: 20px

.flex-col-item
  letter-spacing: 0
  line-height: normal
  margin-left: 15px
  margin-top: 20px
  min-height: 20px

.x2023-algt-all-rights-reserved-1
  letter-spacing: 0
  line-height: normal
  margin-top: 30px
  min-height: 15px

</style>
