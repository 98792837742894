<template>
  <div class="container-center-horizontal">
    <div class="contact-375 screen">
      <div class="overlap-group3-1">
        <img class="union-2" src="img/union-12.svg" alt="Union" @click="drawer = true" />
        <img class="logo-01-1" src="img/logo-01-2.svg" alt="logo-01" />
      </div>
      <h1 class="text-13">{{ contact }}</h1>
      <div class="overlap-group1">
        <div class="text-1-1 plusjakartasans-bold-mine-shaft-16px">{{ addressName1 }}</div>
        <p class="belgium-antwerp-dia-1 heebo-normal-granite-gray-12px">{{ address1 }}</p>
        <div class="phone-2 heebo-normal-granite-gray-12px">{{ telephone1 }}</div>
        <img class="dsc42" :src="doorPic1" alt="DSC4285 1" />
      </div>
      <div class="overlap-group2-1">
        <div class="text-1-1 plusjakartasans-bold-mine-shaft-16px">{{ addressName2 }}</div>
        <p class="china-shanghai-yuyu-1 heebo-normal-granite-gray-12px">{{ address2 }}</p>
        <div class="phone-2 heebo-normal-granite-gray-12px">{{ telephone2 }}</div>
        <img class="dsc42" :src="doorPic2" alt="DSC4272 1" />
      </div>
      <foot v-bind="globalData" />
      <el-drawer direction="ltr" :visible.sync="drawer" :with-header="false" size="50%">
        <left-nav/>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import Foot from "./components/Foot375";
import LeftNav from "./components/Navbar";
export default {
  name: "Contact375",
  components: {
    Foot,
    LeftNav,
  },
  data() {
    return {
      drawer: false,
    }
  },
  props: [
  "globalData",
    "overlapGroup",
    "contact",
    "addressName1",
    "address1",
    "telephone1",
    "doorPic1",
    "addressName2",
    "address2",
    "telephone2",
    "doorPic2",
    "feePdf",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../variables'

.contact-375
  align-items: center
  background-color: $selago
  display: flex
  flex-direction: column
  // height: 1622px
  position: relative
  width: 375px

.overlap-group3-1
  align-items: flex-start
  background-color: $white
  box-shadow: 0px 1px 16px #33333326
  display: flex
  gap: 109px
  height: 47px
  min-width: 375px
  padding: 10px 15px

.union-2
  align-self: center
  height: 17px
  width: 19px

.logo-01-1
  height: 26px
  width: 90px

.text-13
  align-self: flex-start
  color: $mine-shaft
  font-family: $font-family-plus_jakarta_sans
  font-size: $font-size-xxl
  font-weight: 700
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  margin-top: 19px
  min-height: 30px

.overlap-group1
  align-items: flex-start
  background-color: $white
  display: flex
  flex-direction: column
  margin-top: 21px
  // min-height: 495px
  padding: 19px 0
  width: 355px

.text-1-1
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  min-height: 20px

.belgium-antwerp-dia-1
  align-self: center
  letter-spacing: 0
  line-height: 18px
  margin-top: 20px
  min-height: 36px
  width: 315px

.phone-2
  letter-spacing: 0
  line-height: 18px
  margin-left: 20px
  margin-top: 10px
  white-space: nowrap

.dsc42
  align-self: center
  // height: 331px
  margin-top: 21px
  object-fit: cover
  width: 315px

.overlap-group2-1
  align-items: flex-start
  background-color: $white
  display: flex
  flex-direction: column
  margin-top: 20px
  // min-height: 477px
  padding: 19px 0
  width: 355px

.china-shanghai-yuyu-1
  letter-spacing: 0
  line-height: 18px
  margin-left: 20px
  margin-top: 20px
  white-space: nowrap



  
  
  
  
.logo-02-2
  height: 42px
  left: 30px
  position: absolute
  top: 30px
  width: 34px

.service-5
  left: 30px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 221px

.place-5
  left: 30px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 261px

.about-algt-6
  left: 30px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 141px
  white-space: nowrap

.place-6
  left: 30px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 101px

.news-library-2
  left: 30px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 181px
  white-space: nowrap
</style>
