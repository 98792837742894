<template>
  <div :class="[`fee-84`, className || ``]">
    <div class="number-2 valign-text-middle plusjakartasans-bold-cosmic-cobalt-14px" v-html="number"></div>
  </div>
</template>

<script>
export default {
  name: "Fee2",
  props: ["number", "className"],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'

.fee-84
  align-items: center
  background-color: $alto
  display: flex
  flex-wrap: wrap
  gap: 0px 0px
  // height: 34px
  padding: 8px
  position: relative
  width: 155px

.number-2
  align-self: stretch
  letter-spacing: 0
  line-height: normal
  margin-top: -1.00px
  position: relative
  width: 139px

.fee-84.fee-117,
.fee-84.fee-118,
.fee-84.fee-120,
.fee-84.fee-121,
.fee-84.fee-128,
.fee-84.fee-132,
.fee-84.fee-138,
.fee-84.fee-142
  height: 52px

.fee-84.fee-119
  align-items: flex-start
  height: 70px

.fee-84.fee-122,
.fee-84.fee-131,
.fee-84.fee-141
  height: 70px
</style>
