<template>
  <div :class="[`frame-1-2-1`, className || ``]">
    <img class="rectangle-69-9" :src="headView" alt="Rectangle 69" />
    <div class="name-2 plusjakartasans-bold-mine-shaft-24px">
      <router-link :to="{ path: `/m/newsDetail`, query:{ index: index }}" style="visited:{text-align:center;color:#333;}">
        {{ title }} 
      </router-link>
    </div>
    <!-- <tag2 :text5="tag" className="tag-3" /> -->
    <p class="encased-in-a-lush-tr-7 heebo-normal-granite-gray-16px" v-html="content">
    </p>
  </div>
</template>

<script>
import Tag2 from "./Tag2";
export default {
  name: "Frame12",
  components: {
    Tag2,
  },
  props: [
    "title",
    "headView",
    "tag",
    "className",
    "content",
    "index",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'

.frame-1-2-1
  align-items: flex-start
  background-color: $white
  display: flex
  flex-direction: column
  min-height: 487px
  padding: 20px 0
  position: relative
  width: 452px

.rectangle-69-9
  align-self: center
  height: 264px
  object-fit: cover
  position: relative
  width: 412px

.name-2
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  margin-top: 19px
  min-height: 30px
  min-width: 220px
  position: relative

.encased-in-a-lush-tr-7
  -webkit-box-orient: vertical
  -webkit-line-clamp: 4
  align-self: center
  display: -webkit-box
  letter-spacing: 0
  line-height: 19.2px
  margin-top: 19px
  min-height: 80px
  overflow: hidden
  position: relative
  text-overflow: ellipsis
  width: 412px

.frame-1-2-1.frame-1-2-2 .name-2,
.frame-1-2-1.frame-1-2-4 .name-2
  min-width: 236px

.frame-1-2-1.frame-1-2-3 .name-2,
.frame-1-2-1.frame-1-2 .name-2
  min-width: 168px
</style>
