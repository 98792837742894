<template>
  <div class="container-center-horizontal">
    <div class="report-check-1024 screen">
      <div class="overlap-group1-1">> -->
        <img class="rectangle-21" :src="overlapGroup1" alt="Rectangle 21" />
        <div class="rectangle-11-2"></div>
        <left-nav />
        <info v-bind="globalData" />
      </div>
      <h1 class="title plusjakartasans-bold-mine-shaft-32px">{{ title }}</h1>
      <p class="we-offer-both-a-verb-2" v-html="weOfferBothAVerb"></p>
      <div class="group-19 group-19-1">
        <el-carousel class="group-19-item">
          <el-carousel-item v-for="item in images" :key="item.url" class="group-19-item">
            <img class="group-19-item" :src="item.url" :alt="item.url" />
          </el-carousel-item>
        </el-carousel>
        <!-- <img class="group-19-item" :src="x1" alt="1" /> -->
        <!-- <img class="group-19-item" :src="x3" alt="3" /> -->
      </div>
      <!-- <p class="gcs-gemstone-reports-2 heebo-bold-black-20px">{{ gcsGemstoneReports }}</p>
      <p class="report-number-each-2 heebo-bold-black-16px">
        <span class="heebo-bold-mine-shaft-16px">{{ spanText1 }}</span><span class="heebo-normal-mine-shaft-16px">{{
          spanText2 }}</span>
      </p>
      <p class="report-check-1024-item heebo-bold-black-16px">
        <span class="heebo-bold-mine-shaft-16px">{{ spanText3 }}</span><span class="heebo-normal-mine-shaft-16px">{{
          spanText4 }}</span>
      </p>
      <p class="report-check-1024-item heebo-bold-black-16px">
        <span class="heebo-bold-mine-shaft-16px">{{ spanText5 }}</span><span class="heebo-normal-mine-shaft-16px">{{
          spanText6 }}</span>
      </p>
      <p class="identification-des-1 heebo-bold-black-16px">
        <span class="heebo-bold-mine-shaft-16px">{{ spanText7 }}</span><span class="heebo-normal-mine-shaft-16px">{{
          spanText8 }}</span>
      </p>
      <p class="report-check-1024-item-1 heebo-bold-black-16px">
        <span class="heebo-bold-mine-shaft-16px">{{ spanText9 }}</span><span class="heebo-normal-mine-shaft-16px">{{
          spanText10 }}</span>
      </p>
      <p class="report-check-1024-item-1 heebo-bold-black-16px">
        <span class="heebo-bold-mine-shaft-16px">{{ spanText11 }}</span><span class="heebo-normal-mine-shaft-16px">{{
          spanText12 }}</span>
      </p>
      <p class="report-check-1024-item heebo-bold-black-16px">
        <span class="heebo-bold-mine-shaft-16px">{{ spanText13 }}</span><span class="heebo-normal-mine-shaft-16px">{{
          spanText14 }}</span>
      </p>
      <p class="comment-describes-1 heebo-bold-black-16px">
        <span class="heebo-bold-mine-shaft-16px">{{ spanText15 }}</span><span class="heebo-normal-mine-shaft-16px">{{
          spanText16 }}</span>
      </p>
      <p class="origin-opinion-gcs-2 heebo-bold-black-16px">
        <span class="heebo-bold-mine-shaft-16px">{{ spanText17 }}</span><span class="heebo-normal-mine-shaft-16px">{{
          spanText18 }}</span>
      </p> -->
      <footBottom v-bind="globalData" />
    </div>
  </div>
</template>

<script>
import LeftNav from "../components/Navbar";
import FootBottom from "../components/FootBottom";
import Info from "../components/Info";
import 'element-ui/lib/theme-chalk/index.css';
export default {
  name: "GemstoneReport1024",
  components: {
    LeftNav,
    FootBottom,
    Info,
  },
  props: [
    "globalData",
    "overlapGroup1",
    "title",
    "weOfferBothAVerb",
    "images",
    "x1",
    "x3",
    "gcsGemstoneReports",
    "spanText1",
    "spanText2",
    "spanText3",
    "spanText4",
    "spanText5",
    "spanText6",
    "spanText7",
    "spanText8",
    "spanText9",
    "spanText10",
    "spanText11",
    "spanText12",
    "spanText13",
    "spanText14",
    "spanText15",
    "spanText16",
    "spanText17",
    "spanText18",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'

.group-19
  align-items: flex-start
  display: flex
  margin-top: 1px
  min-width: 832px

.group-19-item
  height: 586px
  object-fit: cover
  width: 832px

.group-19.group-19-1
  margin-top: 41px
  
.report-check-1024
  align-items: center
  background-color: $selago
  display: flex
  flex-direction: column
  // height: 1907px
  overflow: hidden
  position: relative
  width: 1024px

.overlap-group1-1
  height: 300px
  position: relative
  width: 1024px

.place-3
  left: 80px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.rectangle-21
  height: 300px
  left: 0
  object-fit: cover
  position: absolute
  top: 0
  width: 1024px

.rectangle-11-2
  background-color: $black-2
  height: 300px
  left: 0
  position: absolute
  top: 0
  width: 1024px

.logo-01-2
  height: 43px
  left: 437px
  position: absolute
  top: 157px
  width: 150px

.group-29
  align-items: flex-end
  display: flex
  height: 20px
  left: 651px
  min-width: 337px
  position: absolute
  top: 29px

.vector-stroke
  height: 20px
  width: 20px

.phone-1
  letter-spacing: 0
  line-height: normal
  margin-left: 10px
  min-height: 19px
  min-width: 108px
  white-space: nowrap

.vector-2
  align-self: center
  height: 16px
  margin-left: 30px
  width: 20px

.infoalgtlabscom-1
  cursor: pointer
  letter-spacing: 0
  line-height: normal
  margin-left: 10px
  min-height: 19px
  min-width: 135px
  white-space: nowrap

.title
  letter-spacing: 0
  line-height: normal
  margin-top: 39px
  min-height: 40px
  min-width: 284px

.we-offer-both-a-verb-2
  color: $mine-shaft
  font-family: $font-family-heebo
  font-size: $font-size-l
  font-weight: 400
  letter-spacing: 0
  line-height: 16.8px
  margin-top: 40px
  min-height: 75px
  width: 944px

.gcs-gemstone-reports-2
  letter-spacing: 0
  line-height: 24.0px
  margin-top: 39px
  width: 944px

.report-number-each-2
  letter-spacing: 0
  line-height: 19.2px
  margin-right: 56.0px
  margin-top: 25px
  min-height: 38px
  width: 888px

.report-check-1024-item
  letter-spacing: 0
  line-height: 19.2px
  margin-right: 56.0px
  margin-top: 10px
  width: 888px

.identification-des-1
  letter-spacing: 0
  line-height: 19.2px
  margin-right: 56.0px
  margin-top: 10px
  min-height: 57px
  width: 888px

.report-check-1024-item-1
  letter-spacing: 0
  line-height: 19.2px
  margin-right: 56.0px
  margin-top: 10px
  min-height: 38px
  width: 888px

.comment-describes-1
  letter-spacing: 0
  line-height: 19.2px
  margin-right: 56.0px
  margin-top: 10px
  min-height: 76px
  width: 888px

.origin-opinion-gcs-2
  letter-spacing: 0
  line-height: 19.2px
  margin-right: 59.0px
  margin-top: 10px
  min-height: 152px
  width: 885px

.foot
  align-items: flex-start
  align-self: flex-start
  display: flex
  margin-top: 62px
  min-width: 1036px

.overlap-group-2
  align-items: center
  background-color: $selago
  display: flex
  height: 100px
  justify-content: flex-end
  min-width: 1024px
  padding: 28px 30px

.logo-02-2
  align-self: flex-end
  height: 42px
  width: 34px

.about-algt-2
  letter-spacing: 0
  line-height: normal
  margin-left: 21px
  margin-top: 3.0px
  min-height: 15px
  min-width: 76px

.our-reports-1
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  margin-top: 3.0px
  min-height: 15px
  min-width: 85px

.service-1
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  margin-top: 3.0px
  min-height: 15px
  min-width: 51px

.video-2
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  margin-top: 3.0px
  min-height: 15px
  min-width: 39px

.place-4
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  margin-top: 3.0px
  min-height: 15px
  min-width: 59px

.x2023-algt-all-rights-reserved-2
  letter-spacing: 0
  line-height: normal
  margin-left: 295px
  margin-top: 1.0px
  min-height: 15px
  min-width: 214px
</style>
