<template>
  <div class="container-center-horizontal">
    <div class="service-1024 screen">
      <div class="flex-col-19">
        <div class="flex-col-20">
          <div class="flex-col-21">
            <div class="overlap-group4-1" :style="{ 'background-image': 'url(' + overlapGroup + ')' }">
              <div class="rectangle-11-2"></div>
              <left-nav/>
              <info v-bind="globalData"/>
            </div>
            <h1 class="title plusjakartasans-bold-mine-shaft-32px">
              {{ service }}
            </h1>
            <div class="flex-row-19">
              <div class="name-5 plusjakartasans-bold-black-18px">
                {{ diamondReportProps.reportName }}
              </div>
              <div class="view-more-5 plusjakartasans-bold-cosmic-cobalt-14px">
                <span class="plusjakartasans-bold-cosmic-cobalt-14px">
                  <router-link :to="`/m`+diamondReportProps.reportUrl" style="visited: {text-align:center; color:#2a2f88;}">
                    {{ viewMore }} {{ viewMore2 }}
                  </router-link>
                </span>
              </div>
            </div>
            <div class="flex-row-20 heebo-normal-granite-gray-12px">
              <img class="rectangle-8-2" :src="diamondReportProps.tag1.rectangle" alt="Rectangle 84" />
              <p class="the-algt-natural-dia" v-html="diamondReportProps.tag1.text"></p>
              <img class="rectangle-85" :src="diamondReportProps.tag2.rectangle" alt="Rectangle 85" />
              <p class="laboratory-grown-dia" v-html="diamondReportProps.tag2.text"></p>
            </div>
          </div>
          <div class="flex-row-21 heebo-normal-granite-gray-12px">
            <img class="rectangle-8-2" :src="diamondReportProps.tag3.rectangle" alt="Rectangle 86" />
            <p class="full-quality-assessm-1" v-html="diamondReportProps.tag3.text"></p>
            <img class="rectangle" :src="diamondReportProps.tag4.rectangle" alt="Rectangle 87" />
            <p class="we-also-specialise-i-1">{{ diamondReportProps.tag4.text }}</p>
          </div>
        </div>
        <div class="flex-row-22">
          <div class="flex-row-23">
            <div class="flex-col-22">
              <div class="gemstone-report-2 plusjakartasans-bold-black-18px">
                {{ gemstoneReportProps.reportName }}
              </div>
              <div class="overlap-group3-1">
                <img class="rectangle-88" :src="gemstoneReportProps.rectangle" alt="Rectangle 88" />
              </div>
            </div>
            <div class="flex-col-23">
              <div class="view-more-7 plusjakartasans-bold-cosmic-cobalt-14px">
                <span class="plusjakartasans-bold-cosmic-cobalt-14px">
                  <router-link :to="`/m`+gemstoneReportProps.reportUrl" style="visited: {text-align:center; color:#2a2f88;}">
                    {{ viewMore }}{{ viewMore2 }}
                  </router-link>
                </span>
              </div>
              <p class="algt-offers-full-rep-1 heebo-normal-granite-gray-12px" v-html="gemstoneReportProps.text"></p>
            </div>
          </div>
          <div class="flex-col-24">
            <div class="flex-row-24">
              <div class="name-6 plusjakartasans-bold-black-18px">
                {{ pearlReportProps.reportName }}
              </div>
              <div class="view-more-5 plusjakartasans-bold-cosmic-cobalt-14px">
                <span class="plusjakartasans-bold-cosmic-cobalt-14px">
                  <router-link :to="`/m`+pearlReportProps.reportUrl" style="visited: {text-align:center; color:#2a2f88;}">
                    {{ viewMore }}{{  viewMore2 }}
                  </router-link>
                </span>
              </div>
            </div>
            <div class="flex-row-25">
              <img class="rectangle-8-2" :src="pearlReportProps.rectangle" alt="Rectangle 89" />
              <p class="name-7 heebo-normal-granite-gray-12px" v-html="pearlReportProps.text"></p>
            </div>
          </div>
        </div>
        <div class="flex-row-26">
          <div class="other-gemstone-report-2 plusjakartasans-bold-black-18px">
            {{ otherGemstoneReportProps.reportName }}
          </div>
          <div class="view-more-6 plusjakartasans-bold-cosmic-cobalt-14px">
            <span class="plusjakartasans-bold-cosmic-cobalt-14px">
              <router-link :to="`/m`+otherGemstoneReportProps.reportUrl" style="visited: {text-align:center; color:#2a2f88;}">
                {{viewMore}}{{ viewMore2 }}
              </router-link>
            </span>
          </div>
          <div class="premium-report-1 plusjakartasans-bold-black-18px">
            {{ premiumReportProps.reportName }}
          </div>
          <div class="view-more-6 plusjakartasans-bold-cosmic-cobalt-14px">
            <span class="plusjakartasans-bold-cosmic-cobalt-14px">
              <router-link :to="`/m`+premiumReportProps.reportUrl" style="visited: {text-align:center; color:#2a2f88;}">
                {{ viewMore }}{{ viewMore2 }}
              </router-link>
            </span>
          </div>
        </div>
      </div>
      <div class="flex-row-27">
        <div class="overlap-group2" :style="{ 'background-image': 'url(' + otherGemstoneReportProps.rectangle + ')' }">
        </div>
        <p class="in-addition-to-the-i-1 heebo-normal-granite-gray-12px" v-html="otherGemstoneReportProps.text">

        </p>
        <!-- <el-carousel class="rectangle" style="margin-left:54px">
          <el-carousel-item v-for="item in premiumReportProps.images" :key="item.url" class="rectangle">
            <img class="rectangle" :src="item.url" :alt="item.url" />
          </el-carousel-item>
        </el-carousel> -->
        <img class="rectangle" :src="premiumReportProps.rectangle" alt="Rectangle 92" />
        <p class="encased-in-a-lush-tr-1 heebo-normal-granite-gray-12px">
          {{ premiumReportProps.text }}
        </p>
      </div>
      <div class="flex-col-25">
        
        <div class="terms">
          <h1 class="terms-title plusjakartasans-bold-mine-shaft-32px">{{termsTitle}}</h1>
          <p class="terms-content heebo-normal-mine-shaft-16px" v-html="termsContent"></p>
          <p class="heebo-normal-mine-shaft-16px" style="margin-top: 20px;text-align: center;">
            <router-link :to="`/m/terms-and-conditions`" style="visited: {text-align:center; color:#2a2f88;}">
              View More >>
            </router-link>
          </p>
        </div>

        <div class="flex-row-28">
          <div class="flex-col-26">
            <img class="rectangle-9-2" :src="additional1Props.rectangle" alt="Rectangle 93" />
            <p class="for-a-small-addition-2 heebo-normal-granite-gray-12px">
              {{ additional1Props.addition }}
            </p>
          </div>
          <div class="flex-col-27">
            <div class="additional-service-2 plusjakartasans-bold-mine-shaft-32px">
              {{ additionalService }}
            </div>
            <img class="rectangle-94-1" :src="additional2Props.rectangle" alt="Rectangle 94" />
            <p class="the-algt-sealing-sys-1 heebo-normal-granite-gray-12px">
              {{ additional2Props.addition }}
            </p>
          </div>
          <div class="flex-col-28">
            <img class="rectangle-9-2" :src="additional3Props.rectangle" alt="Rectangle 95" />
            <p class="algt-offers-a-3-hour-1 heebo-normal-granite-gray-12px">
              {{ additional3Props.addition }}
            </p>
          </div>
        </div>
        <foot1024 v-bind="globalData" />
      </div>
    </div>
  </div>
</template>

<script>
import LeftNav from "./components/Navbar";
import Foot1024 from "./components/Foot1024";
import Info from "./components/Info";
import 'element-ui/lib/theme-chalk/index.css';
export default {
  name: "Service1024",
  components: {
    LeftNav,
    Foot1024,
    Info,
  },
  props: [
    "globalData",
    "overlapGroup",
    "viewMore",
    "viewMore2",
    
    "service",
    "diamondReportProps",
    "gemstoneReportProps",
    "pearlReportProps",
    "otherGemstoneReportProps",
    "premiumReportProps",

    "additionalService",
    "additional1Props",
    "additional2Props",
    "additional3Props",
    "termsTitle",
    "termsContent",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../variables'

.terms
  background-color: #fff
  width: 1024px

.terms-title
  align-self: flex-start
  letter-spacing: 0
  line-height: normal
  // margin-left: 60px
  margin-top: 20px
  margin-bottom: 20px
  min-height: 40px
  text-align: center

.terms-content
  margin-left: 100px
  margin-right: 100px

.el-carousel::-webkit-scrollbar
  display: none
.service-1024
  align-items: center
  background-color: $selago
  display: flex
  flex-direction: column
  height: 2342px
  width: 1024px

.flex-col-19
  align-items: flex-start
  display: flex
  flex-direction: column
  gap: 40px
  min-height: 1291px
  width: 1024px

.flex-col-20
  align-items: center
  display: flex
  flex-direction: column
  gap: 40px
  min-height: 933px
  width: 1024px

.flex-col-21
  align-items: center
  display: flex
  flex-direction: column
  min-height: 684px
  width: 1024px

.overlap-group4-1
  background-position: 50% 50%
  background-size: cover
  height: 300px
  position: relative
  width: 1024px

.rectangle-11-2
  background-color: $black-2
  height: 300px
  left: 0
  // position: absolute
  top: 0
  width: 1024px

.logo-01-4
  height: 43px
  left: 437px
  position: absolute
  top: 140px
  width: 150px

.group-29
  align-items: flex-end
  display: flex
  height: 20px
  left: 651px
  min-width: 337px
  position: absolute
  top: 29px

.vector-stroke
  height: 20px
  width: 20px

.phone-1
  letter-spacing: 0
  line-height: normal
  margin-left: 10px
  min-height: 19px
  min-width: 108px
  white-space: nowrap

.vector-9
  align-self: center
  height: 16px
  margin-left: 30px
  width: 20px

.infoalgtlabscom-3
  cursor: pointer
  letter-spacing: 0
  line-height: normal
  margin-left: 10px
  min-height: 19px
  min-width: 135px
  white-space: nowrap

.title
  letter-spacing: 0
  line-height: normal
  margin-top: 39px
  min-height: 40px
  min-width: 118px

.flex-row-19
  align-items: center
  align-self: flex-start
  display: flex
  gap: 10px
  height: 23px
  margin-left: 30px
  margin-top: 40px
  min-width: 279px

.name-5
  letter-spacing: 0
  line-height: normal
  min-height: 23px
  min-width: 168px

.view-more-5
  letter-spacing: 0
  line-height: normal
  margin-top: 1.0px
  min-height: 18px
  min-width: 101px
  cursor: pointer

.flex-row-20
  align-items: flex-start
  display: flex
  height: 212px
  margin-top: 30px
  min-width: 964px

.rectangle-8-2
  height: 135px
  margin-top: 1px
  object-fit: cover
  width: 165px

.the-algt-natural-dia
  letter-spacing: 0
  line-height: 14.4px
  margin-left: 25px
  min-height: 212px
  width: 270px

.rectangle-85
  height: 135px
  margin-left: 44px
  margin-top: 1px
  object-fit: cover
  width: 165px

.laboratory-grown-dia
  align-self: flex-end
  letter-spacing: 0
  line-height: 14.4px
  margin-left: 25px
  min-height: 210px
  width: 270px

.flex-row-21
  align-items: flex-start
  display: flex
  height: 209px
  min-width: 964px

.full-quality-assessm-1
  letter-spacing: 0
  line-height: 14.4px
  margin-left: 25px
  min-height: 209px
  width: 260px

.rectangle
  height: 135px
  margin-left: 54px
  margin-top: 1px
  object-fit: cover
  width: 165px

.we-also-specialise-i-1
  letter-spacing: 0
  line-height: 14.4px
  margin-left: 25px
  min-height: 198px
  width: 270px

.flex-row-22
  align-items: flex-start
  align-self: center
  display: flex
  gap: 54px
  height: 255px
  margin-right: 10.0px
  min-width: 954px

.flex-row-23
  align-items: flex-start
  display: flex
  gap: 10px
  height: 225px
  min-width: 450px

.flex-col-22
  align-items: flex-start
  display: flex
  flex-direction: column
  gap: 21px
  min-height: 181px
  width: 177px

.gemstone-report-2
  letter-spacing: 0
  line-height: normal
  min-height: 23px

.overlap-group3-1
  height: 137px
  position: relative
  width: 165px

.rectangle-88
  height: 135px
  left: 0
  object-fit: cover
  position: absolute
  top: 0
  width: 165px

.rectangle-96
  background-color: $white
  border-radius: 5px
  height: 21px
  left: 124px
  position: absolute
  top: 116px
  width: 37px

.text-12
  left: 133px
  letter-spacing: 0
  line-height: 14.4px
  position: absolute
  top: 119px
  white-space: nowrap

.flex-col-23
  align-items: flex-start
  align-self: flex-end
  display: flex
  flex-direction: column
  gap: 22px
  min-height: 222px
  width: 263px

.view-more-7
  letter-spacing: 0
  line-height: normal
  min-height: 18px
  cursor: pointer

.algt-offers-full-rep-1
  letter-spacing: 0
  line-height: 14.4px
  margin-left: 3px
  min-height: 182px
  width: 260px

.flex-col-24
  align-items: flex-start
  display: flex
  flex-direction: column
  gap: 20px
  min-height: 255px
  width: 450px

.flex-row-24
  align-items: center
  display: flex
  gap: 10px
  height: 23px
  min-width: 243px

.name-6
  letter-spacing: 0
  line-height: normal
  min-height: 23px
  min-width: 132px

.flex-row-25
  align-items: flex-start
  display: flex
  gap: 25px
  height: 212px
  min-width: 450px

.name-7
  letter-spacing: 0
  line-height: 14.4px
  min-height: 212px
  width: 260px

.flex-row-26
  align-items: flex-end
  display: flex
  height: 23px
  margin-left: 30px
  min-width: 774px

.other-gemstone-report-2
  letter-spacing: 0
  line-height: normal
  min-height: 23px
  min-width: 244px

.view-more-6
  letter-spacing: 0
  line-height: normal
  margin-bottom: 1px
  margin-left: 10px
  min-height: 18px
  min-width: 101px
  cursor: pointer

.premium-report-1
  letter-spacing: 0
  line-height: normal
  margin-left: 149px
  min-height: 23px
  min-width: 159px

.flex-row-27
  align-items: flex-start
  display: flex
  height: 167px
  margin-right: 10.0px
  margin-top: 20px
  min-width: 954px

.overlap-group2
  background-position: 50% 50%
  background-size: cover
  height: 135px
  margin-top: 1px
  position: relative
  width: 165px

.rectangle-98
  background-color: $white
  border-radius: 5px
  height: 21px
  left: 124px
  position: absolute
  top: 110px
  width: 37px

.text-11
  left: 133px
  letter-spacing: 0
  line-height: 14.4px
  position: absolute
  top: 113px
  white-space: nowrap

.in-addition-to-the-i-1
  letter-spacing: 0
  line-height: 14.4px
  margin-left: 25px
  min-height: 167px
  width: 260px

.encased-in-a-lush-tr-1
  letter-spacing: 0
  line-height: 14.4px
  margin-left: 25px
  min-height: 125px
  width: 260px

.flex-col-25
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 41px
  min-height: 823px
  position: relative
  width: 1024px

.flex-row-28
  align-items: flex-start
  background-color: $white
  display: flex
  gap: 32px
  height: 447px
  min-width: 1024px
  padding: 39px 30px

.flex-col-26
  align-items: flex-start
  align-self: flex-end
  display: flex
  flex-direction: column
  gap: 20px
  margin-bottom: 2px
  min-height: 285px
  width: 300px

.rectangle-9-2
  height: 198px
  object-fit: cover
  width: 300px

.for-a-small-addition-2
  letter-spacing: 0
  line-height: 14.4px
  min-height: 67px
  width: 300px

.flex-col-27
  align-items: center
  display: flex
  flex-direction: column
  min-height: 367px
  width: 300px

.additional-service-2
  letter-spacing: 0
  line-height: normal
  min-height: 40px
  min-width: 290px

.rectangle-94-1
  height: 198px
  margin-top: 42px
  object-fit: cover
  width: 300px

.the-algt-sealing-sys-1
  letter-spacing: 0
  line-height: 14.4px
  margin-top: 20px
  min-height: 67px
  width: 300px

.flex-col-28
  align-items: flex-start
  align-self: center
  display: flex
  flex-direction: column
  gap: 20px
  margin-top: 27.0px
  min-height: 232px
  width: 300px

.algt-offers-a-3-hour-1
  letter-spacing: 0
  line-height: 14.4px
  white-space: nowrap
</style>
