<template>
  <div class="container-center-horizontal">
    <div class="contuct-us-1024 screen">
      <div class="flex-col-7">
        <div class="overlap-group3-2">
          <img class="rectangle-21-1" :src="overlapGroup" alt="Rectangle 21" />
          <div class="rectangle-11-1"></div>
          <left-nav />
          <info v-bind="globalData" />
        </div>
        <h1 class="place-4 plusjakartasans-bold-mine-shaft-32px">{{ contact }}</h1>
        <div class="overlap-group2-2">
          <img class="rectangle-127-1" :src="doorPic1" alt="Rectangle 127" />
          <div class="flex-col-8">
            <div class="x-address heebo-bold-mine-shaft-24px">{{ addressName1 }}</div>
            <p class="belgium-antwerp-dia-2 heebo-normal-mine-shaft-16px">{{ address1 }}</p>
            <div class="telephone heebo-normal-mine-shaft-16px">{{ telephone1 }}</div>
          </div>
        </div>
      </div>
      <div class="flex-row-4">
        <div class="flex-col-9">
          <div class="x-address heebo-bold-mine-shaft-24px">{{ addressName2 }}</div>
          <p class="china-shanghai-yuyu-2 heebo-normal-mine-shaft-16px">{{ address2 }}</p>
          <div class="telephone heebo-normal-mine-shaft-16px">{{ telephone2 }}</div>
        </div>
        <img class="rectangle-128-2" :src="doorPic2" alt="Rectangle 128" />
      </div>

      <div class="overlap-group-container">
        <div class="overlap-group1-2">
          <div class="follow-me-2 heebo-bold-mine-shaft-24px">{{ globalData.follow }}</div>
          <div class="flex-row-5 heebo-normal-mine-shaft-16px">
            <img class="union-5" :src="globalData.emailIcon" alt="Union" />
            <div class="mail-2">{{ globalData.emailName }}</div>
            <div class="infoalgtlabscom-4">{{ globalData.email }}</div>
            <img class="vector-11" src="img/vector-32.svg" alt="Vector" />
            <div class="facebook-2">
              <a :href="globalData.facebookUrl" target="_blank" style="visited: {text-align:center; color:#000;}">{{ globalData.facebookName }}</a>  
            </div>
          </div>
          <div class="flex-row-6 heebo-normal-mine-shaft-16px">
            <img class="vector-12" src="img/vector-24.svg" alt="Vector" />
            <div class="business-hours-2">{{ globalData.timeName }}</div>
            <p class="monday-to-friday-930am-to-1730pm-2">{{ globalData.time }}</p>
            <img class="vector-13" src="img/vector-33.svg" alt="Vector" />
            <div class="instagram-2">
              <a :href="globalData.instagramUrl" target="_blank" style="visited: {text-align:center; color:#000;}">{{ globalData.instagramName }}</a>  
            </div>
          </div>
          <div class="flex-row-7 heebo-normal-mine-shaft-16px">
            <img class="vector-14" src="img/vector-25.svg" alt="Vector" />
            <p class="fee-monitoring-fee-pdf-file-download-1">
              <a :href="globalData.algtReportPriceListPdf" target="_blank" style="visited: {text-align:center; color:#333;}">
                {{ feePdf }}
              </a>
            </p>
            <img class="vector-15" src="img/vector-34.svg" alt="Vector" />
            <div class="wechat-2">
              <a href="javascript:void();" @click="centerDialogVisible = true" style="visited: {text-align:center; color:#000;}">{{ globalData.wechatName }}</a>
            </div>
          </div>
          <div class="flex-row-8">
            <img class="union-6" src="img/union-15.svg" alt="Union" />
            <div class="text-3 heebo-normal-mine-shaft-16px">
              <a :href="globalData.redBookUrl" target="_blank" style="visited: {text-align:center; color:#000;}">{{ globalData.redBookName }}</a>  
            </div>
          </div>
        </div>
        <div  style="margin-top: -40px;">
          <footBottom v-bind="globalData"/>
          <el-dialog title="公众号二维码" :visible.sync="centerDialogVisible" width="50%" center>
            <div style="text-align: center;"><img src="img/wx-qrcode.bmp"></div>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'element-ui/lib/theme-chalk/index.css';

import LeftNav from "./components/Navbar";
import FootBottom from "./components/FootBottom";
import Info from "./components/Info";

export default {
  name: "Contact1024",
  data() {
    return {
      centerDialogVisible: false
    };
  },
  components: {
    LeftNav,
    FootBottom,
    Info,
  },
  props: [
    "globalData",
    "overlapGroup",
    "contact",
    "addressName1",
    "address1",
    "telephone1",
    "doorPic1",
    "addressName2",
    "address2",
    "telephone2",
    "doorPic2",
    "feePdf",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../variables'

.contuct-us-1024
  align-items: flex-end
  background-color: $selago
  display: flex
  flex-direction: column
  height: 1398px
  overflow: hidden
  width: 1024px

.flex-col-7
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-right: -936px
  min-height: 713px
  width: 1960px

.overlap-group3-2
  height: 300px
  position: relative
  width: 1960px

.rectangle-21-1
  height: 300px
  left: 0
  object-fit: cover
  position: absolute
  top: 0
  width: 1024px

.rectangle-11-1
  background-color: $black-2
  height: 300px
  left: 0
  position: absolute
  top: 0
  width: 1024px

.logo-01-3
  height: 43px
  left: 437px
  position: absolute
  top: 157px
  width: 150px

.group-29
  align-items: flex-end
  display: flex
  height: 20px
  left: 651px
  min-width: 337px
  position: absolute
  top: 29px

.vector-stroke
  height: 20px
  width: 20px

.phone-1
  letter-spacing: 0
  line-height: normal
  margin-left: 10px
  min-height: 19px
  min-width: 108px
  white-space: nowrap

.vector-10
  align-self: center
  height: 16px
  margin-left: 30px
  width: 20px

.infoalgtlabscom-3
  cursor: pointer
  letter-spacing: 0
  line-height: normal
  margin-left: 10px
  min-height: 19px
  min-width: 135px
  white-space: nowrap

.place-4
  letter-spacing: 0
  line-height: normal
  margin-left: 433px
  margin-top: 39px
  min-height: 40px

.overlap-group2-2
  align-items: center
  background-color: $white
  display: flex
  gap: 34px
  margin-top: 41px
  min-width: 1024px
  padding: 0 20px

.rectangle-127-1
  height: 293px
  object-fit: cover
  width: 386px

.flex-col-8
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-bottom: 2.0px
  min-height: 113px
  width: 472px

.x-address
  letter-spacing: 0
  line-height: normal
  min-height: 35px

.belgium-antwerp-dia-2,
.china-shanghai-yuyu-2
  letter-spacing: 0
  line-height: normal
  margin-top: 20px
  min-height: 24px

.telephone
  letter-spacing: 0
  line-height: normal
  margin-top: 10px
  min-height: 24px

.flex-row-4
  align-items: center
  display: flex
  gap: 68px
  margin-right: 20px
  min-width: 909px

.flex-col-9
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-bottom: 1.0px
  min-height: 113px
  width: 354px

.rectangle-128-2
  height: 296px
  width: 487px

.overlap-group-container
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-right: -12px
  min-height: 389px
  width: 1036px

.overlap-group1-2
  align-items: flex-start
  background-color: $white
  display: flex
  flex-direction: column
  min-height: 289px
  padding: 39px 50px
  width: 1024px

.follow-me-2
  align-self: center
  letter-spacing: 0
  line-height: normal
  margin-left: 347.0px
  min-height: 35px
  min-width: 111px

.flex-row-5
  align-items: flex-start
  display: flex
  height: 31px
  margin-top: 14px
  min-width: 700px

.union-5
  align-self: center
  height: 16px
  margin-bottom: 4.5px
  width: 24px

.mail-2
  letter-spacing: 0
  line-height: normal
  margin-left: 15px
  min-height: 24px
  min-width: 35px

.infoalgtlabscom-4
  letter-spacing: 0
  line-height: normal
  margin-left: 10px
  min-height: 24px
  min-width: 135px

.vector-11
  align-self: flex-end
  height: 24px
  margin-left: 363px
  width: 26px

.facebook-2
  align-self: flex-end
  letter-spacing: 0
  line-height: normal
  margin-bottom: 1px
  margin-left: 22px
  min-height: 24px
  min-width: 70px

.flex-row-6
  align-items: flex-start
  display: flex
  height: 28px
  margin-top: 13px
  min-width: 703px

.vector-12
  height: 24px
  margin-top: 1px
  width: 24px

.business-hours-2
  letter-spacing: 0
  line-height: normal
  margin-left: 15px
  min-height: 24px
  min-width: 114px

.monday-to-friday-930am-to-1730pm-2
  letter-spacing: 0
  line-height: normal
  margin-left: 10px
  min-height: 24px
  min-width: 275px

.vector-13
  align-self: flex-end
  height: 22px
  margin-left: 145px
  width: 24px

.instagram-2
  align-self: flex-end
  letter-spacing: 0
  line-height: normal
  margin-bottom: 0.5px
  margin-left: 23px
  min-height: 24px
  min-width: 73px

.flex-row-7
  align-items: flex-start
  display: flex
  height: 28px
  margin-left: 3px
  margin-top: 16px
  min-width: 765px

.vector-14
  height: 24px
  margin-top: 1px
  width: 18px

.fee-monitoring-fee-pdf-file-download-1
  letter-spacing: 0
  line-height: normal
  margin-left: 18px
  min-height: 24px
  min-width: 261px

.vector-15
  align-self: flex-end
  height: 19px
  margin-bottom: 1.25px
  margin-left: 283px
  width: 24px

.wechat-2
  align-self: flex-end
  letter-spacing: 0
  line-height: normal
  margin-left: 23px
  min-height: 24px
  min-width: 138px

.flex-row-8
  align-items: center
  align-self: flex-end
  display: flex
  gap: 20px
  height: 24px
  margin-right: 182px
  margin-top: 20px
  min-width: 162px

.union-6
  height: 10px
  margin-top: 1.98px
  width: 30px

.text-3
  letter-spacing: 0
  line-height: normal
  min-height: 24px
  min-width: 112px

.overlap-group-2
  align-items: center
  background-color: $selago
  display: flex
  height: 100px
  justify-content: flex-end
  min-width: 1024px
  padding: 28px 30px

.logo-02-1
  align-self: flex-end
  height: 42px
  width: 34px

.about-algt-2
  letter-spacing: 0
  line-height: normal
  margin-left: 21px
  margin-top: 3.0px
  min-height: 15px
  min-width: 76px

.our-reports-1
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  margin-top: 3.0px
  min-height: 15px
  min-width: 85px

.service-2
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  margin-top: 3.0px
  min-height: 15px
  min-width: 51px

.video-1
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  margin-top: 3.0px
  min-height: 15px
  min-width: 39px

.place-5
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  margin-top: 3.0px
  min-height: 15px
  min-width: 59px

.x2023-algt-all-rights-reserved-2
  letter-spacing: 0
  line-height: normal
  margin-left: 295px
  margin-top: 1.0px
  min-height: 15px
  min-width: 214px
</style>
