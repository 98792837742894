<template>
  <div class="group-46">
    <div class="overlap-group3">
      <img
        class="vector-2"
        src="img/vector-9.svg"
        alt="Vector"
      />
      <div class="fee-pdf-1 heebo-bold-white-24px">
        <a :href="algtReportPriceListPdf" target="_blank" style="visited: {text-align:center; color:#fff;}">
          Download Our Testing Price List
      </a>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Group46",
  props: ["algtReportPriceListPdf"],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'

.group-46,
.group-46-1
  align-items: flex-start
  display: flex
  margin-left: 1.0px
  margin-top: 60px
  min-width: 365px

.overlap-group3,
.overlap-group2-1
  align-items: center
  background-color: $brand
  border-radius: 4px
  display: flex
  gap: 12px
  height: 64px
  min-width: 363px
  padding: 12px 30px

.vector-2,
.vector-3
  height: 24px
  margin-bottom: 4.0px
  width: 18px

.fee-pdf-1,
.fee-pdf-2
  align-self: flex-end
  letter-spacing: 0
  line-height: 38.4px
  min-width: 273px
  white-space: nowrap
</style>
