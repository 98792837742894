<template>
  <div class="container-center-horizontal">
    <div class="about-us-1024 screen">
      <div class="overlap-group3-1">
        <img class="banner-1024" :src="overlapGroup" alt="banner-1024" />
        <div class="rectangle-11-1"></div>
        <left-nav/>
        <info v-bind="globalData" />
      </div>
      <h1 class="title-1">{{ title }}</h1>
      <div class="overlap-group2-2">
        <div class="flex-row-10">
          <p class="the-gemstone-market-2 heebo-normal-mine-shaft-14px">{{ theGemstoneMarket }}</p>
          <div class="image-container-1">
            <img
              class="image-9-stroke-1"
              src="img/image-9--stroke--1.svg"
              alt="image 9 (Stroke)"
            />
            <img class="image-9-1" :src="image9" alt="image 9" />
          </div>
        </div>
        <div class="overlap-group4">
          <img class="dsc4285-1-2" :src="dsc428512" alt="DSC4285 1" />
          <div class="flex-col-9 heebo-normal-white-14px">
            <p class="flex-col-item-1">{{ antwerpLaboratoryF }}</p>
            <p class="flex-col-item-1">{{ algtIsEquippedWit }}</p>
          </div>
        </div>
        <div class="flex-row-11">
          <p class="our-team-of-gemmolog-2 heebo-normal-mine-shaft-14px">{{ ourTeamOfGemmolog }}</p>
          <img class="raman-pl-1-1" :src="ramanPl2" alt="RAMAN PL 1" />
        </div>
        <img class="rectangle-73-2" :src="rectangle73" alt="Rectangle 73" />
        <div class="flex-row-12">
          <img class="rectangle-74-2" :src="rectangle74" alt="Rectangle 74" />
          <p class="our-goal-is-to-accom-3 heebo-normal-mine-shaft-14px">{{ ourGoalIsToAccom }}</p>
        </div>
      </div>
      <foot1024 v-bind="globalData" />
    </div>
  </div>
</template>

<script>

import LeftNav from "./components/Navbar";
import Foot1024 from "./components/Foot1024";
import Info from "./components/Info";

export default {
  name: "About1024",
  components: {
    LeftNav,
    Foot1024,
    Info,
  },
  props: [
    "globalData",
    "overlapGroup",
    "title",
    "theGemstoneMarket",
    "image9",
    "dsc42851",
    "dsc428512",
    "antwerpLaboratoryF",
    "algtIsEquippedWit",
    "ourTeamOfGemmolog",
    "ramanPl2",
    "rectangle73",
    "rectangle74",
    "ourGoalIsToAccom",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../variables'

.about-us-1024
  align-items: center
  background-color: $selago
  display: flex
  flex-direction: column
  height: 2491px
  overflow: hidden
  position: relative
  width: 1024px

.overlap-group3-1
  align-self: flex-start
  height: 300px
  position: relative
  width: 1960px

.banner-1024
  height: 300px
  left: 0
  object-fit: cover
  position: absolute
  top: 0
  width: 1024px

.rectangle-11-1
  background-color: $black-2
  height: 300px
  left: 0
  position: absolute
  top: 0
  width: 1024px

.logo-01-4
  height: 43px
  left: 437px
  position: absolute
  top: 157px
  width: 150px

.group-29
  align-items: center
  display: flex
  height: 20px
  left: 682px
  min-width: 306px
  position: absolute
  top: 28px

.vector-stroke
  height: 20px
  width: 20px

.phone-1
  letter-spacing: 0
  line-height: normal
  margin-bottom: 2.0px
  margin-left: 10px
  min-height: 16px
  min-width: 94px
  white-space: nowrap

.vector-10
  height: 16px
  margin-left: 30px
  width: 20px

.infoalgtlabscom-3
  cursor: pointer
  letter-spacing: 0
  line-height: normal
  margin-bottom: 2.0px
  margin-left: 10px
  min-height: 16px
  min-width: 118px
  white-space: nowrap

.title-1
  color: $mine-shaft
  font-family: $font-family-plus_jakarta_sans
  font-size: $font-size-xxxl
  font-weight: 700
  letter-spacing: 0
  line-height: normal
  margin-right: 1.0px
  margin-top: 39px
  min-height: 40px
  min-width: 163px

.overlap-group2-2
  align-items: center
  background-color: $white
  display: flex
  flex-direction: column
  gap: 30px
  margin-top: 41px
  min-height: 1655px
  width: 924px

.flex-row-10
  align-items: center
  display: flex
  gap: 161px
  margin-right: 4.0px
  margin-top: 30px
  min-width: 820px

.the-gemstone-market-2
  letter-spacing: 0
  line-height: 21px
  margin-bottom: 1.0px
  min-height: 231px
  width: 391px

.image-container-1
  height: 320px
  position: relative
  width: 268px

.image-9-stroke-1
  height: 306px
  left: 14px
  position: absolute
  top: 14px
  width: 254px

.image-9-1
  height: 306px
  left: 0
  object-fit: cover
  position: absolute
  top: 0
  width: 254px

.overlap-group4
  align-items: center
  background-color: $brand
  display: flex
  gap: 68px
  min-width: 924px

.dsc4285-1-2
  height: 370px
  width: 354px

.flex-col-9
  align-items: flex-start
  display: flex
  flex-direction: column
  gap: 49px
  margin-bottom: 3.0px
  min-height: 303px
  width: 392px

.flex-col-item-1
  letter-spacing: 0
  line-height: 21px
  min-height: 127px
  width: 392px

.flex-row-11
  align-items: center
  display: flex
  gap: 60px
  min-width: 824px

.our-team-of-gemmolog-2
  letter-spacing: 0
  line-height: 21px
  margin-bottom: 1.0px
  min-height: 170px
  width: 392px

.raman-pl-1-1
  height: 267px
  width: 372px
  object-fit: cover

.rectangle-73-2
  height: 299px
  object-fit: cover
  width: 924px

.flex-row-12
  align-items: center
  display: flex
  gap: 61px
  margin-right: 3.0px
  min-width: 821px

.rectangle-74-2
  height: 249px
  object-fit: cover
  width: 255px

.our-goal-is-to-accom-3
  letter-spacing: 0
  line-height: 21px
  margin-bottom: 2.0px
  min-height: 63px
  width: 505px
</style>
