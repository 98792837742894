<template>
  <div>
    <div class="phone roboto-normal-white-16px">{{ phone }}</div>
    <a href="mailto:info@algtlabs.com" target="_blank">
      <div class="infoalgtlabscom roboto-normal-white-16px">
        {{ email }}
      </div>
    </a>
    <img class="logo-01" src="img/logo-01.svg" alt="logo-01" />
    <img class="vector" src="img/vector--stroke-.svg" alt="Vector" />
    <img class="vector-1" src="img/vector-1.svg" alt="Vector" />
  </div>
</template>

<script>
export default {
  name: "Info",
  props: [
    "phone",
    "email",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'


.phone
  left: 1537px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.infoalgtlabscom
  cursor: pointer
  left: 1705px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.logo-01
  height: 87px
  left: 810px
  position: absolute
  // top: 231px
  top: 147px
  width: 300px

.vector
  height: 20px
  left: 1507px
  position: absolute
  top: 49px
  width: 20px

.vector-1
  height: 16px
  left: 1675px
  position: absolute
  top: 51px
  width: 20px
</style>
