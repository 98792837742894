<template>
  <article :class="[`tag-1024-3 plusjakartasans-bold-white-14px`, className || ``]">
    <div class="x029-91 valign-text-middle">{{ x029 }}</div>
    <div class="fee-65"><div class="x28-117 valign-text-middle">Fee</div></div>
  </article>
</template>

<script>
export default {
  name: "Tag1024",
  props: ["x029", "className"],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'

.tag-1024-3
  align-items: center
  background-color: $brand
  display: flex
  gap: 16px
  width: 430px

.x029-91
  height: 18px
  letter-spacing: 0
  line-height: normal
  margin-bottom: 2.0px
  margin-left: 16px
  width: 243px

.fee-65
  align-items: center
  background-color: $brand
  display: flex
  flex-wrap: wrap
  gap: 20px 20px
  height: 34px
  padding: 8px 0px 8px 8px
  position: relative
  width: 155px

.x28-117
  align-self: stretch
  letter-spacing: 0
  line-height: normal
  margin-top: -2.00px
  position: relative
  width: 139px

.tag-1024-3.tag-1024-1
  margin-left: 1px
  margin-top: 21px

.tag-1024-3.tag-1024-2
  margin-top: 21px
</style>
