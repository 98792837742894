<template>
  <div class="frame-1-1">
    <img class="rectangle-69-1" :src="rectangle" alt="Rectangle 69" />
    <div class="other-gemstone-report-1 plusjakartasans-bold-mine-shaft-16px">{{ reportName }}</div>
    <div class="view-more-4 plusjakartasans-bold-cosmic-cobalt-12px">
      <span class="plusjakartasans-bold-cosmic-cobalt-12px">
        <router-link :to="`/s`+reportUrl" style="visited: {text-align:center; color:#333;}">
          View more 
        </router-link>
       </span>
      <span class="plusjakartasans-normal-cosmic-cobalt-12px">&gt;&gt;</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Frame15",
  props: ["rectangle","reportUrl","reportName"],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'

.frame-1-1
  align-items: center
  background-color: $white
  display: flex
  flex-direction: column
  margin-top: 20px
  min-height: 294px
  padding: 10px 0
  width: 355px

.rectangle-69-1
  height: 199px
  object-fit: cover
  width: 335px

.other-gemstone-report-1
  letter-spacing: 0
  line-height: normal
  margin-top: 19px
  min-height: 20px
  min-width: 117px

.view-more-4
  letter-spacing: 0
  line-height: normal
  margin-top: 10px
  min-height: 15px
  min-width: 87px
  cursor: pointer
</style>
