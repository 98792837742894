<template>
 
 <div class="frame">
    <img class="rectangle-69" :src="headView" alt="Rectangle 69" />
    <div class="text plusjakartasans-bold-mine-shaft-24px">
      <router-link :to="{ path: `/l/newsDetail`, query:{ index: index }}" style="visited:{text-align:center;color:#333;}">
        {{ title }}
      </router-link>
    </div>
    <!-- <tag2 :text5="tag" className="tag-3" /> -->
    <p class="encased-in-a-lush-tr heebo-normal-granite-gray-16px" v-html="content">
    </p>
  </div>

</template>

<script>
import Tag2 from "../components/Tag2";
export default {
  name: "Frame1920",
  components: {
    Tag2,
  },
  props: [
    "title",
    "headView",
    "tag",
    "className",
    "content",
    "index",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'

.frame
  align-items: flex-start
  background-color: $white
  display: flex
  flex-direction: column
  min-height: 523px
  padding: 20px 0
  position: relative
  width: 506px
  
.rectangle-69
  align-self: center
  // height: 300px
  object-fit: cover
  position: relative
  max-height: 466px
  width: 466px

.rectangle-69-1
  align-self: center
  height: 300px
  position: relative
  width: 466px

.rectangle-69-2
  align-self: center
  height: 262px
  object-fit: cover
  position: relative
  width: 466px

.rectangle-69-3
  align-self: center
  height: 383px
  object-fit: cover
  position: relative
  width: 466px

.rectangle-69-4
  align-self: center
  height: 240px
  object-fit: cover
  position: relative
  width: 466px

.text
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  margin-top: 19px
  min-height: 30px
  min-width: 168px
  position: relative

.encased-in-a-lush-tr,
.the-certificate-iden,
.encased-in-a-lush-tr-1,
.encased-in-a-lush-tr-2,
.the-certificate-iden-1,
.encased-in-a-lush-tr-3
  -webkit-box-orient: vertical
  -webkit-line-clamp: 4
  align-self: center
  display: -webkit-box
  letter-spacing: 0
  line-height: 19.2px
  margin-top: 19px
  min-height: 80px
  overflow: hidden
  position: relative
  text-overflow: ellipsis
  width: 466px

</style>
