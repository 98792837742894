<template>
  <div :class="[`tag`, className || ``]">
    <div class="text_label plusjakartasans-normal-cosmic-cobalt-12px">{{text5}}</div>
  </div>
</template>

<script>
export default {
  name: "Tag",
  props: ["text5","className"],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'

.tag
  align-items: center
  align-self: flex-start
  border: 1px solid
  border-color: $brand
  border-radius: 19px
  display: inline-flex
  justify-content: center
  margin-left: 60px
  margin-top: 21px
  padding: 4px 8px
  position: relative

.text_label
  letter-spacing: 0
  line-height: normal
  margin-top: -1.00px
  position: relative
  width: fit-content

.tag.tag-1
  margin-left: 60px
</style>
