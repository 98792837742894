<template></template>
<script>
export default {
  name: "Index",
  created() {
    let width = window.screen.width;
    if (width < 1024) {
      this.$router.push("/s/home");
    } else if (width < 1920) {
      this.$router.push("/m/home");
    } else {
      this.$router.push("/l/home");
    }
  }
}
</script>

