<template>
  <div class="foot">
    <div class="overlap-group-2">
      <img class="logo-02-2" :src="footLogo2" alt="logo-02" />
      <div class="about-algt-2 plusjakartasans-bold-mine-shaft-12px">{{ aboutAlgt }}</div>
      <div class="our-reports-1 plusjakartasans-bold-mine-shaft-12px">{{ ourReports }}</div>
      <div class="service-1 plusjakartasans-bold-mine-shaft-12px">{{ service }}</div>
      <div class="video-2 plusjakartasans-bold-mine-shaft-12px">{{ video }}</div>
      <div class="place-3 plusjakartasans-bold-mine-shaft-12px">{{ place }}</div>
      <p class="x2023-algt-all-rights-reserved-2 plusjakartasans-normal-black-12px">
        {{ allRightsReserved }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FootBottom",
  props: [
    "aboutAlgt",
    "ourReports",
    "service",
    "video",
    "place",
    "allRightsReserved",
    "footLogo2",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'

.foot
  align-items: flex-start
  align-self: flex-start
  display: flex
  margin-top: 40px
  min-width: 1036px

.overlap-group-2
  align-items: center
  background-color: $selago
  display: flex
  height: 100px
  justify-content: flex-end
  min-width: 1024px
  padding: 28px 30px

.logo-02-2
  align-self: flex-end
  height: 42px
  width: 34px

.about-algt-2
  letter-spacing: 0
  line-height: normal
  margin-left: 21px
  margin-top: 3.0px
  min-height: 15px
  min-width: 76px

.our-reports-1
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  margin-top: 3.0px
  min-height: 15px
  min-width: 85px

.service-1
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  margin-top: 3.0px
  min-height: 15px
  min-width: 51px

.video-2
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  margin-top: 3.0px
  min-height: 15px
  min-width: 39px

.place-3
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  margin-top: 3.0px
  min-height: 15px
  min-width: 59px

.x2023-algt-all-rights-reserved-2
  letter-spacing: 0
  line-height: normal
  margin-left: 295px
  margin-top: 1.0px
  min-height: 15px
  min-width: 214px
</style>
