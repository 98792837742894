<template>
  <div class="container-center-horizontal">
    <div class="gemstone-report-375 screen">
      <div class="overlap-group-1" :style="{ 'background-image': 'url(' + overlapGroup1 + ')' }">
        <div class="rectangle-11-1"></div>
        <h1 class="gemstone-report-1">{{ title }}</h1>
        <img class="logo-01-1" src="img/logo-01-12.svg" alt="logo-01" />
        <img class="union" src="img/union-26.svg" alt="Union" @click="drawer = true" />
      </div>
      <p class="we-offer-both-a-verb-1 heebo-normal-mine-shaft-12px" v-html="weOfferBothAVerb"></p>
      <div class="group-19-2">
        <el-carousel class="group-19-item-2">
          <el-carousel-item v-for="item in images" :key="item.url" class="group-19-item-2">
            <img class="group-19-item-2" :src="item.url" :alt="item.url" />
          </el-carousel-item>
        </el-carousel>
        <!-- <img class="group-19-item-2" :src="x1" alt="1" /> -->
        <!-- <img class="group-19-item-2" :src="x3" alt="3" /> -->
      </div>
      <!-- <p class="gcs-gemstone-reports-1 heebo-bold-black-16px">{{ gcsGemstoneReports }}</p>
      <p class="report-number-each-1 heebo-bold-black-12px">
        <span class="heebo-bold-mine-shaft-12px">{{ spanText1 }}</span>
        <span class="heebo-normal-mine-shaft-12px">{{ spanText2 }}</span>
      </p>
      <p class="gemstone-report-375-item heebo-bold-black-12px">
        <span class="heebo-bold-mine-shaft-12px">{{ spanText3 }}</span>
        <span class="heebo-normal-mine-shaft-12px">{{
          spanText4 }}</span>
      </p>
      <p class="gemstone-report-375-item heebo-bold-black-12px">
        <span class="heebo-bold-mine-shaft-12px">{{ spanText5 }}</span>
        <span class="heebo-normal-mine-shaft-12px">{{ spanText6 }}</span>
      </p>
      <p class="x-des heebo-bold-black-12px">
        <span class="heebo-bold-mine-shaft-12px">{{ spanText7 }}</span>
        <span class="heebo-normal-mine-shaft-12px">{{ spanText8 }}</span>
      </p>
      <p class="color-photo-a-full heebo-bold-black-12px">
        <span class="heebo-bold-mine-shaft-12px">{{ spanText9 }}</span>
        <span class="heebo-normal-mine-shaft-12px">{{ spanText10 }}</span>
      </p>
      <p class="shape-cut-descri-1 heebo-bold-black-12px">
        <span class="heebo-bold-mine-shaft-12px">{{ spanText11 }}</span>
        <span class="heebo-normal-mine-shaft-12px">{{ spanText12 }}</span>
      </p>
      <p class="gemstone-report-375-item heebo-bold-black-12px">
        <span class="heebo-bold-mine-shaft-12px">{{ spanText13 }}</span>
        <span class="heebo-normal-mine-shaft-12px">{{ spanText14 }}</span>
      </p>
      <p class="x-des heebo-bold-black-12px">
        <span class="heebo-bold-mine-shaft-12px">{{ spanText15 }}</span>
        <span class="heebo-normal-mine-shaft-12px">{{ spanText16 }}</span>
      </p>
      <p class="origin-opinion-gcs-1 heebo-bold-mine-shaft-12px">
        <span class="heebo-bold-mine-shaft-12px">{{ spanText17 }}</span>
        <span class="heebo-normal-mine-shaft-12px">{{ spanText18 }}</span>
      </p> -->
      <foot-bottom v-bind="globalData" />
      <el-drawer direction="ltr" :visible.sync="drawer" :with-header="false" size="50%">
        <left-nav />
      </el-drawer>
    </div>
  </div>
</template>

<script>
import LeftNav from "../components/Navbar";
import FootBottom from "../components/FootBottom";
import 'element-ui/lib/theme-chalk/index.css';
export default {
  name: "GemstoneReport375",
  components: {
    LeftNav,
    FootBottom,
  },
  data() {
    return {
      drawer: false,
    }
  },
  props: [
    "globalData",
    "overlapGroup1",
    "title",
    "weOfferBothAVerb",
    "images",
    "x1",
    "x3",
    "gcsGemstoneReports",
    "spanText1",
    "spanText2",
    "spanText3",
    "spanText4",
    "spanText5",
    "spanText6",
    "spanText7",
    "spanText8",
    "spanText9",
    "spanText10",
    "spanText11",
    "spanText12",
    "spanText13",
    "spanText14",
    "spanText15",
    "spanText16",
    "spanText17",
    "spanText18",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'
.el-carousel::-webkit-scrollbar
  display: none

.gemstone-report-375
  align-items: center
  background-color: $selago
  display: flex
  flex-direction: column
  height: 1000px
  width: 375px

.overlap-group-1
  background-position: 50% 50%
  background-size: cover
  height: 200px
  position: relative
  width: 375px

.rectangle-11-1
  background-color: $black-2
  height: 200px
  left: 0
  position: absolute
  top: 0
  width: 375px

.gemstone-report-1
  color: $white
  font-family: $font-family-plus_jakarta_sans
  font-size: $font-size-xxl
  font-weight: 700
  left: 68px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 89px

.logo-01-1
  height: 26px
  left: 143px
  position: absolute
  top: 10px
  width: 90px

.union
  height: 17px
  left: 15px
  position: absolute
  top: 15px
  width: 19px

.we-offer-both-a-verb-1
  letter-spacing: 0
  line-height: 18px
  margin-top: 19px
  min-height: 143px
  width: 335px

.group-19-2
  align-items: flex-start
  display: flex
  margin-top: 21px
  min-width: 335px

.group-19-item-2
  height: 236px
  object-fit: cover
  width: 336px

.gcs-gemstone-reports-1
  letter-spacing: 0
  line-height: 19.2px
  margin-top: 19px
  min-height: 57px
  width: 335px

.report-number-each-1
  letter-spacing: 0
  line-height: 14.4px
  margin-top: 39px
  min-height: 42px
  width: 335px

.gemstone-report-375-item
  letter-spacing: 0
  line-height: 14.4px
  margin-top: 20px
  min-height: 28px
  width: 335px

.x-des
  letter-spacing: 0
  line-height: 14.4px
  margin-top: 20px
  min-height: 98px
  width: 335px

.color-photo-a-full
  letter-spacing: 0
  line-height: 14.4px
  margin-top: 20px
  min-height: 42px
  width: 335px

.shape-cut-descri-1
  letter-spacing: 0
  line-height: 14.4px
  margin-top: 20px
  min-height: 56px
  width: 335px

.origin-opinion-gcs-1
  letter-spacing: 0
  line-height: 14.4px
  margin-right: 1.0px
  margin-top: 20px
  min-height: 238px
  width: 334px

.group-16
  align-items: flex-start
  display: flex
  gap: 16px
  height: 185px
  margin-right: 41.0px
  margin-top: 61px
  min-width: 274px

.logo-02-1
  height: 42px
  width: 34px

.flex-col-2
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 0
  min-height: 185px
  width: 214px

.about-algt-1
  letter-spacing: 0
  line-height: normal
  margin-left: 15px
  min-height: 20px

.flex-col-item-1
  letter-spacing: 0
  line-height: normal
  margin-left: 15px
  margin-top: 20px
  min-height: 20px

.x2023-algt-all-rights-reserved-1
  letter-spacing: 0
  line-height: normal
  margin-top: 30px
  min-height: 15px

</style>
