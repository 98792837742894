<template>
  <div class="container-center-horizontal">
    <div class="reg-375 screen">
      <div class="overlap-group6-3">
        <img class="union-6" src="img/union-12.svg" alt="Union" @click="drawer = true" />
        <img class="logo-01-4" src="img/logo-01-2.svg" alt="logo-01" />
      </div>
      <h1 class="customer-registration-form plusjakartasans-bold-mine-shaft-24px" v-html="title"></h1>
      <p class="please-send-your-id-1">
        <span class="heebo-bold-black-12px" v-html="pleaseSendYourId"></span>
      </p>
      <div class="group-47">
        <div class="overlap-group-7">
          <div class="registration-form-download-1 heebo-bold-white-16px">
            <a :href="globalData.customerRegistrationFormPdf" target="_blank" style="visited: {text-align:center; color:#fff;}">
            {{ registrationFormDownload }}
          </a>
        </div>
        </div>
      </div>
      <div class="overlap-group9-2">
        <div class="company-name-1 heebo-normal-black-12px">{{ companyName }}</div>
      </div>
      <div class="overlap-group-5">
        <div class="contact-person heebo-normal-black-12px">{{ contactPerson }}</div>
      </div>
      <div class="overlap-group-5">
        <div class="telephone heebo-normal-black-12px">{{ telephone }}</div>
      </div>
      <div class="overlap-group-5">
        <div class="mobile-phone heebo-normal-black-12px">{{ mobilePhone }}</div>
      </div>
      <div class="overlap-group-6">
        <div class="email heebo-normal-black-12px">{{ email }}</div>
      </div>
      <div class="overlap-group-5">
        <div class="address heebo-normal-black-12px">{{ address }}</div>
      </div>
      <div class="overlap-group-5">
        <div class="city-1 heebo-normal-black-12px">{{ city }}</div>
      </div>
      <div class="overlap-group2-4">
        <div class="country heebo-normal-black-12px">{{ country }}</div>
      </div>
      <div class="overlap-group-6">
        <div class="vat-number heebo-normal-black-12px">{{ vatNumber }}</div>
      </div>
      <div class="overlap-group7-2">
        <div class="id-passport-copy heebo-normal-black-12px">{{ idPassportCopy }}</div>
      </div>
      <p class="i-have-read-and-agre-1 heebo-normal-black-14px">
        <span class="heebo-normal-black-14px">{{ spanText1 }}</span>
        <span class="heebo-bold-black-14px">{{ spanText2 }}</span>
        <span class="heebo-normal-black-14px">{{ spanText3 }}</span>
        <span class="heebo-bold-black-14px">{{ spanText4 }}</span>
        <span class="heebo-normal-black-14px">{{ spanText5 }}</span>
      </p>
      <div class="client-signature-1 heebo-normal-black-16px">{{ clientSignature }}</div>
      <foot v-bind="globalData" />
      <el-drawer direction="ltr" :visible.sync="drawer" :with-header="false" size="50%">
        <left-nav/>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import Foot from "./components/Foot375";
import LeftNav from "./components/Navbar";
export default {
  name: "Reg375",
  components: {
    LeftNav,
    Foot,
  },
  data() {
    return {
      drawer: false,
    }
  },
  props: [
    "globalData",
    "rectangle21",
    "phone",
    "infoAlgtlabsCom",
    "title",
    "pleaseSendYourId",
    "companyName",
    "contactPerson",
    "telephone",
    "mobilePhone",
    "email",
    "address",
    "city",
    "country",
    "vatNumber",
    "idPassportCopy",
    "spanText1",
    "spanText2",
    "spanText3",
    "spanText4",
    "spanText5",
    "clientSignature",
    "registrationFormDownload",
  ],
};
</script>

<style lang="sass">
@import '../../variables'

.reg-375
  align-items: center
  background-color: $selago
  display: flex
  flex-direction: column
  height: 1365px
  overflow: hidden
  position: relative
  width: 375px

.overlap-group6-3
  align-items: flex-start
  background-color: $white
  box-shadow: 0px 1px 16px #33333326
  display: flex
  gap: 109px
  height: 47px
  min-width: 375px
  padding: 10px 15px

.union-28
  align-self: center
  height: 17px
  width: 19px

.logo-01-10
  height: 26px
  width: 90px

.customer-registration-form
  align-self: flex-start
  letter-spacing: 0
  line-height: normal
  margin-left: 12px
  margin-top: 14px
  min-height: 60px

.please-send-your-id-1
  color: transparent
  font-family: $font-family-heebo
  font-size: $font-size-m
  font-weight: 700
  letter-spacing: 0
  line-height: 19.2px
  margin-top: 20px
  min-height: 76px
  width: 351px

.span1
  color: $brand

.group-47
  align-items: flex-start
  display: flex
  margin-left: 2.0px
  margin-top: 30px
  min-width: 353px

.overlap-group-7
  align-items: center
  background-color: $brand
  border-radius: 4px
  display: flex
  height: 48px
  min-width: 351px
  padding: 0 50px

.registration-form-download-1
  letter-spacing: 0
  line-height: 25.6px
  min-height: 26px
  min-width: 251px
  text-align: center
  white-space: nowrap

.overlap-group9-2
  align-items: flex-end
  background-color: $white
  border: 1px solid
  border-color: $alto
  border-radius: 4px
  display: flex
  height: 32px
  margin-top: 54px
  min-width: 351px
  padding: 8.0px 10px

.company-name-1
  letter-spacing: 0
  line-height: 12px
  min-width: 89px
  white-space: nowrap

.overlap-group-5
  align-items: flex-end
  background-color: $white
  border: 1px solid
  border-color: $alto
  border-radius: 4px
  display: flex
  height: 32px
  margin-top: 10px
  min-width: 351px
  padding: 8.0px 10px

.contact-person
  letter-spacing: 0
  line-height: 12px
  min-width: 86px
  white-space: nowrap

.telephone
  letter-spacing: 0
  line-height: 12px
  min-width: 59px
  white-space: nowrap

.mobile-phone
  letter-spacing: 0
  line-height: 12px
  min-width: 77px
  white-space: nowrap

.overlap-group-6
  align-items: flex-start
  background-color: $white
  border: 1px solid
  border-color: $alto
  border-radius: 4px
  display: flex
  height: 32px
  margin-top: 10px
  min-width: 351px
  padding: 8.0px 10px

.email,
.country,
.vat-number,
.id-passport-copy
  letter-spacing: 0
  line-height: 12px
  white-space: nowrap

.address
  letter-spacing: 0
  line-height: 12px
  min-width: 48px
  white-space: nowrap

.city-1
  letter-spacing: 0
  line-height: 12px
  min-width: 24px
  white-space: nowrap

.overlap-group2-4
  align-items: flex-start
  background-color: $white
  border: 1px solid
  border-color: $alto
  border-radius: 4px
  display: flex
  height: 32px
  margin-top: 10px
  min-width: 351px
  padding: 8.0px 6px

.overlap-group7-2
  align-items: flex-start
  background-color: $white
  border: 1px solid
  border-color: $alto
  border-radius: 4px
  display: flex
  height: 32px
  margin-top: 10px
  min-width: 351px
  padding: 8.0px 11px

.i-have-read-and-agre-1
  letter-spacing: 0
  line-height: 14px
  margin-top: 19px
  min-height: 28px
  width: 351px

.client-signature-1
  align-self: flex-start
  letter-spacing: 0
  line-height: 16px
  margin-left: 12px
  margin-top: 20px
  white-space: nowrap
</style>
