import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "../styleguide.sass";
import "../globals.sass";
import 'element-ui/lib/theme-chalk/index.css';

import ElementUI from 'element-ui';
import {FormItem} from 'element-ui';
import {Drawer} from 'element-ui';
import {Carousel} from 'element-ui';

import axios from 'axios';

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;

Vue.use(ElementUI);
Vue.use(FormItem);
Vue.use(Drawer);
Vue.use(Carousel);


new Vue({
  render: h => h(App),
  router
  }).$mount("#app");