<template>
  <div class="group-16">
    <img class="logo-02-1" :src="footLogo2" alt="logo-02" />
    <div class="flex-col-2">
      <div class="about-algt-1 plusjakartasans-bold-mine-shaft-16px">{{ aboutAlgt }}</div>
      <div class="flex-col-item plusjakartasans-bold-mine-shaft-16px">{{ newsLibrary }}</div>
      <div class="flex-col-item plusjakartasans-bold-mine-shaft-16px">{{ service }}</div>
      <div class="flex-col-item plusjakartasans-bold-mine-shaft-16px">{{ place }}</div>
      <p class="x2023-algt-all-rights-reserved-1 plusjakartasans-normal-black-12px">
        {{ allRightsReserved }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FootBottom",
  props: [
    "aboutAlgt",
    "newsLibrary",
    "ourReports",
    "service",
    "video",
    "place",
    "allRightsReserved",
    "footLogo2",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'

.group-16
  align-items: flex-start
  display: flex
  gap: 16px
  height: 185px
  margin-right: 41.0px
  margin-top: 40px
  min-width: 274px

.logo-02-1
  height: 42px
  width: 34px

.flex-col-2
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 0
  min-height: 185px
  width: 214px

.about-algt-1
  letter-spacing: 0
  line-height: normal
  margin-left: 15px
  min-height: 20px

.flex-col-item
  letter-spacing: 0
  line-height: normal
  margin-left: 15px
  margin-top: 20px
  min-height: 20px

.x2023-algt-all-rights-reserved-1
  letter-spacing: 0
  line-height: normal
  margin-top: 30px
  min-height: 15px
</style>
