<template>
  <div :class="[`tag-15`, className || ``]">
    <p class="x029-1 valign-text-middle plusjakartasans-bold-granite-gray-18px">{{ x029 }}</p>
    <fee :number="feeProps.number" :className="feeProps.className" />
  </div>
</template>

<script>
import Fee from "./Fee";
export default {
  name: "Tag3",
  components: {
    Fee,
  },
  props: ["x029", "className", "feeProps"],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'

.tag-15
  align-items: center
  background-color: $selago
  display: flex
  gap: 20px
  height: 92px
  margin-top: 2px
  position: relative
  width: 648px

.x029-1
  height: 23px
  letter-spacing: 0
  line-height: normal
  margin-bottom: 4.61px
  margin-left: 16px
  width: 392px

.tag-15.tag-16
  margin-top: unset
</style>
