<template>
  <div class="container-center-horizontal">
    <div class="service-375 screen">
      <div class="overlap-group1-1" :style="{ 'background-image': 'url(' + overlapGroup + ')' }">
        <div class="rectangle-11-1"></div>
        <div class="service-2">{{ service }}</div>
        <img class="union-2" src="img/union-26.svg" alt="Union" @click="drawer = true" />
        <img class="logo-01-2" src="img/logo-01-12.svg" alt="logo-01" />
      </div>

      <frame15 :rectangle="diamondReportProps.rectangle"        :reportUrl="diamondReportProps.reportUrl"       :reportName="diamondReportProps.reportName" />
      <frame15 :rectangle="gemstoneReportProps.rectangle"       :reportUrl="gemstoneReportProps.reportUrl"      :reportName="gemstoneReportProps.reportName" />
      <frame15 :rectangle="pearlReportProps.rectangle"          :reportUrl="pearlReportProps.reportUrl"         :reportName="pearlReportProps.reportName" />
      <frame15 :rectangle="otherGemstoneReportProps.rectangle"  :reportUrl="otherGemstoneReportProps.reportUrl" :reportName="otherGemstoneReportProps.reportName" />
      <frame15 :rectangle="premiumReportProps.rectangle"        :reportUrl="premiumReportProps.reportUrl"       :reportName="premiumReportProps.reportName" />


      <div class="terms">
        <h1 class="additional-service-1">{{ termsTitle }}</h1>
          <p class="terms-content heebo-normal-black-12px" v-html="termsContent"></p>
          <p class="heebo-normal-black-12px" style="margin-top: 20px;text-align: center;">
            <router-link :to="`/s/terms-and-conditions`" style="visited: {text-align:center; color:#2a2f88;}">
              View More >>
            </router-link>
          </p>
        </div>
      


      <h1 class="additional-service-1">{{ additionalService }}</h1>

      <frame18 :addition="additional1Props.addition" :className="additional1Props.className" :rectangle="additional1Props.rectangle" />
      <frame18 :addition="additional2Props.addition" :className="additional2Props.className" :rectangle="additional2Props.rectangle" />
      <frame18 :addition="additional3Props.addition" :className="additional3Props.className" :rectangle="additional3Props.rectangle" />

      <foot v-bind="globalData" />
      <el-drawer direction="ltr" :visible.sync="drawer" :with-header="false" size="50%">
        <left-nav />
      </el-drawer>
    </div>
  </div>
</template>

<script>
import Frame15 from "./components/Frame15";
import Frame18 from "./components/Frame18";
import Foot from "./components/Foot375";
import LeftNav from "./components/Navbar";
export default {
  name: "Service375",
  components: {
    Frame15,
    Frame18,
    Foot,
    LeftNav,
  },
  data() {
    return {
      drawer: false,
    }
  },
  props: [
    "globalData",
    "overlapGroup",
    
    "service",
    "diamondReportProps",
    "gemstoneReportProps",
    "pearlReportProps",
    "otherGemstoneReportProps",
    "premiumReportProps",

    "additionalService",
    "additional1Props",
    "additional2Props",
    "additional3Props",
    "termsTitle",
    "termsContent",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../variables'

.terms
  // background-color: #fff
  width: 375px

.terms-title
  align-self: flex-start
  letter-spacing: 0
  line-height: normal
  // margin-left: 60px
  margin-top: 20px
  margin-bottom: 20px
  min-height: 40px
  text-align: center

.terms-content
  margin: 20px
  
  
.service-375
  align-items: center
  background-color: $selago
  display: flex
  flex-direction: column
  height: 3553px
  position: relative
  width: 375px

.overlap-group1-1
  background-position: 50% 50%
  background-size: cover
  height: 200px
  position: relative
  width: 375px

.rectangle-11-1
  background-color: $black-2
  height: 200px
  left: 0
  position: absolute
  top: 0
  width: 375px

.service-2
  color: $white
  font-family: $font-family-plus_jakarta_sans
  font-size: $font-size-xxl
  font-weight: 700
  left: 137px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 89px

.logo-01-2
  height: 26px
  left: 143px
  position: absolute
  top: 10px
  width: 90px

.union-2
  height: 17px
  left: 15px
  position: absolute
  top: 15px
  width: 19px

.frame-1
  align-items: center
  background-color: $white
  display: flex
  flex-direction: column
  margin-top: 20px
  min-height: 294px
  padding: 10px 0
  width: 355px

.rectangle-69
  height: 199px
  object-fit: cover
  width: 335px

.name-3
  letter-spacing: 0
  line-height: normal
  margin-top: 19px
  min-height: 20px
  min-width: 149px

.view-more-3
  letter-spacing: 0
  line-height: normal
  margin-top: 10px
  min-height: 15px
  min-width: 87px
  cursor: pointer

.gemstone-report-1
  letter-spacing: 0
  line-height: normal
  margin-top: 19px
  min-height: 20px
  min-width: 157px

.name-4
  letter-spacing: 0
  line-height: normal
  margin-top: 19px
  min-height: 20px
  min-width: 117px

.additional-service-1
  align-self: flex-start
  color: $mine-shaft
  font-family: $font-family-plus_jakarta_sans
  font-size: $font-size-xxl
  font-weight: 700
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  margin-top: 39px
  min-height: 30px










.logo-02-2
  height: 42px
  left: 30px
  position: absolute
  top: 30px
  width: 34px

.service-5
  left: 30px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 221px

.place-5
  left: 30px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 261px

.about-algt-6
  left: 30px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 141px
  white-space: nowrap

.place-6
  left: 30px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 101px

.news-library-2
  left: 30px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 181px
  white-space: nowrap
</style>
