<template>
  <div class="container-center-horizontal">
    <div class="news-375 screen">
      <div class="overlap-group1-1">
        <img class="union-2" src="img/union-12.svg" alt="Union" @click="drawer = true" />
        <img class="logo-01-2" src="img/logo-01-2.svg" alt="logo-01" />
      </div>
      <h1 class="title-1 plusjakartasans-bold-mine-shaft-24px">{{ title }}</h1>
      <frame13 v-for="(item,index) in newsListData" v-bind="item" v-bind:key="item.id" :index="index"/>
      <foot v-bind="globalData" />
      <el-drawer direction="ltr" :visible.sync="drawer" :with-header="false" size="50%">
        <left-nav/>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import Tag2 from "./components/Tag2";
import Frame13 from "./components/Frame13";
import Foot from "./components/Foot375";
import LeftNav from "./components/Navbar";
export default {
  name: "News375",
  components: {
    Tag2,
    Frame13,
    Foot,
    LeftNav,
  },
  data() {
    return {
      drawer: false,
    }
  },
  props: [
    "globalData",
    "title",
    "newsListData",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../variables'

.news-375
  align-items: center
  background-color: $selago
  display: flex
  flex-direction: column
  // height: 2872px
  position: relative
  width: 375px

.overlap-group1-1
  align-items: flex-start
  background-color: $white
  box-shadow: 0px 1px 16px #33333326
  display: flex
  gap: 109px
  height: 47px
  min-width: 375px
  padding: 10px 15px

.union-2
  align-self: center
  height: 17px
  width: 19px

.logo-01-2
  height: 26px
  width: 90px

.title-1
  align-self: flex-start
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  margin-top: 19px
  min-height: 30px

.frame-12
  align-items: flex-start
  background-color: $white
  display: flex
  flex-direction: column
  margin-top: 21px
  min-height: 357px
  padding: 10px 0
  position: relative
  width: 355px

.rectangle-69-5
  align-self: center
  height: 199px
  width: 335px

.text-1-2
  letter-spacing: 0
  line-height: normal
  margin-left: 10px
  margin-top: 19px
  min-height: 20px

.encased-in-a-lush-tr-4
  -webkit-box-orient: vertical
  -webkit-line-clamp: 1
  align-self: center
  display: -webkit-box
  letter-spacing: 0
  line-height: 18px
  margin-top: 19px
  min-height: 35px
  overflow: hidden
  text-overflow: ellipsis
  width: 335px

.frame-17
  align-items: flex-start
  background-color: $white
  display: flex
  flex-direction: column
  margin-top: 20px
  min-height: 357px
  padding: 10px 0
  position: relative
  width: 355px

.rectangle-69-6
  align-self: center
  height: 199px
  object-fit: cover
  width: 335px










.logo-02-2
  height: 42px
  left: 30px
  position: absolute
  top: 30px
  width: 34px

.service-5
  left: 30px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 221px

.place-5
  left: 30px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 261px

.about-algt-6
  left: 30px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 141px
  white-space: nowrap

.place-6
  left: 30px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 101px

.news-library-2
  left: 30px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 181px
  white-space: nowrap
</style>
