<template>
  <div class="container-center-horizontal">
    <div class="report-check-1024 screen">
      <div class="overlap-group1-1">
        <img class="rectangle-21" :src="overlapGroup" alt="Rectangle 21" />
        <div class="rectangle-11-2"></div>
        <left-nav />
        <info v-bind="globalData" />
      </div>
      <h1 class="name-4">{{ title }}</h1>
      <p class="pearls-submitted-to-2 heebo-normal-mine-shaft-14px" v-html="pearlsSubmittedTo"></p>
      <div class="group-19 group-19-1">
        <img class="group-19-item" :src="x1" alt="1" />
        <!-- <img class="group-19-item" :src="x3" alt="3" /> -->
      </div>
      <div class="testing-methods-2 heebo-bold-black-20px">{{ testingMethods }}</div>
      <p class="all-pearls-submitted-2 heebo-normal-mine-shaft-14px">{{ allPearlsSubmitted }}</p>
      <div class="name-5 heebo-bold-black-20px">{{ name2 }}</div>
      <p class="includes-the-followi-2 heebo-normal-mine-shaft-14px" v-html="includesTheFollowi"></p>
      <img class="rectangle-105-2" :src="rectangle105" alt="Rectangle 105" />
      <footBottom v-bind="globalData" />

    </div>
  </div>
</template>

<script>
import LeftNav from "../components/Navbar";
import FootBottom from "../components/FootBottom";
import Info from "../components/Info";
export default {
  name: "PearlReport1024",
  components: {
    LeftNav,
    FootBottom,
    Info,
  },
  props: [
    "globalData",
    "overlapGroup",
    "title",
    "pearlsSubmittedTo",
    "x1",
    "x3",
    "testingMethods",
    "allPearlsSubmitted",
    "name2",
    "includesTheFollowi",
    "rectangle105",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'

.group-19
  align-items: flex-start
  display: flex
  margin-top: 1px
  min-width: 832px

.group-19-item
  height: 586px
  object-fit: cover
  width: 832px

.group-19.group-19-1
  margin-top: 41px

.report-check-1024
  align-items: center
  background-color: $selago
  display: flex
  flex-direction: column
  // height: 1875px
  overflow: hidden
  position: relative
  width: 1024px

.overlap-group1-1
  height: 300px
  position: relative
  width: 1024px

.place-3
  left: 80px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.rectangle-21
  height: 300px
  left: 0
  object-fit: cover
  position: absolute
  top: 0
  width: 1024px

.rectangle-11-2
  background-color: $black-2
  height: 300px
  left: 0
  position: absolute
  top: 0
  width: 1024px

.logo-01-2
  height: 43px
  left: 437px
  position: absolute
  top: 157px
  width: 150px

.group-29
  align-items: flex-end
  display: flex
  height: 20px
  left: 651px
  min-width: 337px
  position: absolute
  top: 29px

.vector-stroke
  height: 20px
  width: 20px

.phone-1
  letter-spacing: 0
  line-height: normal
  margin-left: 10px
  min-height: 19px
  min-width: 108px
  white-space: nowrap

.vector-2
  align-self: center
  height: 16px
  margin-left: 30px
  width: 20px

.infoalgtlabscom-1
  cursor: pointer
  letter-spacing: 0
  line-height: normal
  margin-left: 10px
  min-height: 19px
  min-width: 135px
  white-space: nowrap

.name-4
  color: $mine-shaft
  font-family: $font-family-plus_jakarta_sans
  font-size: $font-size-xxl
  font-weight: 700
  letter-spacing: 0
  line-height: normal
  margin-top: 39px
  min-height: 40px
  min-width: 192px

.pearls-submitted-to-2
  letter-spacing: 0
  line-height: 16.8px
  margin-top: 40px
  min-height: 80px
  width: 944px

.testing-methods-2
  align-self: flex-start
  letter-spacing: 0
  line-height: 24.0px
  margin-left: 40px
  margin-top: 39px
  white-space: nowrap

.all-pearls-submitted-2
  letter-spacing: 0
  line-height: 16.8px
  margin-top: 10px
  min-height: 52px
  width: 944px

.name-5
  align-self: flex-start
  letter-spacing: 0
  line-height: 24.0px
  margin-left: 40px
  margin-top: 40px
  white-space: nowrap

.includes-the-followi-2
  letter-spacing: 0
  line-height: 16.8px
  margin-right: 17.0px
  margin-top: 10px
  min-height: 169px
  width: 927px

.rectangle-105-2
  align-self: flex-start
  height: 200px
  margin-left: 40px
  margin-top: 41px
  object-fit: cover
  width: 260px

.foot
  align-items: flex-start
  align-self: flex-start
  display: flex
  margin-top: 40px
  min-width: 1036px

.overlap-group-2
  align-items: center
  background-color: $selago
  display: flex
  height: 100px
  justify-content: flex-end
  min-width: 1024px
  padding: 28px 30px

.logo-02-2
  align-self: flex-end
  height: 42px
  width: 34px

.about-algt-2
  letter-spacing: 0
  line-height: normal
  margin-left: 21px
  margin-top: 3.0px
  min-height: 15px
  min-width: 76px

.our-reports-1
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  margin-top: 3.0px
  min-height: 15px
  min-width: 85px

.service-1
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  margin-top: 3.0px
  min-height: 15px
  min-width: 51px

.video-2
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  margin-top: 3.0px
  min-height: 15px
  min-width: 39px

.place-4
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  margin-top: 3.0px
  min-height: 15px
  min-width: 59px

.x2023-algt-all-rights-reserved-2
  letter-spacing: 0
  line-height: normal
  margin-left: 295px
  margin-top: 1.0px
  min-height: 15px
  min-width: 214px
</style>
