<template>
  <div class="container-center-horizontal">
    <div class="price-375 screen">
      <div class="overlap-group51">
        <img class="union-6" src="img/union-12.svg" alt="Union" @click="drawer = true" />
        <img class="logo-01-4" src="img/logo-01-2.svg" alt="logo-01" />
      </div>
      <h1 class="algt-report-price-list-1 plusjakartasans-bold-mine-shaft-24px">{{ algtReportPriceList }}</h1>
      <group47 :algtReportPriceListPdf="globalData.algtReportPriceListPdf"/>
      <div class="overlap-group55">
        <div class="name-2 plusjakartasans-bold-mine-shaft-14px">{{ name1 }}</div>
        <tag375 :x029="tag3751Props.x029" />
        <tag3752 :text10="tag37521Props.text10" :number="tag37521Props.number" />
        <tag3752 :text10="tag37522Props.text10" :number="tag37522Props.number" />
        <tag3752 :text10="tag37523Props.text10" :number="tag37523Props.number" />
        <tag3752 :text10="tag37524Props.text10" :number="tag37524Props.number" />
      </div>
      <div class="overlap-group52">
        <p class="colored-stone-identi-1 plusjakartasans-bold-mine-shaft-14px">{{ coloredStoneIdenti }}</p>
        <div class="ruby-sapphire-emeral-1">{{ rubySapphireEmeral }}</div>
        <tag375 :x029="tag3752Props.x029" />
        <tag3752 :text10="tag37525Props.text10" :number="tag37525Props.number" />
        <tag3752 :text10="tag37526Props.text10" :number="tag37526Props.number" />
        <tag3752 :text10="tag37527Props.text10" :number="tag37527Props.number" />
        <tag3752 :text10="tag37528Props.text10" :number="tag37528Props.number" />
        <tag3752 :text10="tag37529Props.text10" :number="tag37529Props.number" />
        <tag3752 :text10="tag375210Props.text10" :number="tag375210Props.number" />
        <tag3752 :text10="tag375211Props.text10" :number="tag375211Props.number" />
        <tag3752 :text10="tag375212Props.text10" :number="tag375212Props.number" />
        <tag3752 :text10="tag375213Props.text10" :number="tag375213Props.number" />
        <tag3752 :text10="tag375214Props.text10" :number="tag375214Props.number" />
        <tag3752 :text10="tag375215Props.text10" :number="tag375215Props.number" />
        <tag3752 :text10="tag375216Props.text10" :number="tag375216Props.number" />
        <tag3752 :text10="tag375217Props.text10" :number="tag375217Props.number" />
      </div>
      <div class="overlap-group53">
        <div class="other-colored-stones-loose-1 plusjakartasans-bold-mine-shaft-14px">
          {{ otherColoredStonesLoose }}
        </div>
        <tag375 :x029="tag3753Props.x029" />
        <tag3752 :text10="tag375218Props.text10" :number="tag375218Props.number" />
        <tag3752 :text10="tag375219Props.text10" :number="tag375219Props.number" />
        <tag3752 :text10="tag375220Props.text10" :number="tag375220Props.number" />
        <tag3752 :text10="tag375221Props.text10" :number="tag375221Props.number" />
        <tag3752 :text10="tag375222Props.text10" :number="tag375222Props.number" />
        <tag3752 :text10="tag375223Props.text10" :number="tag375223Props.number" />
        <tag3752 :text10="tag375224Props.text10" :number="tag375224Props.number" />
        <tag3752 :text10="tag375225Props.text10" :number="tag375225Props.number" />
        <tag3752 :text10="tag375226Props.text10" :number="tag375226Props.number" />
      </div>
      <div class="overlap-group50">
        <div class="name-3 plusjakartasans-bold-mine-shaft-14px">{{ name2 }}</div>
        <tag375 :x029="tag3754Props.x029" />
        <tag3752 :text10="tag375227Props.text10" :number="tag375227Props.number" />
        <tag3752 :text10="tag375228Props.text10" :number="tag375228Props.number" />
      </div>
      <div class="overlap-group56">
        <div class="extra-service-for-delivery-1 plusjakartasans-bold-mine-shaft-14px">
          {{ extraServiceForDelivery }}
        </div>
        <tag375 :x029="tag3755Props.x029" />
        <tag3752 :text10="tag375229Props.text10" :number="tag375229Props.number" style="height: 90px;"/>
      </div>
      <div class="overlap-group54">
        <p class="rough-diamond-identi-1 plusjakartasans-bold-mine-shaft-14px">{{ roughDiamondIdenti }}</p>
        <tag375 :x029="tag3756Props.x029" />
        <tag3752 :text10="tag375230Props.text10" :number="tag375230Props.number" />
        <tag3752 :text10="tag375231Props.text10" :number="tag375231Props.number" />
        <tag3752 :text10="tag375232Props.text10" :number="tag375232Props.number" />
        <tag3752 :text10="tag375233Props.text10" :number="tag375233Props.number" />
      </div>
      <div class="overlap-group49">
        <div class="jewelry-identification-report-1 plusjakartasans-bold-mine-shaft-14px">
          {{ jewelryIdentificationReport }}
        </div>
        <tag375 :x029="tag3757Props.x029" />
        <tag3752
          :text10="tag375234Props.text10"
          :number="tag375234Props.number"
          :className="tag375234Props.className"
        />
        <tag3752
          :text10="tag375235Props.text10"
          :number="tag375235Props.number"
          :className="tag375235Props.className"
        />
        <tag3752
          :text10="tag375236Props.text10"
          :number="tag375236Props.number"
          :className="tag375236Props.className"
        />
        <tag3752
          :text10="tag375237Props.text10"
          :number="tag375237Props.number"
          :className="tag375237Props.className"
        />
        <tag3752
          :text10="tag375238Props.text10"
          :number="tag375238Props.number"
          :className="tag375238Props.className"
        />
        <tag3752
          :text10="tag375239Props.text10"
          :number="tag375239Props.number"
          :className="tag375239Props.className"
        />
      </div>
      <group47 :className="group47Props.className" :algtReportPriceListPdf="globalData.algtReportPriceListPdf"/>
      <foot v-bind="globalData" />
      <el-drawer direction="ltr" :visible.sync="drawer" :with-header="false" size="50%">
        <left-nav/>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import LeftNav from "./components/Navbar";
import Foot from "./components/Foot375";
import Group47 from "./price/Group47";
import Tag375 from "./price/Tag375";
import Tag3752 from "./price/Tag3752";
export default {
  name: "Price375",
  components: {
    LeftNav,
    Foot,
    Group47,
    Tag375,
    Tag3752,
  },
  data() {
    return {
      drawer: false,
    }
  },
  props: [
    "globalData",
    "algtReportPriceList",
    "name1",
    "coloredStoneIdenti",
    "rubySapphireEmeral",
    "otherColoredStonesLoose",
    "name2",
    "extraServiceForDelivery",
    "roughDiamondIdenti",
    "jewelryIdentificationReport",
    "tag3751Props",
    "tag37521Props",
    "tag37522Props",
    "tag37523Props",
    "tag37524Props",
    "tag3752Props",
    "tag37525Props",
    "tag37526Props",
    "tag37527Props",
    "tag37528Props",
    "tag37529Props",
    "tag375210Props",
    "tag375211Props",
    "tag375212Props",
    "tag375213Props",
    "tag375214Props",
    "tag375215Props",
    "tag375216Props",
    "tag375217Props",
    "tag3753Props",
    "tag375218Props",
    "tag375219Props",
    "tag375220Props",
    "tag375221Props",
    "tag375222Props",
    "tag375223Props",
    "tag375224Props",
    "tag375225Props",
    "tag375226Props",
    "tag3754Props",
    "tag375227Props",
    "tag375228Props",
    "tag3755Props",
    "tag375229Props",
    "tag3756Props",
    "tag375230Props",
    "tag375231Props",
    "tag375232Props",
    "tag375233Props",
    "tag3757Props",
    "tag375234Props",
    "tag375235Props",
    "tag375236Props",
    "tag375237Props",
    "tag375238Props",
    "tag375239Props",
    "group47Props",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../variables'

.price-375
  align-items: center
  background-color: $selago
  display: flex
  flex-direction: column
  height: 2825px
  position: relative
  width: 375px

.overlap-group51
  align-items: flex-start
  background-color: $white
  box-shadow: 0px 1px 16px #33333326
  display: flex
  gap: 109px
  height: 47px
  min-width: 375px
  padding: 10px 15px

.union-6
  align-self: center
  height: 17px
  width: 19px

.logo-01-4
  height: 26px
  width: 90px

.algt-report-price-list-1
  align-self: flex-start
  letter-spacing: 0
  line-height: normal
  margin-left: 10px
  margin-top: 19px
  min-height: 30px

.overlap-group55
  align-items: center
  background-color: $white
  display: flex
  flex-direction: column
  margin-top: 20px
  min-height: 213px
  padding: 11px 0
  position: relative
  width: 355px

.name-2,
.colored-stone-identi-1,
.other-colored-stones-loose-1,
.name-3,
.extra-service-for-delivery-1,
.jewelry-identification-report-1
  align-self: flex-start
  letter-spacing: 0
  line-height: normal
  margin-left: 12px
  min-height: 18px

.overlap-group52
  align-items: center
  background-color: $white
  display: flex
  flex-direction: column
  margin-top: 10px
  min-height: 528px
  padding: 11px 0
  position: relative
  width: 355px

.ruby-sapphire-emeral-1
  align-self: flex-start
  color: $mine-shaft
  font-family: $font-family-plus_jakarta_sans
  font-size: $font-size-m
  font-weight: 400
  letter-spacing: 0
  line-height: normal
  margin-left: 12px
  margin-top: 12px
  min-height: 15px

.overlap-group53
  align-items: center
  background-color: $white
  display: flex
  flex-direction: column
  margin-top: 10px
  min-height: 373px
  padding: 11px 0
  position: relative
  width: 355px

.overlap-group50
  align-items: center
  background-color: $white
  display: flex
  flex-direction: column
  margin-top: 10px
  min-height: 149px
  padding: 11px 0
  position: relative
  width: 355px

.overlap-group56
  align-items: center
  background-color: $white
  display: flex
  flex-direction: column
  margin-top: 10px
  min-height: 157px
  padding: 11px 0
  position: relative
  width: 355px

.overlap-group54
  align-items: center
  background-color: $white
  display: flex
  flex-direction: column
  margin-top: 10px
  min-height: 213px
  padding: 11px 0
  position: relative
  width: 355px

.rough-diamond-identi-1
  letter-spacing: 0
  line-height: normal
  margin-right: 24.0px
  min-height: 18px
  min-width: 307px

.overlap-group49
  align-items: center
  background-color: $white
  display: flex
  flex-direction: column
  margin-top: 10px
  min-height: 412px
  padding: 11px 0
  position: relative
  width: 355px
</style>
