<template>
  <div class="container-center-horizontal">
    <div class="report-check-1920 screen">
      <div class="flex-col">
        <div class="overlap-group1" :style="{ 'background-image': 'url(' + overlapGroup1 + ')' }">
          <div class="rectangle-11"></div>
          <left-nav />
          <info v-bind="globalData" />
        </div>
        <h1 class="gemstone-report plusjakartasans-bold-mine-shaft-48px">{{ title }}</h1>
        <p class="we-offer-both-a-verb heebo-normal-mine-shaft-16px" v-html="weOfferBothAVerb"></p>
      </div>
      <div class="flex-row">
        <div class="group-19">
          <el-carousel class="group-19-item">
            <el-carousel-item v-for="item in images" :key="item.url" class="group-19-item">
              <img class="group-19-item" :src="item.url" :alt="item.url" />
            </el-carousel-item>
          </el-carousel>
          <!-- <img class="group-19-item" :src="images[0]" alt="1" /> -->
          <!-- <img class="group-19-item" src="img/--3-3.png" alt="3" /> -->
        </div>
        <!-- <div class="flex-col-1">
          <p class="gcs-gemstone-reports heebo-bold-black-20px">{{ gcsGemstoneReports }}</p>
          <p class="report-number-each heebo-bold-black-16px">
            <span class="heebo-bold-mine-shaft-16px">{{ spanText1 }}</span><span class="heebo-normal-mine-shaft-16px">{{
              spanText2 }}</span>
          </p>
          <p class="flex-col-item heebo-bold-black-16px">
            <span class="heebo-bold-mine-shaft-16px">{{ spanText3 }}</span><span class="heebo-normal-mine-shaft-16px">{{
              spanText4 }}</span>
          </p>
          <p class="object-description heebo-bold-black-16px">
            <span class="heebo-bold-mine-shaft-16px">{{ spanText5 }}</span><span class="heebo-normal-mine-shaft-16px">{{
              spanText6 }}</span>
          </p>
          <p class="identification-des heebo-bold-black-16px">
            <span class="heebo-bold-mine-shaft-16px">{{ spanText7 }}</span><span class="heebo-normal-mine-shaft-16px">{{
              spanText8 }}</span>
          </p>
          <p class="flex-col-item heebo-bold-black-16px">
            <span class="heebo-bold-mine-shaft-16px">{{ spanText9 }}</span><span class="heebo-normal-mine-shaft-16px">{{
              spanText10 }}</span>
          </p>
          <p class="shape-cut-descri heebo-bold-black-16px">
            <span class="heebo-bold-mine-shaft-16px">{{ spanText11 }}</span><span class="heebo-normal-mine-shaft-16px">{{
              spanText12 }}</span>
          </p>
          <p class="flex-col-item heebo-bold-black-16px">
            <span class="heebo-bold-mine-shaft-16px">{{ spanText13 }}</span><span class="heebo-normal-mine-shaft-16px">{{
              spanText14 }}</span>
          </p>
          <p class="comment-describes heebo-bold-black-16px">
            <span class="heebo-bold-mine-shaft-16px">{{ spanText15 }}</span><span class="heebo-normal-mine-shaft-16px">{{
              spanText16 }}</span>
          </p>
          <p class="origin-opinion-gcs heebo-bold-mine-shaft-16px">
            <span class="heebo-bold-mine-shaft-16px">{{ spanText17 }}</span><span class="heebo-normal-mine-shaft-16px">{{
              spanText18 }}</span>
          </p>
        </div> -->
      </div>
      <div class="group-2">
        <footBottom v-bind="globalData" />
      </div>
    </div>
  </div>
</template>

<script>
import LeftNav from "../components/Navbar";
import Info from "../components/Info";
import FootBottom from "../components/FootBottom";
import 'element-ui/lib/theme-chalk/index.css';

export default {
  name: "GemstoneReport1920",
  components: {
    LeftNav,
    Info,
    FootBottom,
  },
  props: [
    "globalData",
    "overlapGroup1",
    "title",
    "weOfferBothAVerb",
    "images",
    "x1",
    "x3",
    "gcsGemstoneReports",
    "spanText1",
    "spanText2",
    "spanText3",
    "spanText4",
    "spanText5",
    "spanText6",
    "spanText7",
    "spanText8",
    "spanText9",
    "spanText10",
    "spanText11",
    "spanText12",
    "spanText13",
    "spanText14",
    "spanText15",
    "spanText16",
    "spanText17",
    "spanText18",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'
.group-19
  align-items: flex-start
  display: flex
  margin-top: 1px
  min-width: 832px

.group-19-item
  height: 900px
  object-fit: cover
  width: 1277px

.group-19.group-19-1
  margin-top: 41px
.report-check-1920
  align-items: flex-start
  background-color: $selago
  display: flex
  flex-direction: column
  // height: 1705px
  overflow: hidden
  width: 1920px

.flex-col
  align-items: center
  display: flex
  flex-direction: column
  min-height: 639px
  width: 1920px

.overlap-group1
  background-position: 50% 50%
  background-size: cover
  height: 400px
  position: relative
  width: 1920px

.rectangle-11
  background-color: $black-2
  height: 400px
  left: 0
  position: absolute
  top: 0
  width: 1920px

.place
  left: 80px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.about-us
  left: 156px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.services
  left: 261px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.video
  left: 363px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.place-1
  left: 439px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.phone
  left: 1537px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.infoalgtlabscom
  cursor: pointer
  left: 1705px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.rectangle-12
  border: 1px solid
  border-color: $white
  border-radius: 56px
  height: 24px
  left: 1323px
  position: absolute
  top: 47px
  width: 160px

.report-number
  left: 1336px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 51px
  white-space: nowrap

.logo-01
  height: 87px
  left: 810px
  position: absolute
  top: 157px
  width: 300px

.vector
  height: 20px
  left: 1507px
  position: absolute
  top: 49px
  width: 20px

.vector-1
  height: 16px
  left: 1675px
  position: absolute
  top: 51px
  width: 20px

.gemstone-report
  letter-spacing: 0
  line-height: normal
  margin-top: 59px
  min-height: 60px
  min-width: 480px

.we-offer-both-a-verb
  letter-spacing: 0
  line-height: 19.2px
  margin-top: 60px
  min-height: 60px
  width: 1560px

.flex-row
  align-items: flex-start
  align-self: center
  display: flex
  gap: 80px
  height: 775px
  margin-left: 2.0px
  margin-top: 60px
  // min-width: 1562px
  position: relative

.flex-col-1
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 775px
  width: 650px

.gcs-gemstone-reports
  letter-spacing: 0
  line-height: 24.0px
  min-height: 48px
  width: 650px

.report-number-each
  letter-spacing: 0
  line-height: 19.2px
  margin-top: 20px
  min-height: 38px
  width: 650px

.flex-col-item
  letter-spacing: 0
  line-height: 19.2px
  margin-top: 10px
  min-height: 38px
  width: 650px

.object-description
  letter-spacing: 0
  line-height: 19.2px
  margin-top: 10px
  width: 650px

.identification-des
  letter-spacing: 0
  line-height: 19.2px
  margin-top: 10px
  min-height: 76px
  width: 650px

.shape-cut-descri
  letter-spacing: 0
  line-height: 19.2px
  margin-top: 10px
  min-height: 57px
  width: 650px

.comment-describes
  letter-spacing: 0
  line-height: 19.2px
  margin-top: 10px
  min-height: 95px
  width: 650px

.origin-opinion-gcs
  letter-spacing: 0
  line-height: 19.2px
  margin-top: 10px
  min-height: 228px
  width: 648px

.group-2
  align-items: flex-start
  display: flex
  margin-top: 131px
  min-width: 1932px

.overlap-group
  align-items: center
  background-color: $selago
  display: flex
  height: 100px
  justify-content: flex-end
  min-width: 1920px
  padding: 28px 158px

.logo-02
  align-self: flex-end
  height: 42px
  width: 34px

.about-algt
  letter-spacing: 0
  line-height: normal
  margin-bottom: 0
  margin-left: 61px
  min-height: 20px
  min-width: 101px

.our-reports
  letter-spacing: 0
  line-height: normal
  margin-left: 30px
  min-height: 20px
  min-width: 113px

.service
  letter-spacing: 0
  line-height: normal
  margin-left: 30px
  min-height: 20px
  min-width: 68px

.video-1
  letter-spacing: 0
  line-height: normal
  margin-left: 30px
  min-height: 20px
  min-width: 52px

.place-2
  letter-spacing: 0
  line-height: normal
  margin-left: 30px
  min-height: 20px
  min-width: 79px

.x2023-algt-all-rights-reserved
  letter-spacing: 0
  line-height: normal
  margin-left: 759px
  margin-top: 1.0px
  min-height: 15px
  min-width: 214px
</style>
