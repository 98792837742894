<template>
  <div :class="[`frame-18`, className || ``]">
    <img class="rectangle-93" :src="rectangle" alt="Rectangle 93" />
    <p class="for-a-small-addition-1 heebo-normal-granite-gray-12px">
      {{ addition }}
    </p>
  </div>
</template>

<script>
export default {
  name: "Frame18",
  props: ["addition", "className", "rectangle"],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'

.frame-18
  align-items: center
  background-color: $white
  display: flex
  flex-direction: column
  gap: 19px
  margin-top: 21px
  min-height: 306px
  padding: 10px 0
  width: 355px

.rectangle-93
  height: 198px
  object-fit: cover
  width: 335px

.for-a-small-addition-1
  letter-spacing: 0
  line-height: 14.4px
  min-height: 58px
  width: 335px

.frame-18.frame-19
  margin-top: 20px
  min-height: 318px

.frame-18.frame-19 .for-a-small-addition-1
  min-height: 70px

.frame-18.frame-20
  margin-top: 20px
  min-height: 263px

.frame-18.frame-20 .for-a-small-addition-1
  min-height: 15px
  white-space: nowrap
</style>
