<template>
  <div class="container-center-horizontal">
    <div class="news-375 screen">
      <div class="overlap-group2-1">
        <img class="union-2" src="img/union-12.svg" alt="Union" @click="drawer = true" />
        <img class="logo-01-2" src="img/logo-01-2.svg" alt="logo-01" />
      </div>
      <div class="overlap-group1-1">

        <div class="news-diamond-report-1 plusjakartasans-bold-mine-shaft-16px">{{ title1 }}</div>
        <p class="the-gemstone-market-4 heebo-normal-granite-gray-12px" v-html="content1"></p>
        <br/><br/>
        <div class="news-diamond-report-1 plusjakartasans-bold-mine-shaft-16px"  style="margin-left: 10px;">{{ title2 }}</div>
        <p class="the-gemstone-market-4 heebo-normal-granite-gray-12px" v-html="content2"></p>
        <table style="width: 85%;margin: 20px;">
          <tr>
            <td>{{ table.tr1.td1 }}</td>
            <td>{{ table.tr1.td2 }}</td>
            <td>{{ table.tr1.td3 }}</td>
            <td colspan="3">{{ table.tr1.td4 }}</td>
          </tr>
          <tr>
            <td>{{ table.tr2.td1 }}</td>
            <td>{{ table.tr2.td2 }}</td>
            <td>{{ table.tr2.td3 }}</td>
            <td>{{ table.tr2.td4 }}</td>
            <td>{{ table.tr2.td5 }}</td>
            <td>{{ table.tr2.td6 }}</td>
          </tr>
          <tr>
            <td>{{ table.tr3.td1 }}</td>
            <td>{{ table.tr3.td2 }}</td>
            <td>{{ table.tr3.td3 }}</td>
            <td>{{ table.tr3.td4 }}</td>
            <td>{{ table.tr3.td5 }}</td>
            <td>{{ table.tr3.td6 }}</td>
          </tr>
        </table>
        <p class="the-gemstone-market-4 heebo-normal-granite-gray-12px" v-html="content3"></p>
        
      </div>
      <foot v-bind="globalData" />
      <el-drawer direction="ltr" :visible.sync="drawer" :with-header="false" size="50%">
        <left-nav />
      </el-drawer>
    </div>
  </div>
</template>

<script>
import Foot from "./components/Foot375";
import LeftNav from "./components/Navbar";
export default {
  name: "Terms375",
  components: {
    Foot,
    LeftNav,
  },
  data() {
    return {
      drawer: false,
      item: "",
    }
  },
  created() {
    let i = this.$route.query.index
    this.item = this.newsListData[i] 
  },
  props: [
    "globalData",
    "title1",
    "content1",
    "title2",
    "content2",
    "table",
    "content3",
  ],
};
</script>
<style lang="css" scoped>
table {
  border-collapse: collapse;
}

table td {
  border-bottom: 1px solid #555;
  border-right: 1px solid #555;
  padding: 5px;
  text-align: center;
  vertical-align: middle;
  color: #333;
  font-size:9px;
}

table tr:first-child td {
  border-top: 1px solid #555;
}

table tr td:first-child {
  border-left: 1px solid #555;
}
</style>
<style lang="sass" scoped>
@import '../../variables'

.news-375
  align-items: center
  background-color: $selago
  display: flex
  flex-direction: column
  // height: 1817px
  position: relative
  width: 375px

.overlap-group2-1
  align-items: flex-start
  background-color: $white
  box-shadow: 0px 1px 16px #33333326
  display: flex
  gap: 109px
  height: 47px
  min-width: 375px
  padding: 10px 15px

.union-2
  align-self: center
  height: 17px
  width: 19px

.logo-01-2
  height: 26px
  width: 90px

.overlap-group1-1
  align-items: center
  background-color: $white
  display: flex
  flex-direction: column
  margin-top: 10px
  // min-height: 1237px
  padding: 12px 0
  width: 355px

.news-diamond-report-1
  align-self: flex-start
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  min-height: 20px

.the-gemstone-market-4
  letter-spacing: 0
  line-height: 18px
  margin-top: 20px
  // min-height: 197px
  width: 315px

.rectangle-74-1
  // height: 160px
  margin-top: 21px
  object-fit: cover
  width: 315px

.the-gemstone-market-5
  letter-spacing: 0
  line-height: 18px
  margin-top: 29px
  min-height: 200px
  width: 315px

.antwerp-laboratory-f-2
  letter-spacing: 0
  line-height: 18px
  margin-top: 30px
  min-height: 107px
  width: 315px

.rectangle-75-1
  height: 160px
  margin-top: 31px
  object-fit: cover
  width: 315px

.the-gemstone-market-6
  letter-spacing: 0
  line-height: 18px
  margin-top: 14px
  min-height: 198px
  width: 315px














.logo-02-2
  height: 42px
  left: 30px
  position: absolute
  top: 30px
  width: 34px

.service-5
  left: 30px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 221px

.place-5
  left: 30px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 261px

.about-algt-6
  left: 30px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 141px
  white-space: nowrap

.place-6
  left: 30px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 101px

.news-library-2
  left: 30px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 181px
  white-space: nowrap
</style>
