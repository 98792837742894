<template>
  <div>
    <img class="logo-02-2" src="img/logo-02-1.svg" alt="logo-02" />
    <div class="place-6 plusjakartasans-bold-mine-shaft-16px">
      <router-link to="/s/home" style="visited: {text-align:center; color:#333;}">HOME</router-link>
    </div>
    <div class="about-algt-6 plusjakartasans-bold-mine-shaft-16px">
      <router-link to="/s/about" style="visited: {text-align:center; color:#333;}">ABOUT ALGT</router-link>
    </div>
    <div class="news-library-2 plusjakartasans-bold-mine-shaft-16px">
      <router-link to="/s/news" style="visited: {text-align:center; color:#333;}">NEWS & LIBRARY</router-link>
    </div>
    <div class="service-5 plusjakartasans-bold-mine-shaft-16px">
      <router-link to="/s/service" style="visited: {text-align:center; color:#333;}">SERVICE</router-link>
    </div>
    <div class="price plusjakartasans-bold-mine-shaft-16px">
      <router-link to="/s/price" style="visited: {text-align:center; color:#333;}">PRICE</router-link>
    </div>
    <div class="reg plusjakartasans-bold-mine-shaft-16px">
      <router-link to="/s/reg" style="visited: {text-align:center; color:#333;}">REGISTRATION</router-link>
    </div>
    <div class="place-5 plusjakartasans-bold-mine-shaft-16px">
      <router-link to="/s/contact" style="visited: {text-align:center; color:#333;}">CONTACT</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeftNav",
  props: ["className"],
  data() {
    return {

    }
  },
};
</script>

<style lang="sass" scoped>

.logo-02-2
  height: 42px
  left: 30px
  position: absolute
  top: 30px
  width: 34px

.service-5
  left: 30px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 221px

.price
  left: 30px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 261px

.reg
  left: 30px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 301px
.place-5
  left: 30px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 341px

.about-algt-6
  left: 30px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 141px
  white-space: nowrap

.place-6
  left: 30px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 101px

.news-library-2
  left: 30px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 181px
  white-space: nowrap
</style>
