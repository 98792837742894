<template>
  <article :class="[`group-11-item-1`, className || ``]">
    <div class="text-1 plusjakartasans-normal-cosmic-cobalt-12px">{{ text5 }}</div>
  </article>
</template>

<script>
export default {
  name: "Tag2",
  props: ["text5", "className"],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'

.group-11-item-1
  align-items: center
  border: 1px solid
  border-color: $geyser
  border-radius: 19px
  display: inline-flex
  justify-content: center
  padding: 4px 8px
  position: relative

.text-1
  letter-spacing: 0
  line-height: normal
  margin-top: -1.00px
  position: relative
  width: fit-content

.group-11-item-1.tag-2
  border-color: $brand

.group-11-item-1.tag-3,
.group-11-item-1.tag-4,
.group-11-item-1.tag-5,
.group-11-item-1.tag-6,
.group-11-item-1.tag-7,
.group-11-item-1.tag-8,
.group-11-item-1.tag-16,
.group-11-item-1.tag-17,
.group-11-item-1.tag-18,
.group-11-item-1.tag-19,
.group-11-item-1.tag-20,
.group-11-item-1.tag-21
  align-items: center
  border: 1px solid
  border-color: $brand
  border-radius: 19px
  display: inline-flex
  justify-content: center
  margin-left: 20px
  margin-top: 11px
  padding: 4px 8px
  position: relative

.group-11-item-1.tag-9,
.group-11-item-1.tag-10,
.group-11-item-1.tag-11,
.group-11-item-1.tag-12,
.group-11-item-1.tag-13,
.group-11-item-1.tag-14
  align-items: center
  border: 1px solid
  border-color: $brand
  border-radius: 19px
  display: inline-flex
  justify-content: center
  margin-left: 10px
  margin-top: 11px
  padding: 4px 8px
  position: relative

.group-11-item-1.tag-15
  align-items: center
  border: 1px solid
  border-color: $brand
  border-radius: 19px
  display: inline-flex
  justify-content: center
  padding: 4px 8px
  position: relative
</style>
