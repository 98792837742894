<template>
  <div :class="[`tag-375-1-1`, className || ``]">
    <div class="overlap-group-3">
      <div class="text-10 plusjakartasans-bold-granite-gray-12px">{{ text10 }}</div>
      <div class="fee-24">
        <div class="number-1 plusjakartasans-bold-cosmic-cobalt-12px" v-html="number"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tag3752",
  props: ["text10", "number", "className"],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'

.tag-375-1-1
  align-items: flex-start
  background-color: $selago
  display: flex
  margin-top: 1px
  width: 331px

.overlap-group-3
  height: 31px
  margin-left: 16px
  position: relative
  width: 315px

.text-10
  left: 0
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 7px
  width: 235px

.fee-24
  align-items: center
  background-color: $alto
  display: flex
  flex-wrap: wrap
  gap: 0px 0px
  left: 206px
  padding: 8px
  position: absolute
  top: 0
  width: 109px

.number-1
  letter-spacing: 0
  line-height: normal
  margin-top: -1.00px
  position: relative
  width: 93px

.tag-375-1-1.tag-375-1-34 .overlap-group-3
  height: 46px

.tag-375-1-1.tag-375-1-35 .fee-24,
.tag-375-1-1.tag-375-1-36 .fee-24
  align-items: flex-start

.tag-375-1-1.tag-375-1-37 .overlap-group-3,
.tag-375-1-1.tag-375-1-38 .overlap-group-3
  height: 61px

.tag-375-1-1.tag-375-1 .overlap-group-3,
.tag-375-1-1.tag-375-1-36
  height: 91px
</style>
