<template>
  <div class="container-center-horizontal">
    <div class="report-check-1920 screen">
      <div class="overlap-group1" :style="{ 'background-image': 'url(' + overlapGroup + ')' }">
        <div class="rectangle-11"></div>
        <left-nav />
        <info v-bind="globalData" />
      </div>
      <h1 class="other-gemstone-report">{{ title }}</h1>
      <p class="we-are-happy-to-cons" v-html="weAreHappyToCons"></p>
      <div class="flex-row">
        <el-carousel class="flex-row-item">
            <el-carousel-item v-for="item in images" :key="item.url" class="flex-row-item">
              <img class="flex-row-item" :src="item.url" :alt="item.url" />
            </el-carousel-item>
          </el-carousel>
        <!-- <img class="flex-row-item" :src="x1" alt="1" /> -->
        <!-- <img class="flex-row-item" :src="x3" alt="3" /> -->
      </div>
      <div class="group-2">
        <footBottom v-bind="globalData"/>
      </div>
    </div>
  </div>
</template>

<script>
import LeftNav from "../components/Navbar";
import Info from "../components/Info";
import FootBottom from "../components/FootBottom";
export default {
  name: "OtherGemstoneReport1920",
  components: {
    LeftNav,
    Info,
    FootBottom,
  },
  props: [
    "globalData",
    "overlapGroup",
    "title",
    "weAreHappyToCons",
    "images",
    "x1",
    "x3",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'
.el-carousel::-webkit-scrollbar
  display: none
.report-check-1920
  align-items: flex-start
  background-color: $selago
  display: flex
  flex-direction: column
  height: 1799px
  overflow: hidden
  width: 1920px

.overlap-group1
  background-position: 50% 50%
  background-size: cover
  height: 400px
  position: relative
  width: 1920px

.rectangle-11
  background-color: $black-2
  height: 400px
  left: 0
  position: absolute
  top: 0
  width: 1920px

.place
  left: 80px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.about-us
  left: 156px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.services
  left: 261px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.video
  left: 363px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.place-1
  left: 439px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.phone
  left: 1537px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.infoalgtlabscom
  cursor: pointer
  left: 1705px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.rectangle-12
  border: 1px solid
  border-color: $white
  border-radius: 56px
  height: 24px
  left: 1323px
  position: absolute
  top: 47px
  width: 160px

.report-number
  color: $white
  font-family: $font-family-roboto
  font-size: $font-size-m
  font-weight: 400
  left: 1336px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 51px
  white-space: nowrap

.logo-01
  height: 87px
  left: 810px
  position: absolute
  top: 157px
  width: 300px

.vector
  height: 20px
  left: 1507px
  position: absolute
  top: 49px
  width: 20px

.vector-1
  height: 16px
  left: 1675px
  position: absolute
  top: 51px
  width: 20px

.other-gemstone-report
  align-self: center
  color: $mine-shaft
  font-family: $font-family-plus_jakarta_sans
  font-size: $font-size-xxxxxl
  font-weight: 700
  letter-spacing: 0
  line-height: normal
  margin-top: 59px
  min-height: 60px
  min-width: 642px

.we-are-happy-to-cons
  align-self: center
  color: $mine-shaft
  font-family: $font-family-heebo
  font-size: $font-size-xl
  font-weight: 400
  letter-spacing: 0
  line-height: 19.2px
  margin-top: 60px
  min-height: 99px
  width: 1560px

.flex-row
  align-items: flex-start
  display: flex
  margin-left: 280px
  margin-top: 61px
  min-width: 832px

.flex-row-item
  height: 900px
  object-fit: cover
  width: 1277px

.group-2
  align-items: flex-start
  display: flex
  margin-top: 60px
  min-width: 1932px

.overlap-group
  align-items: center
  background-color: $selago
  display: flex
  height: 100px
  justify-content: flex-end
  min-width: 1920px
  padding: 28px 158px

.logo-02
  align-self: flex-end
  height: 42px
  width: 34px

.about-algt
  letter-spacing: 0
  line-height: normal
  margin-bottom: 0
  margin-left: 61px
  min-height: 20px
  min-width: 101px

.our-reports
  letter-spacing: 0
  line-height: normal
  margin-left: 30px
  min-height: 20px
  min-width: 113px

.service
  letter-spacing: 0
  line-height: normal
  margin-left: 30px
  min-height: 20px
  min-width: 68px

.video-1
  letter-spacing: 0
  line-height: normal
  margin-left: 30px
  min-height: 20px
  min-width: 52px

.place-2
  letter-spacing: 0
  line-height: normal
  margin-left: 30px
  min-height: 20px
  min-width: 79px

.x2023-algt-all-rights-reserved
  letter-spacing: 0
  line-height: normal
  margin-left: 759px
  margin-top: 1.0px
  min-height: 15px
  min-width: 214px
</style>
