<template>
  <div>
    <img class="logo-01-4" :src="headLogo1024" alt="logo-01" />
    <div class="group-26">
      <img class="vector-stroke" :src="phoneHeadIcon" alt="Vector (Stroke)" />
      <div class="phone-1 roboto-normal-white-14px">{{ phone }}</div>
      <img class="vector-9" :src="emailHeadIcon" alt="Vector" />
      <a :href="`mailto:` + email" target="_blank">
        <div class="infoalgtlabscom-3 roboto-normal-white-14px">
          {{ email }}
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Info",
  props: [
    "phone",
    "phoneHeadIcon",
    "email",
    "emailHeadIcon",
    "headLogo1024",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'


.logo-01-4
  height: 43px
  left: 437px
  position: absolute
  top: 140px
  width: 150px

.group-26
  align-items: center
  // align-items: flex-end
  display: flex
  height: 20px
  left: 692px
  min-width: 337px
  position: absolute
  top: 30px

.vector-stroke
  height: 20px
  width: 20px

.phone-1
  letter-spacing: 0
  line-height: normal
  margin-bottom: 2px
  margin-left: 10px
  min-height: 16px
  min-width: 94px
  white-space: nowrap
  // letter-spacing: 0
  // line-height: normal
  // margin-left: 10px
  // min-height: 19px
  // min-width: 108px
  // white-space: nowrap

.vector-9
  // align-self: center
  height: 16px
  margin-left: 20px
  width: 20px

.infoalgtlabscom-3
  cursor: pointer
  letter-spacing: 0
  line-height: normal
  margin-left: 10px
  min-height: 19px
  min-width: 135px
  white-space: nowrap

</style>
