<template>
  <div class="container-center-horizontal">
    <div class="newsu38library-1920 screen">
      <div class="flex-col">
        <div class="overlap-group2" :style="{ 'background-image': 'url(' + overlapGroup + ')' }">
          <div class="rectangle-11"></div>
          <left-nav />
          <info v-bind="globalData" />
        </div>
        <h1 class="title">{{ title }}</h1>
        <!-- <group11 v-bind="typesData" /> -->
        <div class="group-11">
          <el-input v-model="keyword" placeholder="Enter Keyword" suffix-icon="el-icon-search" @keyup.enter.native="search"></el-input>
        </div>

      </div>
      <div class="flex-row">
        <div class="frame-container">
          <frame1920 v-for="(item,index) in filterData" v-if="index%3==0" v-bind="item"  v-bind:key="item.id" :index="index"/>
        </div>
        <div class="frame-container-1">
          <frame1920 v-for="(item,index) in filterData" v-if="index%3==1" v-bind="item"  v-bind:key="item.id" :index="index"/>
        </div>
        <div class="frame-container-2">
          <frame1920 v-for="(item,index) in filterData" v-if="index%3==2" v-bind="item"  v-bind:key="item.id" :index="index"/>
        </div>
      </div>
      <foot v-bind="globalData" />
    </div>
  </div>
</template>

<script>
import LeftNav from "./components/Navbar";
import Group11 from "./components/Group11";
import Tag2 from "./components/Tag2";
import Foot from "./components/Foot1920";
import Info from "./components/Info";
import Frame1920 from "./components/Frame1920";
import 'element-ui/lib/theme-chalk/index.css';

export default {
  name: "News1920",
  components: {
    LeftNav,
    Group11,
    Tag2,
    Foot,
    Info,
    Frame1920,
  },
  data() {
    return {
      keyword: "",
      filterData: [],
    }
  },
  created() {
    this.filterData = this.newsListData
  },
  methods: {
    search() {
      console.log(this.keyword)
      this.filterData = this.newsListData.filter(item => {
        return item.content.toLowerCase().includes(this.keyword.toLowerCase());
      });
    },
  },
  props: [
    "globalData",
    "overlapGroup",
    "title",
    "typesData",
    "newsListData",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../variables'
.el-input
  width: 400px

.group-11
  align-items: flex-start
  display: flex
  gap: 10px
  height: 23px
  margin-top: 31px
  min-width: 882px
  position: relative
  left: 240px

.newsu38library-1920
  align-items: center
  background-color: $selago
  display: flex
  flex-direction: column
  // height: 2391px
  position: relative
  width: 1920px

.flex-col
  align-items: center
  display: flex
  flex-direction: column
  min-height: 573px
  position: relative
  width: 1920px

.overlap-group2
  background-position: 50% 50%
  background-size: cover
  height: 400px
  position: relative
  width: 1920px

.rectangle-11
  background-color: $black-2
  height: 400px
  left: 0
  position: absolute
  top: 0
  width: 1920px

.phone
  left: 1537px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.infoalgtlabscom
  cursor: pointer
  left: 1705px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.rectangle-12
  border: 1px solid
  border-color: $white
  border-radius: 56px
  height: 24px
  left: 1323px
  position: absolute
  top: 47px
  width: 160px

.report-number
  color: $white
  font-family: $font-family-roboto
  font-size: $font-size-m
  font-weight: 400
  left: 1336px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 51px
  white-space: nowrap

.logo-01
  height: 87px
  left: 810px
  position: absolute
  top: 157px
  width: 300px

.vector
  height: 20px
  left: 1507px
  position: absolute
  top: 49px
  width: 20px

.vector-1
  height: 16px
  left: 1675px
  position: absolute
  top: 51px
  width: 20px

.title
  color: $mine-shaft
  font-family: $font-family-plus_jakarta_sans
  font-size: $font-size-xxxxxl
  font-weight: 700
  letter-spacing: 0
  line-height: normal
  margin-left: 1.0px
  margin-top: 59px
  min-height: 60px
  min-width: 369px

.flex-row
  align-items: flex-start
  display: flex
  gap: 41px
  // height: 1170px
  margin-top: 60px
  min-width: 1600px

.frame-container
  align-items: flex-start
  display: flex
  flex-direction: column
  gap: 40px
  // min-height: 1048px
  width: 506px

.frame-container-1
  align-items: flex-start
  display: flex
  flex-direction: column
  gap: 40px
  // min-height: 1170px
  width: 506px

.frame-container-2
  align-items: flex-start
  display: flex
  flex-direction: column
  gap: 40px
  // min-height: 1026px
  width: 506px

.frame
  align-items: flex-start
  background-color: $white
  display: flex
  flex-direction: column
  min-height: 523px
  padding: 20px 0
  position: relative
  width: 506px

.rectangle-69-1
  align-self: center
  height: 300px
  position: relative
  width: 466px

.text
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  margin-top: 19px
  min-height: 30px
  min-width: 168px
  position: relative

.encased-in-a-lush-tr,
.the-certificate-iden,
.encased-in-a-lush-tr-1,
.encased-in-a-lush-tr-2,
.the-certificate-iden-1,
.encased-in-a-lush-tr-3
  -webkit-box-orient: vertical
  -webkit-line-clamp: 4
  align-self: center
  display: -webkit-box
  letter-spacing: 0
  line-height: 19.2px
  margin-top: 19px
  min-height: 80px
  overflow: hidden
  position: relative
  text-overflow: ellipsis
  width: 466px

.frame-9
  align-items: flex-start
  background-color: $white
  display: flex
  flex-direction: column
  min-height: 485px
  padding: 20px 0
  position: relative
  width: 506px

.rectangle-69-2
  align-self: center
  height: 262px
  object-fit: cover
  position: relative
  width: 466px

.text-10
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  margin-top: 19px
  min-height: 30px
  min-width: 288px
  position: relative


.frame-7
  align-items: flex-start
  background-color: $white
  display: flex
  flex-direction: column
  min-height: 607px
  padding: 20px 0
  position: relative
  width: 506px

.rectangle-69-3
  align-self: center
  height: 383px
  object-fit: cover
  position: relative
  width: 466px

.name
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  margin-top: 19px
  min-height: 30px
  min-width: 220px
  position: relative

.rectangle-69
  align-self: center
  height: 300px
  object-fit: cover
  position: relative
  width: 466px



.frame-8
  align-items: flex-start
  background-color: $white
  display: flex
  flex-direction: column
  min-height: 463px
  padding: 20px 0
  position: relative
  width: 506px

.rectangle-69-4
  align-self: center
  height: 240px
  object-fit: cover
  position: relative
  width: 466px

.gemstone-report
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  margin-top: 19px
  min-height: 30px
  min-width: 236px
  position: relative
</style>
