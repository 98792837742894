<template>
  <div :class="[`navbar roboto-normal-white-16px`, className || ``]">
    <div class="navbar-link">
      <router-link to="/l/home" style="visited: {text-align:center; color:#fff;}">HOME</router-link>
    </div>
    <div class="navbar-link">
      <router-link to="/l/about" style="visited: {text-align:center; color:#fff;}">ABOUT US</router-link>
    </div>
    <div class="navbar-link" >
      <router-link to="/l/news" style="visited: {text-align:center; color:#fff;}">NEWS &amp; LIBRARY</router-link>
    </div>
    <div class="navbar-link" >
      <router-link to="/l/service" style="visited: {text-align:center; color:#fff;}">SERVICE</router-link>
    </div>
    <div class="navbar-link" >
      <router-link to="/l/price" style="visited: {text-align:center; color:#fff;}">PRICE</router-link>
    </div>
    <div class="navbar-link" >
      <router-link to="/l/reg" style="visited: {text-align:center; color:#fff;}">REGISTRATION</router-link>
    </div>
    <div class="navbar-link" >
      <router-link to="/l/contact" style="visited: {text-align:center; color:#fff;}">CONTACT</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeftNav",
  props: ["className"],
};
</script>

<style lang="sass">
.navbar
  align-items: flex-start
  display: inline-flex
  gap: 30px 14px
  left: 80px
  position: absolute
  top: 50px

.navbar-link
  letter-spacing: 0
  line-height: normal
  margin-top: -1.00px
  position: relative
  white-space: nowrap
  width: fit-content
  cursor: pointer

.navbar.navbar-1
  left: 40px
  top: 30px
</style>
