<template>
  <div class="overlap-group">
    <img class="logo-02" :src="footLogo2" alt="logo-02" />
    <div class="about-algt plusjakartasans-bold-mine-shaft-16px">{{ aboutAlgt }}</div>
    <div class="our-reports plusjakartasans-bold-mine-shaft-16px">{{ ourReports }}</div>
    <div class="service plusjakartasans-bold-mine-shaft-16px">{{ service }}</div>
    <div class="video plusjakartasans-bold-mine-shaft-16px">{{ video }}</div>
    <div class="place plusjakartasans-bold-mine-shaft-16px">{{ place }}</div>
    <p class="x2023-algt-all-rights-reserved plusjakartasans-normal-black-12px">
      {{ allRightsReserved }}
    </p>
  </div>
</template>

<script>
export default {
  name: "FootBottom",
  props: [
    "aboutAlgt",
    "ourReports",
    "service",
    "video",
    "place",
    "allRightsReserved",
    "footLogo2",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'

.overlap-group
  align-items: center
  background-color: $selago
  display: flex
  height: 100px
  justify-content: flex-end
  min-width: 1920px
  padding: 28px 158px

.logo-02
  align-self: flex-end
  height: 42px
  width: 34px

.about-algt
  letter-spacing: 0
  line-height: normal
  margin-bottom: 0
  margin-left: 61px
  min-height: 20px
  min-width: 101px

.our-reports
  letter-spacing: 0
  line-height: normal
  margin-left: 30px
  min-height: 20px
  min-width: 113px

.service
  letter-spacing: 0
  line-height: normal
  margin-left: 30px
  min-height: 20px
  min-width: 68px

.video
  letter-spacing: 0
  line-height: normal
  margin-left: 30px
  min-height: 20px
  min-width: 52px

.place
  letter-spacing: 0
  line-height: normal
  margin-left: 30px
  min-height: 20px
  min-width: 79px

.x2023-algt-all-rights-reserved
  letter-spacing: 0
  line-height: normal
  margin-left: 759px
  margin-top: 1.0px
  min-height: 15px
  min-width: 214px
</style>
