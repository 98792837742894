<template>
  <div class="container-center-horizontal">
    <div class="reg-1024 screen">
      <div class="overlap-group2-5">
        <img class="rectangle-21-1" :src="rectangle21" alt="Rectangle 21" />
        <div class="rectangle-11-4"></div>
        <left-nav />
        <info v-bind="globalData" />
      </div>
      <div class="overlap-group3-4">
        <h1 class="title-4">{{ title }}</h1>
        <p class="please-send-your-id-2 heebo-bold-black-14px" v-html="pleaseSendYourId"></p>
        <div class="flex-row-37">
          <div class="flex-col-45 heebo-normal-black-14px">
            <div class="company-name-2">{{ companyName }}</div>
            <div class="flex-col-item-5">{{ contactPerson }}</div>
            <div class="flex-col-item-5">{{ telephone }}</div>
            <div class="flex-col-item-5">{{ mobilePhone }}</div>
            <div class="flex-col-item-5">{{ email }}</div>
            <div class="flex-col-item-5">{{ address }}</div>
            <div class="city-2">{{ city }}</div>
            <div class="flex-col-item-5">{{ country }}</div>
            <div class="flex-col-item-5">{{ vatNumber }}</div>
            <div class="flex-col-item-5">{{ idPassportCopy }}</div>
          </div>
          <div class="flex-col-46">
            <div class="rectangle-131-1"></div>
            <div class="rectangle-1-1"></div>
            <div class="rectangle-1-1"></div>
            <div class="rectangle-1-1"></div>
            <div class="rectangle-1-1"></div>
            <div class="rectangle-139-1"></div>
            <div class="rectangle-1-1"></div>
            <div class="rectangle-1-1"></div>
            <div class="rectangle-1-1"></div>
            <div class="rectangle-1-1"></div>
            <p class="i-have-read-and-agre-2 heebo-normal-black-14px">
              <span class="heebo-normal-black-14px">{{ spanText1 }}</span>
              <span class="heebo-bold-black-14px">{{ spanText2 }}</span>
              <span class="heebo-normal-black-14px">{{ spanText3 }}</span>
              <span class="heebo-bold-black-14px">{{ spanText4 }}</span>
              <span class="heebo-normal-black-14px">{{ spanText5 }}</span>
            </p>
            <div class="client-signature-2 heebo-normal-black-16px">{{ clientSignature }}</div>
            <div class="overlap-group1-7">
              <div class="registration-form-download-2 heebo-bold-white-24px">
                <a :href="globalData.customerRegistrationFormPdf" target="_blank" style="visited: {text-align:center; color:#fff;}">
                  {{ registrationFormDownload }}
                </a> 
              </div>
            </div>
          </div>
        </div>
      </div>
      <foot1024 v-bind="globalData" />
    </div>
  </div>
</template>

<script>
import LeftNav from "./components/Navbar";
import Foot1024 from "./components/Foot1024";
import Info from "./components/Info";

export default {
  name: "Reg1024",
  components: {
    LeftNav,
    Foot1024,
    Info,
  },
  props: [
    "globalData",
    "rectangle21",
    "phone",
    "infoAlgtlabsCom",
    "title",
    "pleaseSendYourId",
    "companyName",
    "contactPerson",
    "telephone",
    "mobilePhone",
    "email",
    "address",
    "city",
    "country",
    "vatNumber",
    "idPassportCopy",
    "spanText1",
    "spanText2",
    "spanText3",
    "spanText4",
    "spanText5",
    "clientSignature",
    "registrationFormDownload",
    "leftNav1024Props",
  ],
};
</script>

<style lang="sass">
@import '../../variables'

.reg-1024
  align-items: flex-start
  background-color: $selago
  display: flex
  flex-direction: column
  height: 1871px
  overflow: hidden
  position: relative
  width: 1024px

.overlap-group2-5
  height: 300px
  position: relative
  width: 1960px

.rectangle-21-1
  height: 300px
  left: 0
  object-fit: cover
  position: absolute
  top: 0
  width: 1024px

.rectangle-11-4
  background-color: $black-3
  height: 300px
  left: 0
  position: absolute
  top: 0
  width: 1024px

.logo-01-11
  height: 43px
  left: 437px
  position: absolute
  top: 157px
  width: 150px

.group-29-3
  align-items: center
  display: flex
  height: 20px
  left: 722px
  min-width: 266px
  position: absolute
  top: 27px

.vector-stroke-3
  height: 20px
  width: 20px

.phone-3
  letter-spacing: 0
  line-height: normal
  margin-left: 10px
  min-height: 14px
  min-width: 81px
  white-space: nowrap

.vector-25
  height: 16px
  margin-left: 20px
  width: 20px

.infoalgtlabscom-9
  cursor: pointer
  letter-spacing: 0
  line-height: normal
  margin-left: 10px
  min-height: 14px
  min-width: 101px
  white-space: nowrap

.overlap-group3-4
  align-items: flex-start
  align-self: center
  background-color: $white
  display: flex
  flex-direction: column
  margin-top: 47px
  min-height: 1108px
  padding: 49px 60px
  width: 924px

.title-4
  color: $black
  font-family: $font-family-plus_jakarta_sans
  font-size: 32px
  font-weight: 700
  letter-spacing: 0
  line-height: normal
  min-height: 40px

.please-send-your-id-2
  letter-spacing: 0
  line-height: 22.4px
  margin-top: 30px
  min-height: 44px
  width: 783px

.flex-row-37
  align-items: flex-start
  display: flex
  gap: 31px
  height: 858px
  margin-top: 37px
  min-width: 783px

.flex-col-45
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 11px
  min-height: 634px
  width: 112px

.company-name-2
  letter-spacing: 0
  line-height: 14px
  white-space: nowrap

.flex-col-item-5
  letter-spacing: 0
  line-height: 14px
  margin-top: 46px
  white-space: nowrap

.city-2
  letter-spacing: 0
  line-height: 14px
  margin-top: 126px
  white-space: nowrap

.flex-col-46
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 858px
  width: 640px

.rectangle-131-1
  background-color: $white
  border: 1px solid
  border-color: $alto
  border-radius: 4px
  box-shadow: inset 2px 2px 2px #0000001a
  height: 40px
  width: 640px

.rectangle-1-1
  background-color: $white
  border: 1px solid
  border-color: $alto
  border-radius: 4px
  box-shadow: inset 2px 2px 2px #0000001a
  height: 40px
  margin-top: 20px
  width: 640px

.rectangle-139-1
  background-color: $white
  border: 1px solid
  border-color: $alto
  border-radius: 4px
  box-shadow: inset 2px 2px 2px #0000001a
  height: 120px
  margin-top: 20px
  width: 640px

.i-have-read-and-agre-2
  letter-spacing: 0
  line-height: 14px
  margin-top: 29px
  min-height: 28px
  width: 600px

.client-signature-2
  letter-spacing: 0
  line-height: 16px
  margin-top: 20px
  white-space: nowrap

.overlap-group1-7
  align-items: center
  background-color: $brand
  border-radius: 4px
  display: flex
  height: 64px
  margin-top: 41px
  min-width: 437px
  padding: 0 30px

.registration-form-download-2
  letter-spacing: 0
  line-height: 38.4px
  min-width: 377px
  white-space: nowrap
</style>
