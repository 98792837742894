<template>
  <div :class="[`group-47`, className || ``]">
    <div class="overlap-group-1">
      <img
        class="vector-16"
        src="img/vector-9.svg"
        alt="Vector"
      />
      <div class="fee-pdf-3 heebo-bold-white-16px">
        <a :href="algtReportPriceListPdf" target="_blank" style="visited: {text-align:center; color:#fff;}">
          Download Our Testing Price List
      </a>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Group47",
  props: ["className","algtReportPriceListPdf"],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'

.group-47
  align-items: flex-start
  display: flex
  margin-left: 2.0px
  margin-top: 21px
  min-width: 357px

.overlap-group-1
  align-items: center
  background-color: $brand
  border-radius: 4px
  display: flex
  gap: 12px
  height: 40px
  min-width: 355px
  padding: 0 71px

.vector-16
  height: 24px
  width: 18px

.fee-pdf-3
  letter-spacing: 0
  line-height: 25.6px
  min-height: 26px
  min-width: 182px
  white-space: nowrap

.group-47.group-48
  margin-top: 20px
</style>
