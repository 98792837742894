<template>
  <div class="container-center-horizontal">
    <div class="diamond-report-375 screen">
      <div class="overlap-group-1" :style="{ 'background-image': 'url(' + overlapGroup1 + ')' }">
        <div class="rectangle-11-1"></div>
        <h1 class="name-2">{{ name1 }}</h1>
        <img class="logo-01-1" src="img/logo-01-12.svg" alt="logo-01" />
        <img class="union" src="img/union-26.svg" alt="Union" @click="drawer = true" />
      </div>
      <p class="the-algt-natural-dia-1 heebo-normal-mine-shaft-12px" v-html="theAlgtNaturalDia"></p>
      <div class="group-18">
        <el-carousel class="group-18-item">
            <el-carousel-item v-for="item in images" :key="item.url" class="group-18-item">
              <img class="group-18-item" :src="item.url" :alt="item.url" />
            </el-carousel-item>
        </el-carousel>
        <!-- <img class="group-18-item" :src="x1" alt="1" /> -->
        <!-- <img class="group-18-item" :src="x3" alt="3" /> -->
      </div>
      <div class="name-3 heebo-bold-black-16px">{{ name2 }}</div>
      <p class="determination-of-dia-1 heebo-normal-mine-shaft-12px">{{ determinationOfDia }}</p>
      <div class="colored-diamond-grading-report-1 heebo-bold-black-16px">{{ coloredDiamondGradingReport }}</div>
      <p class="full-quality-assessm-1 heebo-normal-mine-shaft-12px">{{ fullQualityAssessm }}</p>
      <img class="rectangle-105-1" :src="x1" alt="Rectangle 105" />
      <foot-bottom v-bind="globalData" />
      <el-drawer direction="ltr" :visible.sync="drawer" :with-header="false" size="50%">
        <left-nav />
      </el-drawer>
    </div>
  </div>
</template>

<script>
import LeftNav from "../components/Navbar";
import FootBottom from "../components/FootBottom";
export default {
  name: "DiamondReport375",
  components: {
    LeftNav,
    FootBottom,
  },
  data() {
    return {
      drawer: false,
    }
  },
  props: [
    "globalData",
    "overlapGroup1",
    "name1",
    "theAlgtNaturalDia",
    "images",
    "x1",
    "x3",
    "name2",
    "determinationOfDia",
    "coloredDiamondGradingReport",
    "fullQualityAssessm",
    "rectangle105",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'
.el-carousel::-webkit-scrollbar
  display: none
.diamond-report-375
  align-items: center
  background-color: $selago
  display: flex
  flex-direction: column
  height: 1600px
  width: 375px

.overlap-group-1
  background-position: 50% 50%
  background-size: cover
  height: 200px
  position: relative
  width: 375px

.rectangle-11-1
  background-color: $black-2
  height: 200px
  left: 0
  position: absolute
  top: 0
  width: 375px

.name-2
  color: $white
  font-family: $font-family-plus_jakarta_sans
  font-size: $font-size-xxl
  font-weight: 700
  left: 76px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 89px

.logo-01-1
  height: 26px
  left: 143px
  position: absolute
  top: 10px
  width: 90px

.union
  height: 17px
  left: 15px
  position: absolute
  top: 15px
  width: 19px

.the-algt-natural-dia-1
  letter-spacing: 0
  line-height: 18px
  margin-top: 19px
  min-height: 218px
  width: 335px

.group-18
  align-items: flex-start
  display: flex
  margin-top: 21px
  min-width: 335px

.group-18-item
  height: 236px
  object-fit: cover
  width: 336px

.name-3
  align-self: flex-start
  letter-spacing: 0
  line-height: 19.2px
  margin-left: 20px
  margin-top: 19px
  white-space: nowrap

.determination-of-dia-1
  letter-spacing: 0
  line-height: 18px
  margin-top: 15px
  min-height: 71px
  width: 335px

.colored-diamond-grading-report-1
  align-self: flex-start
  letter-spacing: 0
  line-height: 19.2px
  margin-left: 20px
  margin-top: 30px
  white-space: nowrap

.full-quality-assessm-1
  letter-spacing: 0
  line-height: 18px
  margin-top: 15px
  min-height: 178px
  width: 335px

.rectangle-105-1
  // height: 326px
  margin-top: 21px
  object-fit: contain
  width: 335px

.group-16
  align-items: flex-start
  display: flex
  gap: 16px
  height: 185px
  margin-right: 41.0px
  margin-top: 40px
  min-width: 274px

.logo-02-1
  height: 42px
  width: 34px

.flex-col-2
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 0
  min-height: 185px
  width: 214px

.about-algt-1
  letter-spacing: 0
  line-height: normal
  margin-left: 15px
  min-height: 20px

.flex-col-item
  letter-spacing: 0
  line-height: normal
  margin-left: 15px
  margin-top: 20px
  min-height: 20px

.x2023-algt-all-rights-reserved-1
  letter-spacing: 0
  line-height: normal
  margin-top: 30px
  min-height: 15px

</style>
