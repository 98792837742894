<template>
  <div :class="[`tag-4`, className || ``]">
    <div class="text-5 plusjakartasans-bold-granite-gray-18px">{{ text5 }}</div>
    <fee :number="feeProps.number" :className="feeProps.className" />
  </div>
</template>

<script>
import Fee from "./Fee";
export default {
  name: "Tag2",
  components: {
    Fee,
  },
  props: ["text5", "className", "feeProps"],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'

.tag-4
  align-items: center
  background-color: $selago
  display: flex
  gap: 20px
  // height: 41px
  margin-top: 2px
  position: relative
  width: 648px

.text-5
  letter-spacing: 0
  line-height: normal
  margin-bottom: 4.0px
  margin-left: 16px
  min-height: 23px
  width: 392px

.tag-4.tag-11,
.tag-4.tag-13,
.tag-4.tag-22,
.tag-4.tag-23,
.tag-4.tag-24,
.tag-4.tag-25,
.tag-4.tag-26,
.tag-4.tag-27,
.tag-4.tag-33,
.tag-4.tag-34,
.tag-4.tag-35,
.tag-4.tag-36,
.tag-4.tag-38,
.tag-4.tag-40,
.tag-4.tag-41,
.tag-4.tag-57,
.tag-4.tag-58,
.tag-4.tag-59,
.tag-4.tag-60,
.tag-4.tag-61,
.tag-4.tag-62,
.tag-4.tag-63,
.tag-4.tag-64,
.tag-4.tag-65
  margin-top: unset

.tag-4.flex-col-8-item
  margin-left: 2px

.tag-4.tag-42
  height: 67px

.tag-4.tag-42 .text-5
  margin-bottom: 18.59px

.tag-4.tag-43,
.tag-4.tag-45
  height: 66px

.tag-4.tag-43 .text-5,
.tag-4.tag-45 .text-5,
.tag-4.tag-46 .text-5
  margin-bottom: 18.02px

.tag-4.tag-44,
.tag-4.tag-47
  height: 91px

.tag-4.tag-44 .text-5,
.tag-4.tag-47 .text-5
  margin-bottom: 32.05px

.tag-4.tag-46
  height: 66px
  margin-top: 3px
</style>
