<template>
  <div :class="[`group-11`, className || ``]">
    <tag />
    <tag2 :text5="tag21Props.text5" />
    <tag2 :text5="tag22Props.text5" :className="tag22Props.className" />
    <tag2 :text5="tag23Props.text5" />
    <tag2 :text5="tag24Props.text5" />
    <tag2 :text5="tag25Props.text5" />
    <tag2 :text5="tag26Props.text5" />
    <tag2 :text5="tag27Props.text5" />
  </div>
</template>

<script>
import Tag from "./Tag";
import Tag2 from "./Tag2";
export default {
  name: "Group11",
  components: {
    Tag,
    Tag2,
  },
  props: [
    "className",
    "tag21Props",
    "tag22Props",
    "tag23Props",
    "tag24Props",
    "tag25Props",
    "tag26Props",
    "tag27Props",
  ],
};
</script>

<style lang="sass" scoped>
.group-11
  align-items: flex-start
  display: flex
  gap: 10px
  height: 23px
  margin-top: 31px
  min-width: 882px
  position: relative

.group-11.group-11-1
  margin-top: 41px
</style>
