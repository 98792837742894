<template>
  <div :class="[`frame-1-1`, className || ``]">
    <img class="rectangle-69-7" :src="headView" alt="Rectangle 69" />
    <div class="name-1 plusjakartasans-bold-mine-shaft-16px">
      <router-link :to="{ path: `/s/newsDetail`, query:{ index: index }}" style="visited: {text-align:center; color:#333;}">
        {{ title }}
      </router-link>
    </div>
    <!-- <tag2 :text5="tag" className="tag-3" /> -->
    <p class="encased-in-a-lush-tr-5 heebo-normal-granite-gray-12px" v-html="content">
    </p>
  </div>
</template>

<script>
import Tag2 from "./Tag2";
export default {
  name: "Frame13",
  components: {
    Tag2,
  },
  props: [
    "title",
    "headView",
    "tag",
    "className",
    "content",
    "index",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'

.frame-1-1
  align-items: flex-start
  background-color: $white
  display: flex
  flex-direction: column
  margin-top: 20px
  min-height: 357px
  padding: 10px 0
  position: relative
  width: 355px

.rectangle-69-7
  align-self: center
  height: 199px
  object-fit: cover
  width: 335px

.name-1
  letter-spacing: 0
  line-height: normal
  margin-left: 10px
  margin-top: 19px
  min-height: 20px

.encased-in-a-lush-tr-5
  -webkit-box-orient: vertical
  -webkit-line-clamp: 1
  align-self: center
  display: -webkit-box
  letter-spacing: 0
  line-height: 18px
  margin-top: 19px
  min-height: 35px
  overflow: hidden
  text-overflow: ellipsis
  width: 335px
</style>
