<template>
  <div class="container-center-horizontal">
    <div class="news-1024 screen">
      <div class="overlap-group1-2" :style="{ 'background-image': 'url(' + overlapGroup + ')' }">
        <div class="rectangle-11-1"></div>
        <left-nav/>
        <info v-bind="globalData"/>
      </div>
      <div class="overlap-group2-2">


        <h1 class="title plusjakartasans-bold-mine-shaft-32px">{{ item.title }}</h1>
        <!-- <tag3 :text5="item.tag"/> -->
        <p class="the-gemstone-market-8 heebo-normal-mine-shaft-16px" v-html="item.content"></p>
        <!-- <img class="rectangle-74-2" :src="item.rectangle69" alt="Rectangle 74" /> -->
        <img class="rectangle-74-2" v-for="(img) in item.images" :src="img" v-bind:key="item.id"/>

        <a v-for="(lk) in item.links" :href="lk.url" v-bind:key="item.id">
          <img style="margin-top: 20px;" :src="lk.img"/>
        </a>
        
      </div>
      <foot v-bind="globalData" />
    </div>
  </div>
</template>

<script>
import LeftNav from "./components/Navbar";
import Tag3 from "./components/Tag3";
import Foot from "./components/Foot1024";
import Info from "./components/Info";
export default {
  name: "NewsDetail1024",
  components: {
    LeftNav,
    Tag3,
    Foot,
    Info,
  },
  data() {
    return {
      item: '',
    }
  },
  created() {
    let i = this.$route.query.index
    this.item = this.newsListData[i] 
  },
  props: [
    "globalData",
    "overlapGroup",
    "newsListData",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../variables'

.news-1024
  align-items: flex-start
  background-color: $selago
  display: flex
  flex-direction: column
  // height: 2161px
  overflow: hidden
  position: relative
  width: 1024px

.overlap-group1-2
  background-position: 50% 50%
  background-size: cover
  height: 300px
  position: relative
  width: 1024px

.rectangle-11-1
  -webkit-backdrop-filter: blur(30px) brightness(100%)
  backdrop-filter: blur(30px) brightness(100%)
  background-color: $black-2
  height: 300px
  left: 0
  position: absolute
  top: 0
  width: 1024px

.logo-01-5
  height: 43px
  left: 437px
  position: absolute
  top: 157px
  width: 150px

.group-29
  align-items: flex-end
  display: flex
  height: 20px
  left: 651px
  min-width: 337px
  position: absolute
  top: 29px

.vector-stroke
  height: 20px
  width: 20px

.phone-1
  letter-spacing: 0
  line-height: normal
  margin-left: 10px
  min-height: 19px
  min-width: 108px
  white-space: nowrap

.vector-12
  align-self: center
  height: 16px
  margin-left: 30px
  width: 20px

.infoalgtlabscom-4
  cursor: pointer
  letter-spacing: 0
  line-height: normal
  margin-left: 10px
  min-height: 19px
  min-width: 135px
  white-space: nowrap

.overlap-group2-2
  align-items: center
  align-self: center
  background-color: $white
  display: flex
  flex-direction: column
  margin-top: 20px
  // min-height: 1711px
  padding: 59px 0
  position: relative
  width: 944px

.title
  align-self: flex-start
  letter-spacing: 0
  line-height: normal
  margin-left: 60px
  min-height: 40px

.the-gemstone-market-8
  letter-spacing: 0
  line-height: 25.6px
  margin-top: 59px
  // min-height: 161px
  width: 824px

.rectangle-74-2
  // height: 239px
  margin-top: 41px
  object-fit: cover
  width: 824px

.the-gemstone-market-7
  letter-spacing: 0
  line-height: 25.6px
  margin-top: 39px
  min-height: 156px
  width: 824px

.antwerp-laboratory-f-3
  letter-spacing: 0
  line-height: 25.6px
  margin-top: 40px
  min-height: 77px
  width: 824px

.rectangle-75-2
  height: 343px
  margin-top: 41px
  width: 824px

.antwerp-laboratory-f-4
  letter-spacing: 0
  line-height: 25.6px
  margin-top: 40px
  min-height: 76px
  width: 824px
</style>
