<template>
  <article class="tag-1024-11">
    <div class="x029-92 plusjakartasans-bold-granite-gray-14px">{{ x029 }}</div>
    <fee2 :number="fee2Props.number" :className="fee2Props.className" />
  </article>
</template>

<script>
import Fee2 from "./Fee2";
export default {
  name: "Tag10243",
  components: {
    Fee2,
  },
  props: ["x029", "fee2Props"],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'

.tag-1024-11
  align-items: center
  background-color: $selago
  display: flex
  gap: 16px
  height: 52px
  position: relative
  width: 430px

.x029-92
  letter-spacing: 0
  line-height: normal
  margin-left: 16px
  margin-top: 7.53px
  min-height: 36px
  width: 243px
</style>
