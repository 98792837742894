<template>
  <div class="container-center-horizontal">
    <div class="pearl-report-375 screen">
      <div class="overlap-group-1" :style="{ 'background-image': 'url(' + overlapGroup + ')' }">
        <div class="rectangle-11-1"></div>
        <h1 class="premium-report-1">{{ title }}</h1>
        <img class="logo-01-1" src="img/logo-01-12.svg" alt="logo-01" />
        <img class="union" src="img/union-26.svg" alt="Union" @click="drawer = true" />
      </div>
      <p class="encased-in-a-lush-tr-1 heebo-normal-mine-shaft-12px">{{ encasedInALushTr }}</p>
      <div class="group-22-1">
        <img class="group-22-item-1" :src="rectangle" alt="1" />
        <!-- <img class="group-22-item-1" :src="x1" alt="1" /> -->
        <!-- <img class="group-22-item-1" :src="x3" alt="3" /> -->
      </div>
      <!-- <div class="premium-service-1">{{ premiumService }}</div>
      <p class="for-a-small-addition-1 heebo-normal-mine-shaft-12px">{{ forASmallAddition }}</p>
      <div class="group-23">
        <img class="rectangle-9-1" :src="rectangle93" alt="Rectangle 93" />
        <img class="rectangle-9-1" :src="rectangle95" alt="Rectangle 95" />
        <img class="rectangle-9-1" :src="rectangle94" alt="Rectangle 94" />
      </div>
      <p class="the-algt-sealing-sys-1 heebo-normal-mine-shaft-12px">{{ theAlgtSealingSys }}</p>
      <p class="algt-offers-a-3-hour-1 heebo-normal-mine-shaft-12px">{{ algtOffersA3Hour }}</p> -->
      <foot-bottom v-bind="globalData" />
      <el-drawer direction="ltr" :visible.sync="drawer" :with-header="false" size="50%">
        <left-nav />
      </el-drawer>
    </div>
  </div>
</template>

<script>
import LeftNav from "../components/Navbar";
import FootBottom from "../components/FootBottom";
export default {
  name: "PremiumReport375",
  components: {
    LeftNav,
    FootBottom,
  },
  data() {
    return {
      drawer: false,
    }
  },
  props: [
    "globalData",
    "overlapGroup",
    "title",
    "encasedInALushTr",
    "rectangle",
    "x1",
    "x3",
    "premiumService",
    "forASmallAddition",
    "rectangle93",
    "rectangle95",
    "rectangle94",
    "theAlgtSealingSys",
    "algtOffersA3Hour",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'

.pearl-report-375
  align-items: center
  background-color: $selago
  display: flex
  flex-direction: column
  height: 800px
  width: 375px

.overlap-group-1
  background-position: 50% 50%
  background-size: cover
  height: 200px
  position: relative
  width: 375px

.rectangle-11-1
  background-color: $black-2
  height: 200px
  left: 0
  position: absolute
  top: 0
  width: 375px

.premium-report-1
  color: $white
  font-family: $font-family-plus_jakarta_sans
  font-size: $font-size-xxl
  font-weight: 700
  left: 82px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 89px

.logo-01-1
  height: 26px
  left: 143px
  position: absolute
  top: 10px
  width: 90px

.union
  height: 17px
  left: 15px
  position: absolute
  top: 15px
  width: 19px

.encased-in-a-lush-tr-1
  letter-spacing: 0
  line-height: 18px
  margin-top: 19px
  min-height: 53px
  width: 335px

.group-22-1
  align-items: flex-start
  display: flex
  margin-right: 10.0px
  margin-top: 21px
  min-width: 335px

.group-22-item-1
  height: 236px
  object-fit: cover
  width: 336px

.premium-service-1
  align-self: flex-start
  color: $black
  font-family: $font-family-heebo
  font-size: $font-size-xl
  font-weight: 700
  letter-spacing: 0
  line-height: 19.2px
  margin-left: 20px
  margin-top: 29px
  white-space: nowrap

.for-a-small-addition-1
  letter-spacing: 0
  line-height: 18px
  margin-top: 10px
  min-height: 70px
  width: 335px

.group-23
  align-items: flex-start
  display: flex
  gap: 11px
  margin-top: 21px
  min-width: 335px

.rectangle-9-1
  height: 71px
  object-fit: cover
  width: 105px

.the-algt-sealing-sys-1
  letter-spacing: 0
  line-height: 18px
  margin-top: 20px
  min-height: 90px
  width: 335px

.algt-offers-a-3-hour-1
  align-self: flex-start
  letter-spacing: 0
  line-height: 18px
  margin-left: 20px
  margin-top: 20px
  white-space: nowrap

.group-16
  align-items: flex-start
  display: flex
  gap: 16px
  height: 185px
  margin-right: 41.0px
  margin-top: 41px
  min-width: 274px

.logo-02-1
  height: 42px
  width: 34px

.flex-col-3
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 0
  min-height: 185px
  width: 214px

.about-algt-1
  letter-spacing: 0
  line-height: normal
  margin-left: 15px
  min-height: 20px

.flex-col-item
  letter-spacing: 0
  line-height: normal
  margin-left: 15px
  margin-top: 20px
  min-height: 20px

.x2023-algt-all-rights-reserved-1
  letter-spacing: 0
  line-height: normal
  margin-top: 30px
  min-height: 15px

</style>
