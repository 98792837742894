<template>
  <div>
    <h1>Hello World!!!</h1>
  </div>
</template>
<script>
export default {
  name: "Test"
};
</script>
<style lang="sass" scoped>

</style>