<template>
  <div class="group-24" :style="{ 'background-image': 'url(' + footBg + ')' }">
    <div class="overlap-group">
      <img class="logo-01-3" :src="footLogo" alt="logo-01" />
      <div class="flex-row-8 heebo-normal-white-12px">
        <img class="union-3" :src="emailIcon" alt="Union" />
        <div class="text-10">{{ emailName }}</div>
        <div class="infoalgtlabscom-2">{{ email }}</div>
      </div>
      <div class="flex-row-9 heebo-normal-white-12px">
        <img class="vector-6" :src="timeIcon" alt="Vector" />
        <div class="text-9">{{ timeName }}</div>
        <p class="monday-to-friday-930am-to-1730pm-1">{{ time }}</p>
      </div>
      <div class="text-8">{{ follow }}</div>
      <div class="flex-row-10 heebo-normal-white-12px">
        <div class="vector-container-1">
          <img class="vector-6" :src="facebookIcon" alt="Vector" />
          <img class="vector-7" :src="wechatIcon" alt="Vector" />
        </div>
        <div class="flex-col-12">
          <div class="facebook">
            <a :href="facebookUrl" target="_blank" style="visited: {text-align:center; color:#fff;}">{{ facebookName }}</a>
          </div>
          <div class="wechat">
            <a href="javascript:void();" @click="centerDialogVisible = true" style="visited: {text-align:center; color:#fff;}">{{ wechatName }}</a>
          </div>
        </div>
        <div class="flex-col-13">
          <img class="vector-8" :src="instagramIcon" alt="Vector" />
          <img class="union-4" :src="redBookIcon" alt="Union" />
        </div>
        <div class="flex-col-14">
          <div class="instagram">
            <a :href="instagramUrl" target="_blank" style="visited: {text-align:center; color:#fff;}">{{ instagramName }}</a>
          </div>
          <div class="text-7">
            <a :href="redBookUrl" target="_blank" style="visited: {text-align:center; color:#fff;}">{{ redBookName }}</a>
          </div>
        </div>
      </div>
      <div class="flex-col-15">
        <div class="rectangle-106-1"></div>
        <div class="flex-row-11 plusjakartasans-bold-granite-gray-12px">
          <div class="about-algt-3">{{ aboutAlgt }}</div>
          <div class="news-library">{{ newsLibrary }}</div>
          <div class="service-4">{{ service }}</div>
          <div class="place-3">{{ place }}</div>
        </div>
        <p class="x2023-algt-all-rights-reserved-1 plusjakartasans-normal-granite-gray-12px">
          {{ allRightsReserved }}
        </p>
      </div>
    </div>
    <el-dialog title="公众号二维码" :visible.sync="centerDialogVisible" width="50%" center>
        <div style="text-align: center;"><img src="img/wx-qrcode.bmp"></div>
      </el-dialog>
  </div>
</template>

<script>
import 'element-ui/lib/theme-chalk/index.css';

export default {
  name: "Foot375",
  data() {
    return {
      centerDialogVisible: false
    };
  },
  props: [
    "phone",
    "footLogo",
    "footBg",
    "aboutAlgt",
    "ourReports",
    "newsLibrary",
    "service",
    "video",
    "place",
    "allRightsReserved",
    "email",
    "emailName",
    "emailIcon",
    "time",
    "timeName",
    "timeIcon",
    "follow",
    "facebookName",
    "facebookUrl",
    "facebookIcon",
    "instagramName",
    "instagramUrl",
    "instagramIcon",
    "wechatName",
    "wechatIcon",
    "redBookName",
    "redBookUrl",
    "redBookIcon",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'

.group-24
  align-items: flex-start
  background-position: 50% 50%
  background-size: cover
  display: flex
  height: 493px
  margin-top: 20px
  min-width: 375px

.overlap-group
  -webkit-backdrop-filter: blur(10px) brightness(100%)
  align-items: center
  backdrop-filter: blur(10px) brightness(100%)
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 45.37%, rgba(0, 0, 0, 0.82) 100%)
  display: flex
  flex-direction: column
  min-height: 493px
  padding: 41px 0
  width: 375px

.logo-01-3
  height: 35px
  margin-right: 1.0px
  margin-top: 7px
  width: 120px

.flex-row-8
  align-items: center
  align-self: flex-start
  display: flex
  height: 19px
  margin-left: 30px
  margin-top: 44px
  min-width: 195px

.union-3
  height: 16px
  margin-bottom: 0.59px
  width: 24px

.text-10
  letter-spacing: 0
  line-height: 19.2px
  margin-left: 10px
  min-width: 51px
  white-space: nowrap

.infoalgtlabscom-2
  letter-spacing: 0
  line-height: 19.2px
  margin-left: 9px
  min-width: 101px
  white-space: nowrap

.flex-row-9
  align-items: flex-start
  display: flex
  margin-right: 15.0px
  margin-top: 18px
  min-width: 300px

.vector-6
  height: 24px
  width: 24px

.text-9
  letter-spacing: 0
  line-height: 19.2px
  margin-left: 10px
  margin-top: 1px
  min-width: 51px
  white-space: nowrap

.monday-to-friday-930am-to-1730pm-1
  letter-spacing: 0
  line-height: 19.2px
  margin-left: 9px
  margin-top: 1px
  white-space: nowrap

.text-8
  align-self: flex-start
  color: $white
  font-family: $font-family-heebo
  font-size: $font-size-xl
  font-weight: 700
  letter-spacing: 0
  line-height: 25.6px
  margin-left: 30px
  margin-top: 36px
  min-height: 26px
  white-space: nowrap

.flex-row-10
  align-items: center
  display: flex
  height: 72px
  margin-right: 10.0px
  margin-top: 21px
  min-width: 305px

.vector-container-1
  align-items: flex-start
  display: flex
  flex-direction: column
  gap: 28px
  min-height: 72px
  width: 24px

.vector-7
  height: 20px
  width: 24px

.flex-col-12
  align-items: flex-start
  display: flex
  flex-direction: column
  gap: 31px
  margin-bottom: 0.54px
  margin-left: 10px
  min-height: 69px
  width: 104px

.facebook,
.wechat,
.instagram,
.text-7
  letter-spacing: 0
  line-height: 19.2px
  white-space: nowrap

.flex-col-13
  align-items: flex-start
  align-self: flex-start
  display: flex
  flex-direction: column
  gap: 32px
  margin-left: 41px
  min-height: 67px
  width: 32px

.vector-8
  height: 24px
  margin-left: 4px
  width: 24px

.union-4
  height: 11px
  width: 32px

.flex-col-14
  align-items: flex-start
  display: flex
  flex-direction: column
  gap: 31px
  margin-bottom: 0.54px
  margin-left: 10px
  min-height: 69px
  width: 84px

.flex-col-15
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 29px
  min-height: 80px
  width: 335px

.rectangle-106-1
  background-color: $mine-shaft
  height: 1px
  width: 335px

.flex-row-11
  align-items: flex-start
  display: flex
  gap: 10px
  height: 15px
  margin-top: 29px
  min-width: 309px

.about-algt-3
  letter-spacing: 0
  line-height: normal
  min-height: 15px
  min-width: 76px

.news-library
  letter-spacing: 0
  line-height: normal
  min-height: 15px
  min-width: 93px

.service-4
  letter-spacing: 0
  line-height: normal
  min-height: 15px
  min-width: 51px

.place-3
  letter-spacing: 0
  line-height: normal
  min-height: 15px

.x2023-algt-all-rights-reserved-1
  letter-spacing: 0
  line-height: normal
  margin-top: 20px
  min-height: 15px
</style>
