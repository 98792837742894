<template>
  <div class="container-center-horizontal">
    <div class="reg-1920 screen">
      <div class="overlap-group3-3">
        <img class="rectangle-144" :src="rectangle21" alt="Rectangle 144" />
        <div class="rectangle-11-3"></div>
        <left-nav />
        <info v-bind="globalData" />

      </div>
      <div class="overlap-group2-3">
        <div class="logo-container" :style="{ 'background-image': 'url(' + logoContainer + ')' }">
          <img class="logo-02" src="img/logo-02.svg" alt="logo-02" />
          <img class="logo" src="img/logo.svg" alt="Logo" />
        </div>
        <div class="flex-col-42">
          <h1 class="title-3">{{ title }}</h1>
          <p class="please-send-your-id heebo-bold-black-16px" v-html="pleaseSendYourId"></p>
          <div class="flex-row-36">
            <div class="flex-col-43 heebo-normal-black-16px">
              <div class="company-name">{{ companyName }}</div>
              <div class="flex-col-item-4">{{ contactPerson }}</div>
              <div class="flex-col-item-4">{{ telephone }}</div>
              <div class="flex-col-item-4">{{ mobilePhone }}</div>
              <div class="flex-col-item-4">{{ email }}</div>
              <div class="flex-col-item-4">{{ address }}</div>
              <div class="city">{{ city }}</div>
              <div class="flex-col-item-4">{{ country }}</div>
              <div class="flex-col-item-4">{{ vatNumber }}</div>
              <div class="flex-col-item-4">{{ idPassportCopy }}</div>
            </div>
            <div class="flex-col-44">
              <div class="rectangle-131"></div>
              <div class="rectangle-1"></div>
              <div class="rectangle-1"></div>
              <div class="rectangle-1"></div>
              <div class="rectangle-1"></div>
              <div class="rectangle-139"></div>
              <div class="rectangle-1"></div>
              <div class="rectangle-1"></div>
              <div class="rectangle-1"></div>
              <div class="rectangle-1"></div>
              <p class="i-have-read-and-agre heebo-normal-black-16px">
                <span class="heebo-normal-black-16px">{{ spanText1 }}</span
                ><span class="heebo-bold-black-16px">{{ spanText2 }}</span
                ><span class="heebo-normal-black-16px">{{ spanText3 }}</span
                ><span class="heebo-bold-black-16px">{{ spanText4 }}</span
                ><span class="heebo-normal-black-16px">{{ spanText5 }}</span>
              </p>
              <div class="client-signature heebo-normal-black-16px">{{ clientSignature }}</div>
              <div class="overlap-group1-6">
                <div class="registration-form-download heebo-bold-white-24px">
                  <a :href="globalData.customerRegistrationFormPdf" target="_blank" style="visited: {text-align:center; color:#fff;}">
                  {{ registrationFormDownload }}
                </a>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <foot v-bind="globalData" />
    </div>
  </div>
</template>

<script>
import LeftNav from "./components/Navbar";
import Foot from "./components/Foot1920";
import Info from "./components/Info";
export default {
  name: "Reg1920",
  components: {
    LeftNav,
    Foot,
    Info,
  },
  props: [
    "globalData",
    "rectangle21",
    "phone",
    "infoAlgtlabsCom",
    "logoContainer",
    "title",
    "pleaseSendYourId",
    "companyName",
    "contactPerson",
    "telephone",
    "mobilePhone",
    "email",
    "address",
    "city",
    "country",
    "vatNumber",
    "idPassportCopy",
    "spanText1",
    "spanText2",
    "spanText3",
    "spanText4",
    "spanText5",
    "clientSignature",
    "registrationFormDownload",
    "footProps",
  ],
};
</script>

<style lang="sass">
@import '../../variables'

.reg-1920
  align-items: flex-start
  background-color: $selago
  display: flex
  flex-direction: column
  height: 2201px
  overflow: hidden
  position: relative
  width: 1920px

.overlap-group3-3
  height: 400px
  position: relative
  width: 2000px

.rectangle-144
  height: 400px
  left: 0
  object-fit: cover
  position: absolute
  top: 0
  width: 1920px

.rectangle-11-3
  background-color: $black-2
  height: 400px
  left: 0
  position: absolute
  top: 0
  width: 1920px

.logo-01-9
  height: 87px
  left: 810px
  position: absolute
  top: 157px
  width: 300px

.group-30-1
  align-items: flex-end
  display: flex
  height: 20px
  left: 1507px
  min-width: 337px
  position: absolute
  top: 49px

.vector-stroke-2
  height: 20px
  width: 20px

.phone-2
  letter-spacing: 0
  line-height: normal
  margin-left: 10px
  min-height: 19px
  min-width: 108px
  white-space: nowrap

.vector-24
  align-self: center
  height: 16px
  margin-left: 30px
  width: 20px

.infoalgtlabscom-8
  cursor: pointer
  letter-spacing: 0
  line-height: normal
  margin-left: 10px
  min-height: 19px
  min-width: 135px
  white-space: nowrap

.overlap-group2-3
  align-items: center
  align-self: center
  background-color: $white
  display: flex
  gap: 100px
  height: 1190px
  margin-top: 60px
  min-width: 1600px
  padding: 0 1px

.logo-container
  align-items: center
  background-size: 100% 100%
  display: flex
  flex-direction: column
  gap: 20px
  min-height: 1190px
  padding: 339px 0
  width: 420px

.logo-02
  height: 147px
  width: 120px

.logo
  height: 124px
  width: 280px

.flex-col-42
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 17.0px
  min-height: 1029px
  width: 872px

.title-3
  color: $black
  font-family: $font-family-plus_jakarta_sans
  font-size: $font-size-xxxxxl
  font-weight: 700
  letter-spacing: 0
  line-height: normal
  min-height: 60px

.please-send-your-id
  letter-spacing: 0
  line-height: 25.6px
  margin-top: 30px
  min-height: 52px
  width: 872px

.flex-row-36
  align-items: flex-start
  display: flex
  gap: 15px
  height: 837px
  margin-top: 50px
  min-width: 862px

.flex-col-43
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 11px
  min-height: 636px
  width: 128px

.company-name
  letter-spacing: 0
  line-height: 16px
  white-space: nowrap

.flex-col-item-4
  letter-spacing: 0
  line-height: 16px
  margin-top: 44px
  white-space: nowrap

.city
  letter-spacing: 0
  line-height: 16px
  margin-top: 124px
  white-space: nowrap

.flex-col-44
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 837px
  width: 719px

.rectangle-131
  background-color: $white
  border: 1px solid
  border-color: $alto
  border-radius: 4px
  box-shadow: inset 2px 2px 2px #0000001a
  height: 40px
  width: 640px

.rectangle-1
  background-color: $white
  border: 1px solid
  border-color: $alto
  border-radius: 4px
  box-shadow: inset 2px 2px 2px #0000001a
  height: 40px
  margin-top: 20px
  width: 640px

.rectangle-139
  background-color: $white
  border: 1px solid
  border-color: $alto
  border-radius: 4px
  box-shadow: inset 2px 2px 2px #0000001a
  height: 120px
  margin-top: 20px
  width: 640px

.i-have-read-and-agre
  letter-spacing: 0
  line-height: 16px
  margin-top: 29px
  white-space: nowrap

.client-signature
  letter-spacing: 0
  line-height: 16px
  margin-top: 20px
  white-space: nowrap

.overlap-group1-6
  align-items: center
  background-color: $brand
  border-radius: 4px
  display: flex
  height: 64px
  margin-top: 32px
  min-width: 437px
  padding: 0 30px

.registration-form-download
  letter-spacing: 0
  line-height: 38.4px
  min-width: 377px
  white-space: nowrap
</style>
