<template>
  <div class="foot-1024" :style="{ 'background-image': 'url(' + footBg + ')' }">
    <div class="flex-col-10">
      <div class="flex-row-12">
        <div class="flex-col-11">
          <img class="logo-01-5" :src="footLogo" alt="logo-01" />
          <div class="flex-row-13 heebo-normal-white-16px">
            <div class="flex-col-12">
              <img class="union-5" :src="emailIcon" alt="Union" />
              <img class="vector-10" :src="timeIcon" alt="Vector" />
            </div>
            <div class="text-container">
              <div class="text-12">{{ emailName }}</div>
              <div class="text-11">{{ timeName }}</div>
            </div>
            <div class="flex-col-13">
              <div class="infoalgtlabscom-4">{{ email }}</div>
              <p class="monday-to-friday-930am-to-1730pm-2">{{ time }}</p>
            </div>
          </div>
        </div>
        <div class="flex-col-14">
          <div class="text-10">{{ follow }}</div>
          <div class="flex-row-14">
            <div class="vector-container-2">
              <img class="vector-10" :src="facebookIcon" alt="Vector" />
              <img class="vector-10" :src="instagramIcon" alt="Vector" />
            </div>
            <div class="flex-col-15 heebo-normal-white-16px">
              <div class="facebook-1">
                <a :href="facebookUrl" target="_blank" style="visited: {text-align:center; color:#fff;}">{{ facebookName }}</a>
              </div>
              <div class="instagram-1">
                <a :href="instagramUrl" target="_blank" style="visited: {text-align:center; color:#fff;}">{{ instagramName }}</a>
              </div>
            </div>
          </div>
          <div class="flex-row-15">
            <img class="vector-11" :src="wechatIcon" alt="Vector" />
            <div class="wechat-1 heebo-normal-white-16px">
              <a href="javascript:void();" @click="centerDialogVisible = true" style="visited: {text-align:center; color:#fff;}">{{ wechatName }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-col-16">
        <div class="flex-row-16">
          <img class="union-6" :src="redBookIcon" alt="Union" />
          <div class="text-9 heebo-normal-white-16px">
            <a :href="redBookUrl" target="_blank" style="visited: {text-align:center; color:#fff;}">{{ redBookName }}</a>
          </div>
        </div>
        <div class="rectangle-106-2"></div>
        <div class="flex-row-17">
          <div class="group-27 plusjakartasans-bold-granite-gray-12px">
            <div class="about-algt-2">{{ aboutAlgt }}</div>
            <div class="our-reports-1">{{ ourReports }}</div>
            <div class="service-2">{{ service }}</div>
            <div class="video-1">{{ video }}</div>
            <div class="place-2">{{ place }}</div>
          </div>
          <p class="x2023-algt-all-rights-reserved-2 plusjakartasans-normal-granite-gray-12px">
            {{ allRightsReserved }}
          </p>
        </div>
      </div>
    </div>
    <el-dialog title="公众号二维码" :visible.sync="centerDialogVisible" width="50%" center>
        <div style="text-align: center;"><img src="img/wx-qrcode.bmp"></div>
      </el-dialog>
  </div>
</template>

<script>
import 'element-ui/lib/theme-chalk/index.css';

export default {
  name: "Foot1024",
  data() {
    return {
      centerDialogVisible: false
    };
  },
  props: [
    "phone",
    "footLogo",
    "footBg",
    "aboutAlgt",
    "ourReports",
    "newsLibrary",
    "service",
    "video",
    "place",
    "allRightsReserved",
    "email",
    "emailName",
    "emailIcon",
    "time",
    "timeName",
    "timeIcon",
    "follow",
    "facebookName",
    "facebookUrl",
    "facebookIcon",
    "instagramName",
    "instagramUrl",
    "instagramIcon",
    "wechatName",
    "wechatIcon",
    "redBookName",
    "redBookUrl",
    "redBookIcon",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'

.foot-1024
  align-items: flex-start
  background-position: 50% 50%
  background-size: cover
  display: flex
  height: 376px
  margin-top: 40px
  min-width: 1024px

.flex-col-10
  -webkit-backdrop-filter: blur(10px) brightness(100%)
  align-items: center
  backdrop-filter: blur(10px) brightness(100%)
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 45.37%, rgba(0, 0, 0, 0.82) 100%)
  display: flex
  flex-direction: column
  gap: 16px
  min-height: 376px
  padding: 20px 0
  width: 1024px

.flex-row-12
  align-items: flex-start
  display: flex
  gap: 327px
  height: 178px
  margin-right: 37.0px
  margin-top: 19px
  min-width: 907px

.flex-col-11
  align-items: flex-start
  display: flex
  flex-direction: column
  gap: 40px
  margin-top: 1px
  min-height: 156px
  width: 397px

.logo-01-5
  height: 43px
  width: 150px

.flex-row-13
  align-items: flex-start
  display: flex
  gap: 15px
  height: 73px
  min-width: 397px

.flex-col-12
  align-items: flex-start
  align-self: flex-end
  display: flex
  flex-direction: column
  gap: 27px
  min-height: 67px
  width: 24px

.union-5
  height: 16px
  width: 24px

.vector-10
  height: 24px
  width: 24px

.text-container
  align-items: flex-start
  display: flex
  flex-direction: column
  gap: 20px
  min-height: 72px
  // width: 68px

.text-12,
.text-11,
.infoalgtlabscom-4,
.monday-to-friday-930am-to-1730pm-2,
.facebook-1,
.instagram-1
  letter-spacing: 0
  line-height: 25.6px
  min-height: 26px
  white-space: nowrap

.flex-col-13
  align-items: flex-start
  display: flex
  flex-direction: column
  gap: 20px
  min-height: 72px
  width: 275px

.flex-col-14
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 178px
  width: 183px

.text-10
  color: $white
  font-family: $font-family-heebo
  font-size: $font-size-xxl
  font-weight: 700
  letter-spacing: 0
  line-height: 38.4px
  white-space: nowrap

.flex-row-14
  align-items: flex-end
  display: flex
  gap: 17px
  height: 68px
  margin-left: 4px
  margin-top: 32px
  min-width: 114px

.vector-container-2
  align-items: flex-start
  display: flex
  flex-direction: column
  gap: 18px
  min-height: 66px
  width: 24px

.flex-col-15
  align-items: flex-start
  display: flex
  flex-direction: column
  gap: 16px
  min-height: 68px
  width: 73px

.flex-row-15
  align-items: center
  display: flex
  gap: 17px
  height: 26px
  margin-left: 4px
  margin-top: 14px
  min-width: 179px

.vector-11
  height: 20px
  margin-top: 1.54px
  width: 24px

.wechat-1
  letter-spacing: 0
  line-height: 25.6px
  min-height: 26px
  min-width: 138px
  white-space: nowrap

.flex-col-16
  align-items: flex-end
  display: flex
  flex-direction: column
  min-height: 123px
  width: 960px

.flex-row-16
  align-items: center
  display: flex
  gap: 13px
  height: 26px
  margin-right: 71px
  min-width: 157px

.union-6
  height: 11px
  margin-top: 1.33px
  width: 32px

.text-9
  letter-spacing: 0
  line-height: 25.6px
  min-height: 26px
  min-width: 112px
  white-space: nowrap

.rectangle-106-2
  background-color: $mine-shaft
  height: 1px
  margin-top: 61px
  width: 960px

.flex-row-17
  align-items: flex-start
  display: flex
  gap: 338px
  height: 16px
  margin-top: 19px
  min-width: 952px

.group-27
  align-items: flex-start
  align-self: flex-end
  display: flex
  gap: 20px
  height: 15px
  min-width: 400px

.about-algt-2
  letter-spacing: 0
  line-height: normal
  margin-top: 0
  min-height: 15px
  min-width: 76px

.our-reports-1
  letter-spacing: 0
  line-height: normal
  min-height: 15px
  min-width: 85px

.service-2
  letter-spacing: 0
  line-height: normal
  min-height: 15px
  min-width: 51px

.video-1
  letter-spacing: 0
  line-height: normal
  min-height: 15px
  min-width: 39px

.place-2,
.x2023-algt-all-rights-reserved-2
  letter-spacing: 0
  line-height: normal
  min-height: 15px
</style>
