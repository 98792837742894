<template>
  <div class="container-center-horizontal">
    <div class="report-check-1920 screen">
      <div class="flex-col">
        <div class="overlap-group1" :style="{ 'background-image': 'url(' + overlapGroup + ')' }">
          <div class="rectangle-11"></div>
          <left-nav />
          <info v-bind="globalData" />
        </div>
        <h1 class="premium-report">{{ title }}</h1>
        <p class="encased-in-a-lush-tr heebo-normal-mine-shaft-16px">{{ encasedInALushTr }}</p>
      </div>
      <div class="flex-row">
        <div class="group-22">
          <img class="group-22-item" :src="rectangle" alt="1" />
          <!-- <img class="group-22-item" :src="x1" alt="1" /> -->
          <!-- <img class="group-22-item" :src="x3" alt="3" /> -->
        </div>
        <!-- <div class="flex-col-1 heebo-normal-mine-shaft-16px">
          <div class="flex-col-2">
            <div class="premium-service heebo-bold-black-20px">{{ premiumService }}</div>
            <p class="for-a-small-addition heebo-normal-mine-shaft-16px">{{ forASmallAddition }}</p>
          </div>
          <div class="flex-row-1">
            <img class="rectangle-9" :src="rectangle93" alt="Rectangle 93" /><img class="rectangle-9" :src="rectangle95"
              alt="Rectangle 95" /><img class="rectangle-9" :src="rectangle94" alt="Rectangle 94" />
          </div>
          <p class="the-algt-sealing-sys">{{ theAlgtSealingSys }}</p>
          <p class="algt-offers-a-3-hour">{{ algtOffersA3Hour }}</p>
        </div> -->
      </div>
      <div class="group-2">
        <footBottom v-bind="globalData"/>
      </div>
    </div>
  </div>
</template>

<script>
import LeftNav from "../components/Navbar";
import Info from "../components/Info";
import FootBottom from "../components/FootBottom";
export default {
  name: "PremiumReport1920",
  components: {
    LeftNav,
    Info,
    FootBottom,
  },
  props: [
    "globalData",
    "overlapGroup",
    "title",
    "premiumReport",
    "encasedInALushTr",
    "rectangle",
    "x1",
    "x3",
    "premiumService",
    "forASmallAddition",
    "rectangle93",
    "rectangle95",
    "rectangle94",
    "theAlgtSealingSys",
    "algtOffersA3Hour",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'

.report-check-1920
  align-items: flex-start
  background-color: $selago
  display: flex
  flex-direction: column
  gap: 60px
  height: 1719px
  overflow: hidden
  width: 1920px

.flex-col
  align-items: center
  display: flex
  flex-direction: column
  min-height: 598px
  width: 1920px

.overlap-group1
  background-position: 50% 50%
  background-size: cover
  height: 400px
  position: relative
  width: 1920px

.rectangle-11
  background-color: $black-2
  height: 400px
  left: 0
  position: absolute
  top: 0
  width: 1920px

.place
  left: 80px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.about-us
  left: 156px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.services
  left: 261px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.video
  left: 363px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.place-1
  left: 439px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.phone
  left: 1537px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.infoalgtlabscom
  cursor: pointer
  left: 1705px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.rectangle-12
  border: 1px solid
  border-color: $white
  border-radius: 56px
  height: 24px
  left: 1323px
  position: absolute
  top: 47px
  width: 160px

.report-number
  color: $white
  font-family: $font-family-roboto
  font-size: $font-size-m
  font-weight: 400
  left: 1336px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 51px
  white-space: nowrap

.logo-01
  height: 87px
  left: 810px
  position: absolute
  top: 157px
  width: 300px

.vector
  height: 20px
  left: 1507px
  position: absolute
  top: 49px
  width: 20px

.vector-1
  height: 16px
  left: 1675px
  position: absolute
  top: 51px
  width: 20px

.premium-report
  color: $mine-shaft
  font-family: $font-family-plus_jakarta_sans
  font-size: $font-size-xxxxxl
  font-weight: 700
  letter-spacing: 0
  line-height: normal
  margin-top: 59px
  min-height: 60px
  min-width: 424px

.encased-in-a-lush-tr
  letter-spacing: 0
  line-height: 19.2px
  margin-top: 60px
  white-space: nowrap
  width: 1560px

.flex-row
  align-items: flex-start
  align-self: center
  display: flex
  gap: 80px
  height: 587px
  margin-left: 200px
  min-width: 1540px

.group-22
  align-items: flex-start
  // align-self: flex-end
  display: flex
  min-width: 832px

.group-22-item
  height: 900px
  object-fit: cover
  width: 1277px

.flex-col-1
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 372px
  width: 628px

.flex-col-2
  align-items: flex-start
  display: flex
  flex-direction: column
  gap: 20px
  min-height: 100px
  width: 628px

.premium-service
  letter-spacing: 0
  line-height: 24.0px
  white-space: nowrap

.for-a-small-addition
  letter-spacing: 0
  line-height: 19.2px
  min-height: 56px
  width: 628px

.flex-row-1
  align-items: flex-start
  display: flex
  gap: 20px
  margin-top: 21px
  min-width: 628px

.rectangle-9
  height: 134px
  object-fit: cover
  width: 196px

.the-algt-sealing-sys
  letter-spacing: 0
  line-height: 19.2px
  margin-top: 19px
  min-height: 56px
  width: 628px

.algt-offers-a-3-hour
  letter-spacing: 0
  line-height: 19.2px
  margin-top: 20px
  min-height: 22px
  white-space: nowrap

.group-2
  align-items: flex-start
  display: flex
  min-width: 1932px
  margin-top: auto

.overlap-group
  align-items: center
  background-color: $selago
  display: flex
  height: 100px
  justify-content: flex-end
  min-width: 1920px
  padding: 28px 158px

.logo-02
  align-self: flex-end
  height: 42px
  width: 34px

.about-algt
  letter-spacing: 0
  line-height: normal
  margin-bottom: 0
  margin-left: 61px
  min-height: 20px
  min-width: 101px

.our-reports
  letter-spacing: 0
  line-height: normal
  margin-left: 30px
  min-height: 20px
  min-width: 113px

.service
  letter-spacing: 0
  line-height: normal
  margin-left: 30px
  min-height: 20px
  min-width: 68px

.video-1
  letter-spacing: 0
  line-height: normal
  margin-left: 30px
  min-height: 20px
  min-width: 52px

.place-2
  letter-spacing: 0
  line-height: normal
  margin-left: 30px
  min-height: 20px
  min-width: 79px

.x2023-algt-all-rights-reserved
  letter-spacing: 0
  line-height: normal
  margin-left: 759px
  margin-top: 1.0px
  min-height: 15px
  min-width: 214px
</style>
