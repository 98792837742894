<template>
  <article class="tag"><div class="text_label plusjakartasans-normal-white-12px">全部</div></article>
</template>

<script>
export default {
  name: "Tag",
};
</script>

<style lang="sass" scoped>
@import '../../../variables'

.tag,
.tag-1
  align-items: center
  background-color: $brand
  border: 1px solid
  border-radius: 19px
  display: inline-flex
  justify-content: center
  padding: 4px 8px
  position: relative

.text_label,
.text_label-1
  letter-spacing: 0
  line-height: normal
  margin-top: -1.00px
  position: relative
  width: fit-content
</style>
