<template>
  <div class="navbar roboto-normal-white-14px navbar-1">
    <div class="navbar-link">
      <router-link to="/m/home" style="visited: {text-align:center; color:#fff;}">HOME</router-link>
    </div>
    <div class="navbar-link">
      <router-link to="/m/about" style="visited: {text-align:center; color:#fff;}">ABOUT US</router-link>
    </div>
    <div class="navbar-link" >
      <router-link to="/m/news" style="visited: {text-align:center; color:#fff;}">NEWS &amp; LIBRARY</router-link>
    </div>
    <div class="navbar-link" >
      <router-link to="/m/service" style="visited: {text-align:center; color:#fff;}">SERVICE</router-link>
    </div>
    <div class="navbar-link" >
      <router-link to="/m/price" style="visited: {text-align:center; color:#fff;}">PRICE</router-link>
    </div>
    <div class="navbar-link" >
      <router-link to="/m/reg" style="visited: {text-align:center; color:#fff;}">REGISTRATION</router-link>
    </div>
    <div class="navbar-link" >
      <router-link to="/m/contact" style="visited: {text-align:center; color:#fff;}">CONTACT</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeftNav",
  props: ["className"],
};
</script>

<style lang="sass" scoped>
.navbar
  align-items: center
  display: inline-flex
  gap: 20px 14px
  left: 40px
  position: absolute
  top: 30px

.navbar-link
  letter-spacing: 0
  line-height: normal
  // margin-top: -1.00px
  position: relative
  white-space: nowrap
  width: fit-content
  cursor: pointer

.navbar.navbar-1
  left: 40px
  top: 30px
</style>
