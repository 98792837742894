<template>
  <div class="container-center-horizontal">
    <div class="newsu38library-1024 screen">
      <div class="overlap-group1-2" :style="{ 'background-image': 'url(' + overlapGroup + ')' }">
        <div class="rectangle-11-1"></div>
        <left-nav />
        <info v-bind="globalData" />
      </div>
      <h1 class="title-2">{{ title }}</h1>
      <!-- <group11 v-bind="typesData" /> -->
      <div class="group-11">
          <el-input v-model="keyword" placeholder="Enter Keyword" suffix-icon="el-icon-search" @keyup.enter.native="search"></el-input>
      </div>
      <div class="frame-container-4">
        <frame12 v-for="(item,index) in filterData" v-bind="item" v-bind:key="item.id" :index="index"/>
      </div>
      <foot1024 v-bind="globalData" />
    </div>
  </div>
</template>

<script>
import LeftNav from "./components/Navbar";
import Group11 from "./components/Group11";
import Tag2 from "./components/Tag2";
import Frame12 from "./components/Frame";
import Foot1024 from "./components/Foot1024";
import Info from "./components/Info";
import 'element-ui/lib/theme-chalk/index.css';

export default {
  name: "News1024",
  components: {
    LeftNav,
    Group11,
    Tag2,
    Frame12,
    Foot1024,
    Info,
  },
  data() {
    return {
      keyword: "",
      filterData: [],
    }
  },
  created() {
    this.filterData = this.newsListData
  },
  methods: {
    search() {
      console.log(this.keyword)
      this.filterData = this.newsListData.filter(item => {
        return item.content.toLowerCase().includes(this.keyword.toLowerCase());
      });
    },
  },
  props: [
    "overlapGroup",
    "title",
    "typesData",
    "globalData",
    "newsListData",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../variables'
.el-input
  width: 280px
.group-11
  align-items: flex-start
  display: flex
  gap: 10px
  height: 23px
  margin-top: 31px
  min-width: 882px
  position: relative
  left: 300px

.newsu38library-1024
  align-items: center
  background-color: $selago
  display: flex
  flex-direction: column
  // height: 2400px
  position: relative
  width: 1024px

.overlap-group1-2
  background-position: 50% 50%
  background-size: cover
  height: 300px
  position: relative
  width: 1024px

.rectangle-11-1
  background-color: $black-2
  height: 300px
  left: 0
  // position: absolute
  top: 0
  width: 1024px

.logo-01-4
  height: 43px
  left: 437px
  position: absolute
  top: 140px
  width: 150px

.group-29
  align-items: flex-end
  display: flex
  height: 20px
  left: 651px
  min-width: 337px
  position: absolute
  top: 29px

.vector-stroke
  height: 20px
  width: 20px

.phone-1
  letter-spacing: 0
  line-height: normal
  margin-left: 10px
  min-height: 19px
  min-width: 108px
  white-space: nowrap

.vector-9
  align-self: center
  height: 16px
  margin-left: 30px
  width: 20px

.infoalgtlabscom-3
  cursor: pointer
  letter-spacing: 0
  line-height: normal
  margin-left: 10px
  min-height: 19px
  min-width: 135px
  white-space: nowrap

.title-2
  color: $mine-shaft
  font-family: $font-family-plus_jakarta_sans
  font-size: $font-size-xxxl
  font-weight: 700
  letter-spacing: 0
  line-height: normal
  margin-top: 39px
  min-height: 40px
  min-width: 246px

.frame-container-4
  align-content: flex-start
  display: flex
  gap: 40px
  height: auto
  margin-top: 40px
  max-width: 944px
  position: relative
  flex-wrap: wrap

.frame-6
  align-items: flex-start
  background-color: $white
  display: flex
  flex-direction: column
  min-height: 487px
  padding: 20px 0
  position: relative
  width: 452px

.rectangle-69-8
  align-self: center
  height: 264px
  position: relative
  width: 412px

.text-21
  letter-spacing: 0
  line-height: normal
  margin-left: 20px
  margin-top: 19px
  min-height: 30px
  min-width: 168px
  position: relative

.encased-in-a-lush-tr-6
  -webkit-box-orient: vertical
  -webkit-line-clamp: 4
  align-self: center
  display: -webkit-box
  letter-spacing: 0
  line-height: 19.2px
  margin-top: 19px
  min-height: 80px
  overflow: hidden
  position: relative
  text-overflow: ellipsis
  width: 412px

.frame-container-3
  align-items: flex-start
  display: flex
  gap: 40px
  height: 487px
  margin-top: 20px
  min-width: 944px
  position: relative
</style>
