import { render, staticRenderFns } from "./Test.vue?vue&type=template&id=ad7dd412&scoped=true"
import script from "./Test.vue?vue&type=script&lang=js"
export * from "./Test.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad7dd412",
  null
  
)

export default component.exports