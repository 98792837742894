<template>
  <div class="container-center-horizontal">
    <div class="news-1024 screen">
      <div class="overlap-group1-2" :style="{ 'background-image': 'url(' + overlapGroup + ')' }">
        <div class="rectangle-11-1"></div>
        <left-nav />
        <info v-bind="globalData" />
      </div>
      <div class="overlap-group2-2">

        <h1 class="title plusjakartasans-bold-mine-shaft-32px">{{ title1 }}</h1>
        <p class="the-gemstone-market-8 heebo-normal-mine-shaft-16px" v-html="content1"></p>
        <br><br>
        <h1 class="title plusjakartasans-bold-mine-shaft-32px">{{ title2 }}</h1>
        <p class="the-gemstone-market-8 heebo-normal-mine-shaft-16px" v-html="content2"></p>

        <table style="width: 85%;margin: 20px;">
          <tr>
            <td>{{ table.tr1.td1 }}</td>
            <td>{{ table.tr1.td2 }}</td>
            <td>{{ table.tr1.td3 }}</td>
            <td colspan="3">{{ table.tr1.td4 }}</td>
          </tr>
          <tr>
            <td>{{ table.tr2.td1 }}</td>
            <td>{{ table.tr2.td2 }}</td>
            <td>{{ table.tr2.td3 }}</td>
            <td>{{ table.tr2.td4 }}</td>
            <td>{{ table.tr2.td5 }}</td>
            <td>{{ table.tr2.td6 }}</td>
          </tr>
          <tr>
            <td>{{ table.tr3.td1 }}</td>
            <td>{{ table.tr3.td2 }}</td>
            <td>{{ table.tr3.td3 }}</td>
            <td>{{ table.tr3.td4 }}</td>
            <td>{{ table.tr3.td5 }}</td>
            <td>{{ table.tr3.td6 }}</td>
          </tr>
        </table>
        <p class="the-gemstone-market-8 heebo-normal-mine-shaft-16px" v-html="content3"></p>
      </div>
      <foot v-bind="globalData" />
    </div>
  </div>
</template>

<script>
import LeftNav from "./components/Navbar";
import Tag3 from "./components/Tag3";
import Foot from "./components/Foot1024";
import Info from "./components/Info";


export default {
  name: "Terms1024",
  components: {
    LeftNav,
    Tag3,
    Foot,
    Info,
  },
  props: [
    "globalData",
    "overlapGroup",
    "title1",
    "content1",
    "title2",
    "content2",
    "table",
    "content3",
  ],
};
</script>
<style lang="css" scoped>
table {
  border-collapse: collapse;
}

table td {
  border-bottom: 1px solid #555;
  border-right: 1px solid #555;
  padding: 10px;
  text-align: center;
  vertical-align: middle;
  color: #333;
  font-size: small;
}

table tr:first-child td {
  border-top: 1px solid #555;
}

table tr td:first-child {
  border-left: 1px solid #555;
}
</style>
<style lang="sass" scoped>
@import '../../variables'

.news-1024
  align-items: flex-start
  background-color: $selago
  display: flex
  flex-direction: column
  // height: 2161px
  overflow: hidden
  position: relative
  width: 1024px

.overlap-group1-2
  background-position: 50% 50%
  background-size: cover
  height: 300px
  position: relative
  width: 1024px

.rectangle-11-1
  -webkit-backdrop-filter: blur(30px) brightness(100%)
  backdrop-filter: blur(30px) brightness(100%)
  background-color: $black-2
  height: 300px
  left: 0
  position: absolute
  top: 0
  width: 1024px

.logo-01-5
  height: 43px
  left: 437px
  position: absolute
  top: 157px
  width: 150px

.group-29
  align-items: flex-end
  display: flex
  height: 20px
  left: 651px
  min-width: 337px
  position: absolute
  top: 29px

.vector-stroke
  height: 20px
  width: 20px

.phone-1
  letter-spacing: 0
  line-height: normal
  margin-left: 10px
  min-height: 19px
  min-width: 108px
  white-space: nowrap

.vector-12
  align-self: center
  height: 16px
  margin-left: 30px
  width: 20px

.infoalgtlabscom-4
  cursor: pointer
  letter-spacing: 0
  line-height: normal
  margin-left: 10px
  min-height: 19px
  min-width: 135px
  white-space: nowrap

.overlap-group2-2
  align-items: center
  align-self: center
  background-color: $white
  display: flex
  flex-direction: column
  margin-top: 20px
  // min-height: 1711px
  padding: 59px 0
  position: relative
  width: 944px

.title
  align-self: flex-start
  letter-spacing: 0
  line-height: normal
  margin-left: 60px
  min-height: 40px

.the-gemstone-market-8
  letter-spacing: 0
  line-height: 25.6px
  margin-top: 59px
  // min-height: 161px
  width: 824px

.rectangle-74-2
  // height: 239px
  margin-top: 41px
  object-fit: cover
  width: 824px

.the-gemstone-market-7
  letter-spacing: 0
  line-height: 25.6px
  margin-top: 39px
  min-height: 156px
  width: 824px

.antwerp-laboratory-f-3
  letter-spacing: 0
  line-height: 25.6px
  margin-top: 40px
  min-height: 77px
  width: 824px

.rectangle-75-2
  height: 343px
  margin-top: 41px
  width: 824px

.antwerp-laboratory-f-4
  letter-spacing: 0
  line-height: 25.6px
  margin-top: 40px
  min-height: 76px
  width: 824px
</style>
