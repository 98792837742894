<template>
  <div class="container-center-horizontal">
    <div class="contuct-us-1920 screen">
      <div class="overlap-group3">
        <img class="rectangle-21" :src="overlapGroup" alt="Rectangle 21" />
        <div class="rectangle-11"></div>
        <left-nav />
        <info v-bind="globalData" />
      </div>
      <h1 class="place plusjakartasans-bold-mine-shaft-48px">{{ contact }}</h1>
      <div class="overlap-group2">
        <img class="rectangle-127" :src="doorPic1" alt="Rectangle 127" />
        <div class="flex-col">
          <div class="european-head-office-address heebo-bold-mine-shaft-32px">{{ addressName1 }}</div>
          <p class="belgium-antwerp-dia heebo-normal-mine-shaft-24px">{{ address1 }}</p>
          <div class="telephone-32-3-5350888 heebo-normal-mine-shaft-24px">{{ telephone1 }}</div>
        </div>
      </div>
      <div class="overlap-group4">
        <div class="overlap-group1">
          <div class="rectangle-101"></div>
          <div class="business-hours heebo-normal-mine-shaft-24px">{{ globalData.timeName }}</div>
          <div class="mail heebo-normal-mine-shaft-24px">{{ globalData.emailName }}</div>
          <img class="union" :src="globalData.emailIcon" alt="Union" />
          <img class="vector-2" src="img/vector-24.svg" alt="Vector" />
          <img class="vector-3" src="img/vector-25.svg" alt="Vector" />
          <p class="monday-to-friday-930am-to-1730pm heebo-normal-mine-shaft-24px">{{ globalData.time }}</p>
          <div class="infoalgtlabscom-1 heebo-normal-mine-shaft-24px">{{ globalData.email }}</div>
          <div class="facebook heebo-normal-mine-shaft-24px">
            <a :href="globalData.facebookUrl" target="_blank" style="visited: {text-align:center; color:#000;}">{{ globalData.facebookName }}</a>
          </div>
          <div class="instagram heebo-normal-mine-shaft-24px">
            <a :href="globalData.instagramUrl" target="_blank" style="visited: {text-align:center; color:#000;}">{{ globalData.instagramName }}</a>
          </div>
          <div class="follow-me heebo-bold-mine-shaft-32px">{{ globalData.follow }}</div>
          <div class="wechat heebo-normal-mine-shaft-24px">
            <a href="javascript:void();" @click="centerDialogVisible = true" style="visited: {text-align:center; color:#000;}">{{ globalData.wechatName }}</a>
            </div>
          <div class="text-1 heebo-normal-mine-shaft-24px">
            <a :href="globalData.redBookUrl" target="_blank" style="visited: {text-align:center; color:#000;}">{{ globalData.redBookName }}</a>
          </div>
          <p class="fee-monitoring-fee-pdf-file-download heebo-normal-mine-shaft-24px">
            <a :href="globalData.algtReportPriceListPdf" target="_blank" style="visited: {text-align:center; color:#333;}">
              {{ feePdf }}
            </a>
          </p>
          <img class="vector-4" src="img/vector-2.svg" alt="Vector" />
          <img class="vector-5" src="img/vector-3.svg" alt="Vector" />
          <img class="vector-6" src="img/vector-4.svg" alt="Vector" />
          <img class="union-1" src="img/union.svg" alt="Union" />
          <img class="rectangle-128" :src="doorPic2" alt="Rectangle 128" />
        </div>
        <div class="telephone-86-021-53508888 heebo-normal-mine-shaft-24px">{{ telephone2 }}</div>
        <div class="china-branch-address heebo-bold-mine-shaft-32px">{{ addressName2 }}</div>
        <p class="china-shanghai-yuyu heebo-normal-mine-shaft-24px">{{ address2 }}</p>
      </div>
      <footBottom v-bind="globalData" />
      <el-dialog title="公众号二维码" :visible.sync="centerDialogVisible" width="50%" center>
        <div style="text-align: center;"><img src="img/wx-qrcode.bmp"></div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import 'element-ui/lib/theme-chalk/index.css';

import LeftNav from "./components/Navbar";
import Info from "./components/Info";
import FootBottom from "./components/FootBottom";
export default {
  name: "Contact1920",
  data() {
    return {
      centerDialogVisible: false
    };
  },
  components: {
    LeftNav,
    Info,
    FootBottom,
  },
  props: [
    "globalData",
    "overlapGroup",
    "contact",
    "addressName1",
    "address1",
    "telephone1",
    "doorPic1",
    "addressName2",
    "address2",
    "telephone2",
    "doorPic2",
    "feePdf",
    "algtReportPriceListPdf",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../variables'

.contuct-us-1920
  align-items: flex-start
  background-color: $selago
  display: flex
  flex-direction: column
  height: 1955px
  overflow: hidden
  width: 1920px

.overlap-group3
  height: 400px
  position: relative
  width: 2000px

.rectangle-21
  height: 400px
  left: 0
  object-fit: cover
  position: absolute
  top: 0
  width: 1920px

.rectangle-11
  background-color: $black-2
  height: 400px
  left: 0
  position: absolute
  top: 0
  width: 1920px

.phone
  left: 1537px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.infoalgtlabscom
  cursor: pointer
  left: 1705px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 49px
  white-space: nowrap

.rectangle-12
  border: 1px solid
  border-color: $white
  border-radius: 56px
  height: 24px
  left: 1323px
  position: absolute
  top: 47px
  width: 160px

.report-number
  left: 1336px
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 51px
  white-space: nowrap

.logo-01
  height: 87px
  left: 810px
  position: absolute
  top: 157px
  width: 300px

.vector
  height: 20px
  left: 1507px
  position: absolute
  top: 49px
  width: 20px

.vector-1
  height: 16px
  left: 1675px
  position: absolute
  top: 51px
  width: 20px

.place
  align-self: center
  letter-spacing: 0
  line-height: normal
  margin-right: 1.0px
  margin-top: 59px
  min-height: 60px
  min-width: 235px

.overlap-group2
  align-items: center
  background-color: $white
  display: flex
  gap: 100px
  margin-top: 61px
  min-width: 1920px
  padding: 0 260px

.rectangle-127
  height: 425px
  object-fit: cover
  width: 560px

.flex-col
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-bottom: 2.0px
  min-height: 165px
  width: 707px

.european-head-office-address
  letter-spacing: 0
  line-height: 51.2px
  white-space: nowrap

.belgium-antwerp-dia
  letter-spacing: 0
  line-height: 38.4px
  margin-top: 22px
  white-space: nowrap

.telephone-32-3-5350888
  letter-spacing: 0
  line-height: 38.4px
  margin-top: 18px
  min-height: 36px
  white-space: nowrap

.overlap-group4
  height: 849px
  margin-top: 1px
  position: relative
  width: 1920px

.overlap-group1
  height: 849px
  left: 0
  position: absolute
  top: 0
  width: 1920px

.rectangle-101
  background-color: $white
  height: 425px
  left: 0
  position: absolute
  top: 424px
  width: 1920px

.business-hours
  left: 339px
  letter-spacing: 0
  line-height: 38.4px
  position: absolute
  top: 617px
  white-space: nowrap

.mail
  left: 339px
  letter-spacing: 0
  line-height: 38.4px
  position: absolute
  top: 556px
  white-space: nowrap

.union
  height: 16px
  left: 300px
  position: absolute
  top: 569px
  width: 24px

.vector-2
  height: 24px
  left: 300px
  position: absolute
  top: 625px
  width: 24px

.vector-3
  height: 24px
  left: 303px
  position: absolute
  top: 684px
  width: 18px

.monday-to-friday-930am-to-1730pm
  left: 530px
  letter-spacing: 0
  line-height: 38.4px
  position: absolute
  top: 617px
  white-space: nowrap
  width: 425px

.infoalgtlabscom-1
  left: 411px
  letter-spacing: 0
  line-height: 38.4px
  position: absolute
  top: 556px
  white-space: nowrap
  width: 267px

.facebook
  left: 1120px
  letter-spacing: 0
  line-height: 38.4px
  position: absolute
  top: 554px
  white-space: nowrap

.instagram
  left: 1120px
  letter-spacing: 0
  line-height: 38.4px
  position: absolute
  top: 612px
  white-space: nowrap

.follow-me
  left: 1058px
  letter-spacing: 0
  line-height: 51.2px
  position: absolute
  top: 486px
  white-space: nowrap

.wechat
  left: 1120px
  letter-spacing: 0
  line-height: 38.4px
  position: absolute
  top: 670px
  white-space: nowrap

.text-1
  left: 1120px
  letter-spacing: 0
  line-height: 38.4px
  position: absolute
  top: 728px
  white-space: nowrap

.fee-monitoring-fee-pdf-file-download
  left: 339px
  letter-spacing: 0
  line-height: 38.4px
  position: absolute
  top: 675px
  white-space: nowrap

.vector-4
  height: 32px
  left: 1067px
  position: absolute
  top: 558px
  width: 32px

.vector-5
  height: 32px
  left: 1067px
  position: absolute
  top: 616px
  width: 32px

.vector-6
  height: 26px
  left: 1067px
  position: absolute
  top: 677px
  width: 32px

.union-1
  height: 18px
  left: 1058px
  position: absolute
  top: 739px
  width: 51px

.rectangle-128
  height: 425px
  left: 976px
  position: absolute
  top: 0
  width: 700px

.telephone-86-021-53508888
  left: 300px
  letter-spacing: 0
  line-height: 38.4px
  position: absolute
  top: 257px
  white-space: nowrap

.china-branch-address
  left: 300px
  letter-spacing: 0
  line-height: 51.2px
  position: absolute
  top: 128px
  white-space: nowrap
  width: 561px

.china-shanghai-yuyu
  left: 300px
  letter-spacing: 0
  line-height: 38.4px
  position: absolute
  top: 201px
  white-space: nowrap
  width: 626px

.group-2
  align-items: flex-start
  display: flex
  min-width: 1932px

.overlap-group
  align-items: center
  background-color: $selago
  display: flex
  height: 100px
  justify-content: flex-end
  min-width: 1920px
  padding: 28px 158px

.logo-02
  align-self: flex-end
  height: 42px
  width: 34px

.about-algt
  letter-spacing: 0
  line-height: normal
  margin-bottom: 0
  margin-left: 61px
  min-height: 20px
  min-width: 101px

.our-reports
  letter-spacing: 0
  line-height: normal
  margin-left: 30px
  min-height: 20px
  min-width: 113px

.service
  letter-spacing: 0
  line-height: normal
  margin-left: 30px
  min-height: 20px
  min-width: 68px

.video
  letter-spacing: 0
  line-height: normal
  margin-left: 30px
  min-height: 20px
  min-width: 52px

.place-1
  letter-spacing: 0
  line-height: normal
  margin-left: 30px
  min-height: 20px
  min-width: 79px

.x2023-algt-all-rights-reserved
  letter-spacing: 0
  line-height: normal
  margin-left: 759px
  margin-top: 1.0px
  min-height: 15px
  min-width: 214px
</style>
