<template>
  <div :class="[`fee-15`, className || ``]">
    <div class="number valign-text-middle plusjakartasans-bold-cosmic-cobalt-20px">{{ number }}</div>
  </div>
</template>

<script>
export default {
  name: "Fee",
  props: ["number", "className"],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'

.fee-15
  align-items: center
  background-color: $alto
  display: flex
  flex-wrap: wrap
  gap: 0px 0px
  // height: 41px
  padding: 8px 16px
  position: relative
  width: 220px

.number
  align-self: stretch
  letter-spacing: 0
  line-height: normal
  margin-top: -1.00px
  position: relative
  width: 188px

.fee-15.fee-48
  height: 67px

.fee-15.fee-48 .number
  margin-top: -0.50px

.fee-15.fee-49
  align-items: flex-start
  height: 66px

.fee-15.fee-50
  align-items: flex-start
  height: 91px

.fee-15.fee-51,
.fee-15.fee-52
  height: 66px

.fee-15.fee-53
  height: 91px

.fee-15.fee-62,
.fee-15.fee-72
  height: 92px
</style>
