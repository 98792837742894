<template>
  <article :class="[`tag-1024-4-1`, className || ``]">
    <div class="text-15 valign-text-middle plusjakartasans-bold-granite-gray-14px">{{ text15 }}</div>
    <fee2 :number="fee2Props.number" :className="fee2Props.className" />
  </article>
</template>

<script>
import Fee2 from "./Fee2";
export default {
  name: "Tag10242",
  components: {
    Fee2,
  },
  props: ["text15", "className", "fee2Props"],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'

.tag-1024-4-1
  align-items: center
  background-color: $selago
  display: flex
  gap: 16px
  // height: 34px
  position: relative
  width: 430px

.text-15
  height: 18px
  letter-spacing: 0
  line-height: normal
  margin-bottom: 2.0px
  margin-left: 16px
  width: 243px

.tag-1024-4-1.tag-1024-5
  margin-left: 1px
  margin-top: 1px

.tag-1024-4-1.tag-1024-6
  margin-top: 1px

.tag-1024-4-1.tag-1024-7,
.tag-1024-4-1.tag-1024-7-1,
.tag-1024-4-1.tag-1024-7-2,
.tag-1024-4-1.tag-1024-7-3
  height: 52px
  margin-top: 1px

.tag-1024-4-1.tag-1024-7-1 .text-15,
.tag-1024-4-1.tag-1024-7-2 .text-15,
.tag-1024-4-1.tag-1024-9-1 .text-15,
.tag-1024-4-1.tag-1024-9-2 .text-15,
.tag-1024-4-1.tag-1024-7-3 .text-15,
.tag-1024-4-1.tag-1024-7 .text-15
  margin-bottom: 2.47px

.tag-1024-4-1.tag-1024-8,
.tag-1024-4-1.tag-1024-8-1
  height: 70px
  margin-top: 1px

.tag-1024-4-1.tag-1024-8-1 .text-15,
.tag-1024-4-1.tag-1024-10-1 .text-15,
.tag-1024-4-1.tag-1024-8 .text-15,
.tag-1024-4-1.tag-1024-10 .text-15
  margin-bottom: 0.94px

.tag-1024-4-1.tag-1024-9,
.tag-1024-4-1.tag-1024-9-1,
.tag-1024-4-1.tag-1024-9-2
  height: 52px

.tag-1024-4-1.tag-1024-10,
.tag-1024-4-1.tag-1024-10-1
  height: 70px

.tag-1024-4-1.tag-1024-9 .text-15
  height: 36px
  margin-bottom: 2.47px
</style>
