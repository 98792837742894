<template>
  <div class="foot" :style="{ 'background-image': 'url(' + footBg + ')' }">
    <div class="flex-col-1">
      <div class="flex-row-2">
        <div class="flex-col-2">
          <img class="logo-01-1" :src="footLogo" alt="logo-01" />
          <div class="flex-row-3">
            <img class="union" :src="emailIcon" alt="Union" />
            <div class="overlap-group heebo-normal-white-24px">
              <div class="text-4">{{ emailName }}</div>
              <div class="infoalgtlabscom-1">{{ email }}</div>
            </div>
          </div>
          <div class="flex-row-4 heebo-normal-white-24px">
            <img class="vector-2" :src="timeIcon" alt="Vector" />
            <div class="text-3"> {{ timeName }}</div>
            <p class="monday-to-friday-930am-to-1730pm">{{ time }}</p>
          </div>
        </div>
        <div class="flex-col-3">
          <div class="text-2">{{ follow }}</div>
          <div class="flex-row-5">
            <div class="vector-container">
              <img class="vector-3" :src="facebookIcon" alt="Vector" />
              <img class="vector-4" :src="instagramIcon" alt="Vector" />
              <img class="vector-5" :src="wechatIcon" alt="Vector" />
            </div>
            <div class="flex-col-4 heebo-normal-white-24px">
              <div class="flex-col-item">
                <a :href="facebookUrl" target="_blank" style="visited: {text-align:center; color:#fff;}">{{ facebookName }}</a>
              </div>
              <div class="flex-col-item">
                <a :href="instagramUrl" target="_blank" style="visited: {text-align:center; color:#fff;}">{{ instagramName }}</a>
              </div>
              <div class="flex-col-item">
                <a href="javascript:void();" @click="centerDialogVisible = true" style="visited: {text-align:center; color:#fff;}">{{ wechatName }}</a>
              </div>
            </div>
          </div>
          <div class="flex-row-6">
            <img class="union-1" :src="redBookIcon" alt="Union" />
            <div class="text-1 heebo-normal-white-24px">
              <a :href="redBookUrl" target="_blank" style="visited: {text-align:center; color:#fff;}">{{ redBookName }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-col-5">
        <div class="rectangle-106"></div>
        <div class="flex-row-7">
          <div class="about-algt plusjakartasans-bold-granite-gray-16px">{{ aboutAlgt }}</div>
          <div class="our-reports plusjakartasans-bold-granite-gray-16px">{{ ourReports }}</div>
          <div class="service plusjakartasans-bold-granite-gray-16px">{{ service }}</div>
          <div class="video plusjakartasans-bold-granite-gray-16px">{{ video }}</div>
          <div class="place plusjakartasans-bold-granite-gray-16px">{{ place }}</div>
          <p class="x2023-algt-all-rights-reserved plusjakartasans-normal-granite-gray-12px">
            {{ allRightsReserved }}
          </p>
        </div>
      </div>
    </div>
    <el-dialog title="公众号二维码" :visible.sync="centerDialogVisible" width="50%" center>
      <div style="text-align: center;"><img src="img/wx-qrcode.bmp"></div>
    </el-dialog>
  </div>
</template>

<script>
import 'element-ui/lib/theme-chalk/index.css';

export default {
  name: "Foot",
  data() {
    return {
      centerDialogVisible: false
    };
  },
  props: [
    "phone",
    "footLogo",
    "footBg",
    "aboutAlgt",
    "ourReports",
    "newsLibrary",
    "service",
    "video",
    "place",
    "allRightsReserved",
    "email",
    "emailName",
    "emailIcon",
    "time",
    "timeName",
    "timeIcon",
    "follow",
    "facebookName",
    "facebookUrl",
    "facebookIcon",
    "instagramName",
    "instagramUrl",
    "instagramIcon",
    "wechatName",
    "wechatIcon",
    "redBookName",
    "redBookUrl",
    "redBookIcon",
  ],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'

.foot
  align-items: flex-start
  background-position: 50% 50%
  background-size: cover
  display: flex
  height: 511px
  margin-top: 80px
  min-width: 1920px

.flex-col-1
  -webkit-backdrop-filter: blur(10px) brightness(100%)
  align-items: flex-start
  backdrop-filter: blur(10px) brightness(100%)
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 45.37%, rgba(0, 0, 0, 0.82) 100%)
  display: flex
  flex-direction: column
  gap: 51px
  min-height: 511px
  padding: 41px 159px
  width: 1920px

.flex-row-2
  align-items: flex-start
  display: flex
  gap: 636px
  height: 280px
  margin-left: 1px
  margin-top: 38px
  min-width: 1504px

.flex-col-2
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 1px
  min-height: 245px
  width: 590px

.logo-01-1
  height: 87px
  width: 300px

.flex-row-3
  align-items: center
  display: flex
  gap: 15px
  margin-top: 59px
  min-width: 432px

.union
  height: 16px
  margin-top: 3.41px
  width: 24px

.overlap-group
  height: 41px
  position: relative
  width: 393px

.text-4
  left: 0
  letter-spacing: 0
  line-height: 38.4px
  position: absolute
  top: 0
  white-space: nowrap
  width: 221px

.infoalgtlabscom-1
  left: 221px
  letter-spacing: 0
  line-height: 38.4px
  position: absolute
  top: 0
  white-space: nowrap
  width: 267px

.flex-row-4
  align-items: center
  display: flex
  height: 38px
  margin-top: 20px
  min-width: 590px

.vector-2
  height: 24px
  margin-top: 1.87px
  width: 24px

.text-3
  letter-spacing: 0
  line-height: 38.4px
  margin-left: 15px
  white-space: nowrap
  width: 221px

.monday-to-friday-930am-to-1730pm
  letter-spacing: 0
  line-height: 38.4px
  white-space: nowrap
  width: 425px

.flex-col-3
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 280px
  width: 278px

.text-2
  color: $white
  font-family: $font-family-heebo
  font-size: $font-size-xxxl
  font-weight: 700
  letter-spacing: 0
  line-height: 51.2px
  margin-left: 9px
  white-space: nowrap

.flex-row-5
  align-items: center
  align-self: flex-end
  display: flex
  gap: 30px
  height: 154px
  margin-top: 17px
  min-width: 269px

.vector-container
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-bottom: 0.95px
  min-height: 145px
  width: 32px

.vector-3
  height: 32px
  width: 32px

.vector-4
  height: 32px
  margin-top: 26px
  width: 32px

.vector-5
  height: 26px
  margin-top: 29px
  width: 32px

.flex-col-4
  align-items: flex-start
  display: flex
  flex-direction: column
  gap: 20px
  min-height: 154px
  width: 207px

.flex-col-item
  letter-spacing: 0
  line-height: 38.4px
  white-space: nowrap

.flex-row-6
  align-items: center
  display: flex
  gap: 20px
  height: 38px
  margin-top: 20px
  min-width: 239px

.union-1
  height: 18px
  margin-top: 2.0px
  width: 51px

.text-1
  letter-spacing: 0
  line-height: 38.4px
  min-width: 168px
  white-space: nowrap

.flex-col-5
  align-items: flex-start
  align-self: flex-end
  display: flex
  flex-direction: column
  gap: 39px
  min-height: 60px
  width: 1601px

.rectangle-106
  background-color: $mine-shaft
  height: 1px
  width: 1600px

.flex-row-7
  align-items: center
  display: flex
  height: 20px
  min-width: 1601px

.about-algt
  letter-spacing: 0
  line-height: normal
  min-height: 20px
  min-width: 101px

.our-reports
  letter-spacing: 0
  line-height: normal
  margin-left: 30px
  min-height: 20px
  min-width: 113px

.service
  letter-spacing: 0
  line-height: normal
  margin-left: 30px
  min-height: 20px
  min-width: 68px

.video
  letter-spacing: 0
  line-height: normal
  margin-left: 30px
  min-height: 20px
  min-width: 52px

.place
  letter-spacing: 0
  line-height: normal
  margin-left: 30px
  min-height: 20px
  min-width: 79px

.x2023-algt-all-rights-reserved
  letter-spacing: 0
  line-height: normal
  margin-left: 854px
  margin-top: 1.0px
  min-height: 15px
  min-width: 214px
</style>
