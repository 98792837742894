<template>
  <div class="tag-375">
    <div class="overlap-group-2 plusjakartasans-bold-white-12px">
      <div class="x029-2">{{ x029 }}</div>
      <div class="fee-17"><div class="x28-47">Fee</div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tag375",
  props: ["x029"],
};
</script>

<style lang="sass" scoped>
@import '../../../variables'

.tag-375
  align-items: flex-start
  background-color: $brand
  display: flex
  margin-top: 13px
  width: 331px

.overlap-group-2
  height: 31px
  margin-left: 16px
  position: relative
  width: 315px

.x029-2
  left: 0
  letter-spacing: 0
  line-height: normal
  position: absolute
  top: 7px
  width: 235px

.fee-17,
.fee-18,
.fee-19,
.fee-20,
.fee-21,
.fee-22,
.fee-23
  align-items: center
  background-color: $brand
  display: flex
  flex-wrap: wrap
  gap: 20px 20px
  left: 206px
  padding: 8px 0px 8px 8px
  position: absolute
  top: 0
  width: 109px

.x28-47,
.x28-73,
.x28-74,
.x28-75,
.x28-76,
.x28-77,
.x28-78
  letter-spacing: 0
  line-height: normal
  margin-top: -2.00px
  position: relative
  width: 93px
</style>
